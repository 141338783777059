const appointmentQuery = `
animal{
  id
  tag
  pricingDetails{
    discountedPrice{
        amount
    }
  }
}
type
status
purpose
notes
appointmentNumber
farm{
  id
  name
  code
  operational
}
customer{
  name
  phoneNo
}
createdAt
  updatedAt
  createdBy{
      name
  }
  updatedBy{
      name
  }
  followUpAction`
const getAppointmentsQuery = () => {
  return {
    "query": `query{appointments{${appointmentQuery}
      }}`
  }
}
const updateAppointmentQuery = (payloadString: any) => {
  return {
    query: `mutation($updateAppointmentInput:UpdateAppointmentInput!,$id: String!) {
            updateAppointment(updateAppointmentInput: $updateAppointmentInput, id: $id){
                success
        }
      }`,
    variables: {
      updateAppointmentInput: payloadString.updateAppointmentInput,
      id: payloadString.id

    },
  };
}
const createAppointmentQuery = (payloadString: any) => {
  return {
    query: `mutation($createAppointmentInput:CreateAppointmentInput!) {
            appointment(createAppointmentInput:$createAppointmentInput){
                id
                status
        }
      }`,
    variables: {
      "createAppointmentInput": payloadString
    },
  };
}

const appointmentsByDateQuery = (param: any) => {
  const { startDate, endDate, farmIds = "", animalId = "" } = param;
  return {
    "query": `query ($queryInput:GetAppointmentByDateInput!) {
    appointmentsByDate (getAppointmentByDateInput:$queryInput) {
      id
      date
      ${appointmentQuery}
    }
  }`,
    variables: {
      "queryInput": {
        "startTime": startDate, "endTime": endDate,
        ...(farmIds && { farmIds: [farmIds] }),
        ...(animalId && { animalId: animalId })
      }
    }
  };
}
export const AppointmentQuery = {
  updateAppointmentQuery,
  getAppointmentsQuery,
  appointmentsByDateQuery,
  createAppointmentQuery
}