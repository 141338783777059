import { createSlice } from "@reduxjs/toolkit";
import { CATTLE_DIRECTORY_STATUS, CATTLE_GENDER } from "../../../constants/app-constants";
import { AnimalsReducerModel } from "../../models/animal/animal.models";
import AnimalExtraReducers from "./extra-reducers";
export const initialState: AnimalsReducerModel = {
  animal: null,
  isAnimalError: false,
  isAnimalSuccess: false,
  isAnimalLoading: false,
  animalMessage: "",
  isFetching: false,
  farmAnimal: {
    farmId: "",
    animals: [],
    filteredAnimal: [],
    isDataExist: false,
    isFirstCallAnimal: false,
    isFetching: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    filter: {
      price: {
        selected: { min: "", max: "" },
        title: "Price",
        min: "",
        max: "",
        type: "TEXT"
      },
      milk: {
        currentMilk: {
          title: "Current milk",
          type: "OPTIONS",
          selected: { min: "", max: "" },
          data: [],
          min: "",
          max: ""
        },
        expectedMilk: {
          title: "Expected Milk",
          type: "OPTIONS",
          selected: { min: "", max: "" },
          data: [],
          min: "",
          max: ""
        }
      },
      lactationNos: {
        selected: [],
        title: "Byat",
        type: "CHECKED",
        data: [
          { "label": "1", "value": 1, "count": null },
          { "label": "2", "value": 2, "count": null },
          { "label": "3", "value": 3, "count": null },
          { "label": "4", "value": 4, "count": null }
        ]
      },
      daysFromLastParturition: {
        selected: [],
        title: "Byahi Din",
        type: "CHECKED",
        data: [{
          label: "less than 10",
          select: { "startDay": 0, "endDay": 10 },
          value: 10,
          count: null
        },
        {
          label: "10-15",
          select: { "startDay": 10, "endDay": 15 },
          value: 15,
          count: null
        },
        {
          label: "16-20",
          select: { "startDay": 16, "endDay": 20 },
          value: 20,
          count: null
        },
        {
          label: "21-35",
          select: { "startDay": 21, "endDay": 35 },
          value: 35,
          count: null
        },
        {
          label: "36-40",
          select: { "startDay": 36, "endDay": 40 },
          value: 40,
          count: null
        },
        {
          label: "41-45",
          select: { "startDay": 41, "endDay": 45 },
          value: 45,
          count: null
        },
        {
          label: "46+",
          select: { "startDay": 46, "endDay": 1000 },
          value: 1000,
          count: null
        }]
      },
      sathMein: {
        selected: [],
        title: "Sath Mein",
        type: "CHECKED",
        data: [{
          label: CATTLE_GENDER["male"],
          value: "male",
          count: null
        }, {
          label: CATTLE_GENDER["female"],
          value: "female",
          count: null
        }, {
          label: "Not alive",
          value: CATTLE_DIRECTORY_STATUS.NOT_ALIVE,
          count: null
        }]
      },
      daysInHub: {
        selected: [],
        title: "Days in Hub",
        type: "CHECKED",
        data: []
      },
      calfHealth: {
        selected: [],
        title: "Calf Health",
        type: "CHECKED",
        data: [{
          label: CATTLE_DIRECTORY_STATUS.Healthy,
          value: CATTLE_DIRECTORY_STATUS.HEALTHY,
          count: null
        }, {
          label: CATTLE_DIRECTORY_STATUS.Unwell,
          value: CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT,
          count: null
        }]
      }
    }
  },

  signedUrlImage: "",
  replaceMediaType: "",
  isSignedUrlImageError: false,
  isSignedUrlImageLoading: false,
  isSignedUrlImageSuccess: false,
  signedUrlMessage: "",
};
export const AnimalsSlice = createSlice({
  name: "animals",
  initialState,
  reducers: {
    setReplaceMediaType: (state, action) => {
      state.replaceMediaType = action.payload as string;
    },
    setAnimalList: (state, action) => {
      const { filteredAnimal, animals, farmId = "", filter } = action.payload as any || {};
      if (animals) {
        state.farmAnimal.animals = animals;
      }
      if (filteredAnimal) {
        state.farmAnimal.filteredAnimal = filteredAnimal;
      }
      if (filter && Object.keys(filter)) {
        state.farmAnimal.filter = filter;
      }
      state.farmAnimal.farmId = farmId;
    },
    setIsFirstCallAnimal: (state, action) => {
      const { isFirstCallAnimal, farmId = "" } = action.payload;
      state.farmAnimal.isFirstCallAnimal = isFirstCallAnimal as any;
      if (farmId) {
        state.farmAnimal.farmId = farmId as any;
      }
    },
    setAnimalIsLoading: (state, action) => {
      state.farmAnimal.isLoading = action.payload as boolean;
      state.farmAnimal.isFetching = action.payload as boolean;
    },
    setAnimalFilter: (state, action) => {
      state.farmAnimal.filter = action.payload as any;
    },
    setPriceFilter: (state, action) => {
      state.farmAnimal.filter.price = action.payload as any;
    },
    setIsDataExist: (state, action) => {
      state.farmAnimal.isDataExist = action.payload;
    },
    resetAnimalState: (state, action) => {
      state = action.payload as any;
    }
  },
  extraReducers: (builder) => AnimalExtraReducers(builder),
});
const { setReplaceMediaType, setAnimalList, setIsFirstCallAnimal, setAnimalIsLoading, setAnimalFilter, setPriceFilter, resetAnimalState, setIsDataExist } = AnimalsSlice.actions;
export const AnimalsReducerActions = {
  setReplaceMediaType,
  setAnimalList,
  setIsFirstCallAnimal,
  setAnimalIsLoading,
  setAnimalFilter,
  setPriceFilter,
  setIsDataExist,
  resetAnimalState
};
export default AnimalsSlice.reducer;
