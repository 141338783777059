/* eslint-disable array-callback-return */
import {ref, getDownloadURL} from "firebase/storage"; 
import { storage } from "../../firebase";
import { FORM_INPUT_TYPE } from "../../constants/app-constants"; 
const imagesType=[FORM_INPUT_TYPE.PHOTO_INPUT,FORM_INPUT_TYPE.BARCODE_INPUT,FORM_INPUT_TYPE.VIDEO_INPUT];
export class QualityService {
    static getImageUrl=async(response:string)=>{ 
        const imagesListRef = await ref(storage, `${response}`);  
        const imageUrl= await getDownloadURL(imagesListRef);
        const path=(typeof imageUrl=== 'string')? imageUrl:""; 
        return path;
    }
    static getSortedList=(list:Array<any>,order:string)=>{
      return list.sort((a:any, b:any)=> {return (a[order] - b[order])}) // sorting
    }
    static getQcReport=(qcReport:any,fields:any)=>{
      let value=Object.assign({},qcReport);
      let flag =false;
      for(let i=0;i<fields.length;i++){
          if(typeof value[fields[i]] == 'undefined'){
              flag=true;
              break;
          }else {
              value = value[fields[i]];
          }
      }
      return {flag,value}
    }
    static getQuestionByAnswer= async(element:any,qcReport:any,sectionQuestions:any)=>{
      let generatedResponse:any={questions:[],title:element.title,section:element.section};
        await Promise.all(element.questions.map(async (question:any,i:number) => {
          try {
                question.id=i;
                question.answer='';
                question.isDisable=false;
                question.field_status="";
                let fields = question.field_path.split('.'); 
                if(Object.keys(qcReport).length){
                 const {flag,value}= QualityService.getQcReport(qcReport,fields) 
                    if(flag){ 
                        question.field_status=0;
                        question.isDisable=true;
                        generatedResponse.questions.push(question);
                    }
                    else{   question.field_status=1;
                        if(imagesType.includes(question.question_type) && value){
                          const answer= await QualityService.getImageUrl(value); 
                            question.answer= answer; 
                        }else{
                            question.answer=value;
                        } 
                        generatedResponse.questions.push(question);
                    }
                }else{ 
                  question.field_status=0;
                  question.isDisable=true;
                  generatedResponse.questions.push(question);
                }
          } catch (error) {
            console.log('error'+ error);
          }
        })) 
        const sortQuestion=QualityService.getSortedList(generatedResponse.questions,'id')
        generatedResponse.questions=[...sortQuestion]; 
        const questions=QualityService.getQuestionIfAnswerd(generatedResponse,sectionQuestions);
        return questions;
    }
    static getQCQuestion = async(response:any) => { 
        const sectionQuestions=Object.keys(response.questions).length? JSON.parse(response.questions):[];
        const qcReport=Object.keys(response.qcReport).length? JSON.parse(response.qcReport):""; 
        let generatedResponse:any = []; 
        await Promise.all(sectionQuestions.map(async (elem:any,index:number) => {
          try { elem.section=index;
            // here candidate data is inserted into  
            let insertResponse = await QualityService.getQuestionByAnswer(elem,qcReport,sectionQuestions)  
            // and response need to be added into final response array  
            generatedResponse.push(insertResponse); 
          } catch (error) {
            console.log('error'+ error);
          }
        }));
        const sortData=QualityService.getSortedList(generatedResponse,'section');
        // let totalAnswer=0,totalQuestion=0;
        // sortData.filter((question:any) =>{
        //   totalAnswer+=question.totalAnswer;
        //   totalQuestion+=question.questions.length;
        // });
        // const percent=Math.abs(totalAnswer/totalQuestion*100)
        // console.log(totalAnswer,totalQuestion,'---------totalAnswer',percent,sortData) 
        return sortData;
    }
   static getQuestionIfAnswerd=(generatedResponse:any,sectionQuestions:any)=>{ 
        let obj:any={...generatedResponse};
        // obj.totalAnswer=0;
        // const totalAnswer=obj.questions.filter((question:any) => question.answer); 
        // obj.totalAnswer = totalAnswer.length; 
        obj.questions=[];
        generatedResponse.questions.filter((question:any) => { 
            if(question.show_if){
              const section=sectionQuestions.find((x:any) => x.section ===question.depends_on.section); 
              const ansQuestion=section.questions.find((x:any) => x.id ===question.depends_on.question); 
              const show_if=question.show_if.toLowerCase();
              const answer=ansQuestion.answer.toLowerCase(); 
                if((ansQuestion && ansQuestion.answer) && (new RegExp(show_if).test(answer))){
                   obj.questions.push(question);
                } 
          }else{ 
            obj.questions.push(question);
          }
        });   
      return obj
    }
    static qcReportSubmitValidator=(list:any)=>{ 
      let validator:any=[],i:number=1, qcReport:any=[],section:number=0;
      list.forEach((item:any)=>{ 
          item.questions.forEach((question:any)=>{
            if((question.field_status==="" && !question.isDisable)){
                  if(i===1){ 
                    section=item.section;
                  } 
                  validator.push(question.field_path) 
                  i+=1;
              }
              else if(question.field_status>=0){
                 qcReport.push({field_path:question.field_path,field_status:question.field_status})
              }
          });
      });   
      return {validator,section,qcReport};
    }
}