import React, { Fragment } from "react";
import { CSpinner } from "@coreui/react";
import "./loader.scss";
interface LoaderProps {
    type?: string
    customClass?: string;
    isPageLoader?: boolean;
    listLoader?: boolean;
}
function Loader(porps: LoaderProps) {
    const { type, customClass = "", isPageLoader = true, listLoader = false } = porps;
    return (
        <Fragment>
            {(isPageLoader && !listLoader) && <div className={`${customClass} ${type === "full" ? "page-loader" : "list-loader"}`}>
                <CSpinner color="primary" />
            </div>}
            {(listLoader) && <div className={`${customClass} list-load`}>
                <div className="snippet" data-title=".dot-elastic">
                    <div className="stage">
                        <div className="dot-elastic"></div>
                    </div>
                </div>
            </div>}
        </Fragment>

    )
}

export default Loader;