import { CATTLE_DIRECTORY_BUFFALO_QC_STATUS, CATTLE_DIRECTORY_BUFFALO_STATUS, CATTLE_DIRECTORY_CALVES_STATUS, CATTLE_DIRECTORY_CARD, CATTLE_DIRECTORY_STATUS, CATTLE_GENDER, CATTLE_GENDER_HINDI, FILTER_NAME } from "../../constants/app-constants";
import { getDayOfSuffix, getDiffDay, getUtcDate } from "../../shared/helper/get-day-from-date";
import { currencyFormat } from "../../shared/helper/formatter";
import _ from "lodash";
const getSelectedStatus = (data: Array<any>, item: any, name: string) => {
    return data.find((elm) => item[name] === elm.value)
}
const getMilkValue = (item: any) => {
    const volumeValue = (item && item.milkVolume && item.milkVolume.value && item.milkVolume.value) || "";
    const weightValue = (item && item.milkWeight && item.milkWeight.value && item.milkWeight.value) || "";
    return { volumeValue, weightValue }
}
const getPrice = (pricingDetails: any) => {
    const mrpPrice = pricingDetails?.mrp?.amount || "";
    const discountedPrice = pricingDetails?.discountedPrice?.amount || "";
    const mrpFormatPrice = (mrpPrice && currencyFormat(Math.round(mrpPrice), true)) || "";
    const discountFormatPrice = (discountedPrice && currencyFormat(Math.round(discountedPrice), true)) || "";
    return { mrpPrice, discountedPrice, mrpFormatPrice, discountFormatPrice }
}
const getChildren = (item: any) => {
    let children: any = [], gender: any = [], genderHindi: any = [], tag = "", genders: any = [];
    if (item && item.children) {
        item.children.forEach((element: any) => {
            if (element.gender) {
                gender.push(element?.gender || "");
                genderHindi.push(CATTLE_GENDER_HINDI[element?.gender] || "")
                genders.push(CATTLE_GENDER[element?.gender] || "");
            }
            tag = element.tag || "";
            const { days = "" } = getDiffDay(element.dob, new Date()) || {};
            children.push(
                { label: CATTLE_DIRECTORY_CARD.CALFID, value: element.tag || "" },
                { label: CATTLE_DIRECTORY_CARD.AGE, value: days || "" }
            )
        });
    }
    return { children, gender, genderHindi, tag, genders };
}
const getAnimalListItem = (item: any, selectedFarm = "") => {
    let listItem: any = [], selectedOption: any;
    if (item.isChild) {
        const selected = getSelectedStatus(CATTLE_DIRECTORY_CALVES_STATUS, item, "healthStatus");
        const isAllHub = (selectedFarm === "ALL" && { label: "Hub", value: item.farm?.name || "" })
        const { days = "" } = getDiffDay(item.dob, new Date()) || {};
        const list: any = [
            { label: CATTLE_DIRECTORY_CARD.AGE, value: `${days || ""}` },
            { label: CATTLE_DIRECTORY_CARD.GENDER, value: CATTLE_GENDER[item.gender] || "" },
            { label: CATTLE_DIRECTORY_CARD.MOTHER_ID, value: item?.parent?.tag || "" }
        ];
        if (isAllHub) {
            listItem = [...list, isAllHub];
        } else {
            listItem = [...list];
        }
        selectedOption = selected;
    } else {
        const lactationNo = (item.lactationNo && (`${item.lactationNo}${getDayOfSuffix(item.lactationNo)}`)) || "";
        let selected;
        if (item.healthStatus === CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT) {
            selected = getSelectedStatus(CATTLE_DIRECTORY_BUFFALO_STATUS, item, "healthStatus")
        } else if (item.animalStatus == CATTLE_DIRECTORY_STATUS.PICKUP_PENDING || item.animalStatus == CATTLE_DIRECTORY_STATUS.IN_TRANSIT) {
            selected = getSelectedStatus(CATTLE_DIRECTORY_BUFFALO_QC_STATUS, item, "animalStatus")
        }

        else {
            selected = getSelectedStatus(CATTLE_DIRECTORY_BUFFALO_STATUS, item, "animalStatus")
        }
        const isAllHub = (selectedFarm === "ALL" && { label: "Hub", value: item.farm?.name || "" });
        const { genders = [] } = getChildren(item);
        const { volumeValue: averageVolumeValue, weightValue: averageWeightValue } = getMilkValue(item.averageMilkYield);
        const { volumeValue: expectedVolumeValue, weightValue: expectedWeightValue } = getMilkValue(item.expectedMilkYield);
        const { mrpFormatPrice = "", discountFormatPrice = "" } = getPrice(item.pricingDetails);
        const { days = "" } = (item.lastParturitionDate && getDiffDay(item.lastParturitionDate, new Date(), "days")) || "";
        const list: any = [
            { label: CATTLE_DIRECTORY_CARD.BYAT, value: lactationNo },
            { label: CATTLE_DIRECTORY_CARD.Milk_Yield, value: (item.averageMilkYield && `${(averageWeightValue && `${averageWeightValue}Kg/`) || ""} ${(averageVolumeValue && `${averageVolumeValue}L`) || ""}`) || "" },
            { label: CATTLE_DIRECTORY_CARD.MILK_CAPACITY, value: (item.expectedMilkYield && `${(expectedWeightValue && `${expectedWeightValue}Kg/`) || ""} ${(expectedVolumeValue && `${expectedVolumeValue}L`) || ""}`) || "" },
            { label: CATTLE_DIRECTORY_CARD.KITNE_DIN_KI_BYAHI, value: `${days}` },
            { label: CATTLE_DIRECTORY_CARD.SATH_MEIN, value: (genders.length && genders.toString()) || "", children: item.children },
            { label: CATTLE_DIRECTORY_CARD.CURRENT_PRICE, mrp: mrpFormatPrice, value: discountFormatPrice }
        ]
        if (isAllHub) {
            listItem = [...list, isAllHub];
        } else {
            listItem = [...list];
        }
        selectedOption = selected;
    }
    return { listItem, selectedOption }
}
const getSwipeAnimalData = (param: any) => {
    const { animals = [], animal = {}, isFilterAll = false, isSwipe = false } = param || {};
    return animals.filter((element: any) => {
        if (isFilterAll) {
            element.isSwipe = isSwipe;
        }
        else if (animal && animal.id === element.id && !isFilterAll) {
            const swipe = element.isSwipe;
            element.isSwipe = !swipe;
        }
        return element;
    });
}
const filterByAnimalData = (data: any, obj = { animalId: "", status: "", animalHealth: "", isFilterAllAnimal: false }, isChild = false) => {
    const { animalId = "", status = "", animalHealth = "", isFilterAllAnimal = false } = obj || {};
    let currentAnimal: any, payload: any, animalTag: any = "";
    const animals = _.cloneDeep(data);
    const allAnimal = animals.filter((item: any) => {
        if (isChild && !item.isChild) {
            const children = item.children.filter((c: any) => {
                if (c.id === animalId) {
                    c.healthStatus = status;
                    animalTag = c.tag;
                    payload = { id: c.id, healthStatus: c.healthStatus, isChild: c.isChild };
                    currentAnimal = item;
                }
                if (c.healthStatus !== CATTLE_DIRECTORY_STATUS.NOT_ALIVE) {
                    return c;
                }
            });
            item.children = children;
        } else if (!isChild && !item.isChild && item.id === animalId) {
            let healthStatus = item.healthStatus;
            if (status) {
                item.animalStatus = status;
            }
            if (animalHealth) {
                item.healthStatus = animalHealth;
            }
            payload = { id: item.id, animalStatus: item.animalStatus, isChild: item.isChild, healthStatus: healthStatus };
            currentAnimal = item;
            animalTag = item.tag;
        }
        return item;
    });
    if (isFilterAllAnimal) {
        return {
            allAnimal: allAnimal,
            filteredAnimal: allAnimal
        }
    } else {
        return { currentAnimal, payload, animalTag: animalTag }
    }
};

const filterByStatusData = (data: any, selected: Array<any>) => {
    if (selected && selected.length) {
        const isUnderTreetment = selected.find((elm: any) => elm === CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT)
        // eslint-disable-next-line array-callback-return
        return data.filter((item: any) => {
            if (selected.includes(item.healthStatus) && isUnderTreetment && item.healthStatus === CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT) {
                return item;
            } else if (selected.includes(item.animalStatus) && item.healthStatus !== CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT) {
                return item;
            }
        })
    } else {
        return data;
    }
};
const copyAnimalData = (item: any) => {
    const { volumeValue: averageVolumeValue } = getMilkValue(item.averageMilkYield);
    const { volumeValue: expectedVolumeValue } = getMilkValue(item.expectedMilkYield);
    const { genderHindi = [] } = getChildren(item);
    const { days = "" } = getDiffDay(item.lastParturitionDate, new Date(), "Din") || {};
    let childGender = ((genderHindi.length) && genderHindi.toString()) || "बछड़ा नहीं है";
    const copyText = `*टैग नंबर: ${item.tag}* 
    नस्ल: मुर्रा भैंस
    मौजूदा दूध की पैदावार (लीटर):  ${averageVolumeValue || "--"}
    दूध की क्षमता (लीटर): ${expectedVolumeValue || "--"}
    कितने दिन की ब्याई- ${days || "--"}
    ब्यात:${item.lactationNo}
    साथ में - ${childGender}`;
    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText);
}
const getExportAnimal = (data: Array<any>, selectedFarm = { id: "", name: "" }) => {
    let exportAnimal: any = [];
    data.forEach((item: any) => {
        const { genders = [], tag } = getChildren(item);
        const farmName = item?.farm?.name || selectedFarm?.name;
        const { volumeValue: averageVolumeValue, weightValue: averageWeightValue } = getMilkValue(item.averageMilkYield);
        const { volumeValue: expectedVolumeValue, weightValue: expectedWeightValue } = getMilkValue(item.expectedMilkYield);
        const { mrpPrice = "", discountedPrice = "" } = getPrice(item.pricingDetails);
        exportAnimal.push({
            "Cattle ID": item.tag || "-",
            "Current Hub": farmName || "-",
            "Date of Parturation": getUtcDate(item?.lastParturitionDate) || "-",
            "Lactation Number": item.lactationNo || "-",
            "Current Milk Yield (kg)": averageWeightValue || "-",
            "Current Milk Yield (L)": averageVolumeValue || "-",
            "Milk Capacity (kg)": expectedWeightValue || "-",
            "Milk Capacity (L)": expectedVolumeValue || "-",
            "Calf ID": tag || "-",
            "Calf Gender": (genders.length && genders.toString()) || "-",
            "Max Price": (mrpPrice && `${mrpPrice}`) || "-",
            "Current Selling Price": (discountedPrice && `${discountedPrice}`) || "-"
        });
    })
    return exportAnimal;
}
const getSelectedFilter = (value: any) => {
    const getValue = (data: any, key: any) => (data[key] || 0);
    const min = getValue(value, "min") || 0;
    const max = getValue(value, "max") || "";
    const selected = { min: parseInt(min) || 0, max: parseInt(max) || "" }
    return selected
}
const setFilter = (filter: any, filterName: any, target?: any, isApply?: boolean) => {
    let filterData, selectedFilter: any = {};
    const filteredData: any = _.cloneDeep(filter);
    Object.keys(filteredData).map((key: any) => {
        if (filteredData[key]) {
            if (key === filterName && FILTER_NAME.PRICE === key) {
                if (isApply && filteredData[key].max && filteredData[key].min >= 0 && parseInt(filteredData[key].max) > parseInt(filteredData[key].min)) {
                    filteredData[key]["selected"] = getSelectedFilter(filteredData[key]);
                } else if (target && target.name) {
                    filteredData[key][target.name] = target.value;
                }
                selectedFilter[key] = filteredData[key]["selected"];
                filterData = filteredData[key];
                return filteredData[key]
            }
            if (key === filterName && filteredData[key] && FILTER_NAME.MILK === key) {
                return Object.keys(filteredData[key]).map((type: any) => {
                    if (isApply && [FILTER_NAME.EXPECTED_MILK, FILTER_NAME.CURRENT_MILK].includes(type)) {
                        const selected = getSelectedFilter(filteredData[key][type]);
                        if (selected.min && selected.max) {
                            filteredData[key][type]["selected"] = { ...selected };
                            selectedFilter[type] = filteredData[key][type]["selected"];
                        } else {
                            filteredData[key][type]["min"] = "";
                            filteredData[key][type]["max"] = "";
                        }
                    }
                    filterData = filteredData[key];
                });
            }
            else if ((key === filterName) && (target && target.value) && ([FILTER_NAME.BYAHI_DIN].includes(key))) {
                const selected = [...filteredData[filterName].selected];
                const selectedNumber = parseInt(target.value);
                const filterValue = filteredData[filterName].data.find((val: any) => val.value === selectedNumber);
                if (filterValue && filterValue.select) {
                    const selectValue = selected.find((val: any) => (val && val.endDay === selectedNumber));
                    if (selectValue && selectValue.endDay) {
                        const filterDays = selected.filter((val: any) => val && val.endDay !== selectedNumber);
                        filteredData[filterName].selected = filterDays;
                    }
                    if (!selectValue) {
                        filteredData[filterName].selected.push(filterValue.select);
                    }
                }
                selectedFilter[key] = filteredData[filterName].selected;
                filterData = filteredData[key];
            }
            else if ((key === filterName) && (target && target.value) && ([FILTER_NAME.BYAT, FILTER_NAME.CALF_HEALTH, FILTER_NAME.SATHMEIN].includes(key))) {
                const selected = [...filteredData[filterName].selected];
                const selectedValue = ([FILTER_NAME.BYAT].includes(key)) ? parseInt(target.value) : target.value
                if (filteredData[filterName].selected.includes(selectedValue)) {
                    const filterValue = selected.filter((val: any) => val !== selectedValue);
                    filteredData[filterName].selected = filterValue;
                } else if (!filteredData[filterName].selected.includes(selectedValue)) {
                    filteredData[filterName].selected.push(selectedValue);
                }
                selectedFilter[key] = filteredData[filterName].selected;
                filterData = filteredData[key];
            }
        }
        return filteredData[key];
    });
    return { filteredData, filterData, selectedFilter };
}
const getAllSelectedFilter = (filter: any) => {
    let selectedFilter: any = {};
    const filteredData: any = _.cloneDeep(filter);
    Object.keys(filteredData).map((key: any) => {
        if (filteredData[key]) {
            if (FILTER_NAME.MILK === key) {
                return Object.keys(filteredData[key]).map((type: any) => {
                    if ([FILTER_NAME.EXPECTED_MILK, FILTER_NAME.CURRENT_MILK].includes(type)) {
                        selectedFilter[type] = filteredData[key][type]["selected"];
                    }
                });
            } else {
                selectedFilter[key] = filteredData[key]["selected"];
            }
        }
        return filteredData[key];
    });
    return { filteredData, selectedFilter };
}
const getMilkFilterOption: any = (data: any, key: any) => {
    let resArr: any = [], options: any = [];
    data.forEach((item: any) => {
        if (!item.isChild) {
            const { volumeValue } = getMilkValue(item[key]);
            if (volumeValue && !resArr.includes(volumeValue)) {
                resArr.push(volumeValue);
            }
        }
    });
    const maxValue = Math.max.apply(Math, resArr.map(function (o: any) { return Math.ceil(o) }));
    const minValue = Math.min.apply(Math, resArr.map(function (o: any) { return Math.floor(o) }));
    for (let i = minValue; i < maxValue + 1; i++) {
        options.push({ label: i, value: i });
    }
    return options;
}
const updateFilterDataCount = (filter: any, animals: any) => {
    const filterObj: any = _.cloneDeep(filter);
    Object.keys(filterObj).forEach((filterKey: any) => {
        if (filterKey === FILTER_NAME.MILK) {
            if (filterObj[FILTER_NAME.MILK][FILTER_NAME.CURRENT_MILK]) {
                const getOptionsMilk = getMilkFilterOption(animals, 'averageMilkYield');
                filterObj[FILTER_NAME.MILK][FILTER_NAME.CURRENT_MILK].data = [...getOptionsMilk.map((element: any) => {
                    return element
                })]
            }
            if (filterObj[FILTER_NAME.MILK][FILTER_NAME.CURRENT_MILK]) {
                const getOptionsMilk = getMilkFilterOption(animals, 'expectedMilkYield');
                filterObj[FILTER_NAME.MILK][FILTER_NAME.EXPECTED_MILK].data = [...getOptionsMilk.map((element: any) => {
                    return element
                })]
            }
        }
    })
    return filterObj;
}
const isPrice = (item: any, value: any) => item >= parseInt(value.min) && item <= parseInt(value.max);
const isMilk = (item: any, value: any) => item >= value.min && item <= value.max;

const isCurrentAnimalFallingUnderFilter = (animal: any, filter: any) => {
    let includeCurrentAnimal = true;
    const { price = { max: "", min: "" }, currentMilk = { min: "", max: "" }, expectedMilk = { min: "", max: "" }, sathMein = [], calfHealth = [] } = filter;
    const { discountedPrice = "" } = getPrice(animal.pricingDetails);
    const roundDiscountPrice = Math.round(discountedPrice);
    const { volumeValue: averageVolumeValue } = getMilkValue(animal.averageMilkYield);
    const { volumeValue: expectedVolumeValue } = getMilkValue(animal.expectedMilkYield);
    const roundAverage = Math.round(averageVolumeValue);
    const roundExpected = Math.round(expectedVolumeValue);
    const { gender = [] } = getChildren(animal);
    const sathMeinGender = gender.find((g: any) => sathMein.includes(g));
    const childrenStatus = calfHealth.length && animal.children.filter((c: any) => calfHealth.includes(c.healthStatus));
    if (price && price.min >= 0 && price.max && includeCurrentAnimal) {
        includeCurrentAnimal = !!(roundDiscountPrice && isPrice(roundDiscountPrice, price));
    }
    if (currentMilk && currentMilk.min && currentMilk.max && includeCurrentAnimal) {
        includeCurrentAnimal = !!(roundAverage && isMilk(roundAverage, currentMilk));
    }
    if (expectedMilk && expectedMilk.min && expectedMilk.max && includeCurrentAnimal) {
        includeCurrentAnimal = !!(roundExpected && isMilk(roundExpected, expectedMilk));
    }
    if (sathMein && sathMein.length && includeCurrentAnimal) {
        includeCurrentAnimal = ((gender.length && (sathMeinGender)) || (sathMein.includes(CATTLE_DIRECTORY_STATUS.NOT_ALIVE) && (!animal.children.length)));
    }
    if (calfHealth && calfHealth.length && includeCurrentAnimal) {
        includeCurrentAnimal = childrenStatus.length;
    }
    return includeCurrentAnimal;
};

const filterAppliedAnimal = (allAnimal: Array<any>, filter: any, selectedAnimalStatus: Array<any>) => {
    let isAnimalFilter = false;
    let filteredAnimals: any = [];
    allAnimal.forEach(element => {
        if (isCurrentAnimalFallingUnderFilter(element, filter)) {
            isAnimalFilter = true;
            filteredAnimals.push(element);
        }
    });
    let filteredData = filteredAnimals;
    if (selectedAnimalStatus.length) {
        filteredData = filterByStatusData(filteredAnimals, selectedAnimalStatus);
    }
    if (isAnimalFilter) {
        return { filteredAnimal: filteredData, isAnimalFilter };
    }
    else {
        return { filteredAnimal: [], isAnimalFilter };
    }
}
const getAnimalRequestPayload = (farmAnimal: any, param = { farmId: "", skipPage: 0, pageSize: 10 }) => {
    const { selectedFilter } = CattleDirectoryConfig.getAllSelectedFilter(farmAnimal.filter);
    const { lactationNos = [], daysFromLastParturition = [] } = selectedFilter || {};
    const payload = {
        lactationNos: lactationNos,
        daysFromLastParturition: daysFromLastParturition,
        ...param
    };
    return payload;
}
const getAnimalBasicInformation = (item: any) => {
    const { genders = [] } = getChildren(item);
    const { discountFormatPrice = "" } = getPrice(item.pricingDetails) || "";
    const { days = "" } = getDiffDay(item.lastParturitionDate, new Date(), "days") || "--";
    const lastParturitionDate = getUtcDate(item.lastParturitionDate, "DD-MMM-YYYY") || ""
    let childGender = ((genders.length) && genders.toString()) || "--";
    const { widthFromBack = { value: "", uom: "" }, weight = { value: "", uom: "" }, midSectionLength = { value: "", uom: "" },
        height = { value: "", uom: "" }, girth = { value: "", uom: "" } } = item.measurements || {};
    const { horn = "", face = "", udder = "", body = "" } = item.beautyScore || {}
    const purchaseDate = ((item?.lastTransaction?.transactionDate) && getUtcDate(item.lastTransaction.transactionDate, "DD-MMM-YYYY")) || '';
    const broughtFrom = (item?.lastTransaction?.sellerDetails?.address?.city && item.lastTransaction.sellerDetails.address.city) || "";
    let about: any = [];
    if (item && !item.isChild) {
        let aboutList = [
            { label: "Parturition Date", value: lastParturitionDate },
            { label: "Days in Milk", value: days },
            { label: "Lactation Number", value: item.lactationNo || "--" },
            { label: "Pregnancy Status", value: "Not-pregnant" },
            { label: "Calf", value: `${childGender} ${item.tag}` },
            { label: "Type", value: item.animalType || "--" },
            { label: "Breed", value: item.breed || "--" },
            { label: "Price", value: discountFormatPrice || "-" }
        ];
        about = [...aboutList];
    } else {
        const dobDate = getUtcDate(item.dob, "DD-MMM-YYYY");
        const { days = "" } = getDiffDay(item.dob, new Date(), "days") || "-";
        let aboutList = [
            { label: "Date of Birth", value: dobDate || "-" },
            { label: "Age", value: days || "-" },
            { label: "Gender", value: item.gender || "-" },
            { label: "Mother Id", value: item?.parent?.tag || "-" }
        ];
        about = [...aboutList];
    }
    let measurement: any = [
        { label: "Height", value: ((height.value) && `${height.value} ${height.uom}`) || "-" },
        { label: "Mid-section Length", value: ((midSectionLength.value) && `${midSectionLength.value} ${midSectionLength.uom}`) || "-" },
        { label: "Pelvimetry: Ishial length", value: "-" },
        { label: "Pelvimetry: Width from back", value: ((widthFromBack.value) && `${widthFromBack.value} ${widthFromBack.uom}`) },
        { label: "Ghera", value: ((girth.value) && `${girth.value} ${girth.uom}`) || "-" },
        { label: "Weight", value: ((weight.value) && `${weight.value} ${weight.uom}`) || "-" }
    ];
    let movementHistory: any = [
        { label: "Brought from", value: (broughtFrom && broughtFrom) || "-" },
        { label: "Purchase date", value: (purchaseDate && purchaseDate) || "-" },
        { label: "Current Location", value: item?.farm?.name || "-" }
    ];
    let beautyScore: any = [
        { label: "Horn Beauty", value: (horn && horn) || "" },
        { label: "Face Beauty", value: (face && face) || "" },
        { label: "Udder Beauty", value: (udder && udder) || "" },
        { label: "Body Beauty", value: (body && body) || "" },

    ];
    return { about, measurement, movementHistory, beautyScore };
}
const getMilkInformation = (item: any, filterType = "L") => {
    const { milkQualityRecord = [], milkYieldRecord = [] } = item;
    const { volumeValue: avgVolValue, weightValue: avgWeight } = getMilkValue(item.averageMilkYield);
    const { volumeValue: expVolValue, weightValue: expWeight } = getMilkValue(item.expectedMilkYield);
    const snf = (milkQualityRecord.length && milkQualityRecord[0].snfPercentage) || "";
    const fat = (milkQualityRecord.length && milkQualityRecord[0].fatPercentage) || "";
    const lastDayMilkYeild = ((milkYieldRecord && milkYieldRecord.length) && `${milkYieldRecord[0].weightValue}kg`) || "";
    let filteredMilkValue: any = {
        snf: snf,
        fat: fat,
        milkYeild: lastDayMilkYeild,
        ...((filterType === "Kg") && {
            averageMilk: (((avgWeight) && avgWeight) || ""),
            expectedMilk: (((expWeight) && expWeight) || ""),
        }),
        ...((filterType === "L") && {
            averageMilk: ((avgVolValue && avgVolValue) || ""),
            expectedMilk: ((expVolValue && expVolValue) || ""),
        }),
    };
    return filteredMilkValue;
}
const getHealthInformation = (item: any) => {
    let diseaseHistory: any = [];
    const { healthDetails = { udder: {}, bodyVisibility: {}, vaccination: {}, tests: {} } } = item;
    const { teatRubberiness = "", teatPlacement = "", teatComparision = "", teatLength = "", teatGap = "", cleft = "", leftMilkVienVisibility = "", rightMilkVienVisibility = "" } = healthDetails.udder || {};
    const { bcs = "", sacralVisibility = "", tailheadVisibility = "", spineVisibility = "" } = healthDetails.bodyVisibility || {};
    const { fmd = "", blackQuarter = "" } = healthDetails.vaccination || {};
    const { brucella = "", ketosis = "", cmt = "" } = healthDetails.tests || {};
    const udderHealth = [
        { label: "Rubberiness of Teat", value: (teatRubberiness && teatRubberiness) || "-" },
        { label: "Teat Placement", value: (teatPlacement && teatPlacement) || "-" },
        { label: "Teat Comparison", value: (teatComparision && teatComparision) || "-" },
        { label: "Teat Length", value: (teatLength && teatLength) || "-" },
        { label: "Gap between Teats", value: (teatGap && teatGap) || "-" },
        { label: "Cleft of Udder", value: (cleft && cleft) || "-" },
        { label: "Knots in Teat", value: "-" },
        { label: "Milk Vein Left Visibility", value: (leftMilkVienVisibility && leftMilkVienVisibility) || "-" },
        { label: "Milk Vein Right Visibility", value: (rightMilkVienVisibility && rightMilkVienVisibility) || "-" },
    ];
    const healthRecords = [
        { label: "BCS", value: (bcs && bcs) || "-" },
        { label: "Sacral Visibility", value: (sacralVisibility && sacralVisibility) || "-" },
        { label: "Tailhead Visibility", value: (tailheadVisibility && tailheadVisibility) || "-" },
        { label: "Spine Visibility", value: (spineVisibility && spineVisibility) || "-" },
        { label: "Defects ", value: "-" }
    ];
    const vaccinationHistory = [
        { label: "FMD Vaccine", value: (fmd && fmd) || "-" },
        { label: "Black Quarter Vaccine", value: (blackQuarter && blackQuarter) || "-" },
        { label: "Brucella Test", value: (brucella && brucella) || "-" },
        { label: "Ketosis Test", value: (ketosis && ketosis) || "-" },
        { label: "CMT Test", value: (cmt && cmt) || "-" }
    ]
    return { udderHealth, healthRecords, vaccinationHistory, diseaseHistory };
}

export const CattleDirectoryConfig = {  //AnimalHelperService
    getAnimalListItem,
    filterByStatusData,
    filterByAnimalData,
    setFilter,
    getExportAnimal,
    copyAnimalData,
    updateFilterDataCount,
    filterAppliedAnimal,
    getAllSelectedFilter,
    getSwipeAnimalData,
    getAnimalRequestPayload,
    getAnimalBasicInformation,
    getMilkInformation,
    getHealthInformation
}
