import React from 'react'
import {
  CAvatar, 
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilBell, 
  cilLockLocked,  
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AuthThunk } from '../../shared/async-thunk/auth/actions';
import { AppModel } from '../../shared/models/app-model/app-model';
function UserDropdown(){
  const {displayName,photoURL} = useAppSelector((state: AppModel) => state.auth.authDetail);
  const dispatch=useAppDispatch(); 
  const onSignOut=()=>{
    dispatch(AuthThunk.signOut());
  }
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle   className="py-0 user_name" caret={false}>
        {photoURL ? <CAvatar src={photoURL} size="md" />:<CAvatar src={photoURL} size="md"  className="user-bg"/>}
        {displayName &&  ` Hi ${displayName}`}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-light fw-semibold py-2">My Account</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
            My Profile 
        </CDropdownItem> 
        {/* <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
            Tasks
          <CBadge color="primary" className="ms-2">
            0
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCreditCard} className="me-2" />
            Payments
          <CBadge color="primary" className="ms-2">
            0
          </CBadge>
        </CDropdownItem>  */}
        <CDropdownDivider />
         <CDropdownItem onClick={onSignOut}>
          <CIcon icon={cilLockLocked}  className="me-2" />
            Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default UserDropdown
