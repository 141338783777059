import {API_PATH_ALTERNATE} from "./api-constants";
const currentEnv = process.env.NODE_ENV === 'production' ? "PRODUCTION" : "DEVELOPMENT";
const DEV_API_BASE_URL=`${process.env.REACT_APP_API_BASE_URL}`;
// const DEV_API_BASE_URL = `http://localhost:3003${API_PATH_ALTERNATE.GRAPHQL_PATH}`; 
const appUrl = () => {
    const urls:any = { 
        DEVELOPMENT: process.env.REACT_APP_URL || '',
        PRODUCTION: process.env.REACT_APP_URL || ''
    };
    return urls[currentEnv];
};
 
const baseApiUrl = () => {
    const urls:any = { 
        DEVELOPMENT: DEV_API_BASE_URL, 
        PRODUCTION: DEV_API_BASE_URL
    };
    return urls[currentEnv];
};
  
export {
    currentEnv,
    appUrl,
    baseApiUrl,  
    DEV_API_BASE_URL
};
