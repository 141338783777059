const sellCattleQuery = `
animalType
breed
daysFromLastParturition
accompanyingChildGender
priceDiffPercentage
backPhotoPath
bcs
sellersExpectedPrice{
    amount
    currency
}
upperPriceLimit{
    amount
    currency
}
lowerPriceLimit{
    amount
    currency
}
seller{
    id
    name
    mobileNumber
    fathersName
    milkingEvening
    mobileVerified
    milkingMorning
    buffaloCount
    cowCount
    createdAt
    updatedAt
    createdBy
    updatedBy
    nextFollowUp
    address{
        city
        longitude
        latitude
        pincode
        state
        village
        name
        nearby
        addressLine1
        addressLine2
        district
        country
        
    }
}
qcJob{
    jobNo
    id
    animalId
    assignedTo{
        userId
        name
        mobileNumber
    }
    scheduledOn
  }
readyMilk{
    milkVolume{
        value
        uom
    }
    milkWeight{
        value
        uom
    }
}
id
otherDetails
createdAt
updatedAt
updatedBy{
    name
    roles
}
createdBy{
    name
    roles
}
lactationNumber
backPhotoPath
status
reason
requestNumber`

const updateSellCattleStatusQuery = (payloadString: any) => {
  return {
    query: `mutation($updateSycRequestStatusInput:UpdateSycRequestStatusInput!) {
            updateSycRequestStatus(updateSycRequestStatusInput:$updateSycRequestStatusInput){
                id
                status 
                reason
        }
      }`,
    variables: {
      "updateSycRequestStatusInput": payloadString
    },
  };
}

const updateSellCattleQuery = (id: string, payloadString: any) => {
  return {
    query: `mutation updateSycRequest($updateSycRequestInput: UpdateSycRequestInput!, $id: String!){
                    updateSycRequestData(updateSycRequestInput:$updateSycRequestInput, id: $id){
               ${sellCattleQuery}
        }
      }`,
    variables: {
      "updateSycRequestInput": payloadString,
      "id": id
    },
  };
}

const getSellCattleByStatusQuery = (param: any) => {
  const { take = 10, skip = 0, statuses = [], mobileNumber = "", requestNumber = null } = param;
  return {
    "query": `query ($fetchSycRequestInput:FetchSycRequestInput!) {
    getSycRequests (fetchSycRequestInput:$fetchSycRequestInput) {
      ${sellCattleQuery}
    }
  }`,
    variables: {
      "fetchSycRequestInput": {
        "take": take, "skip": skip, "statuses": statuses, "mobileNumber": mobileNumber, "requestNumber": requestNumber
      }
    }
  };
}
export const SellCattleQuery = {
  getSellCattleByStatusQuery,
  updateSellCattleStatusQuery,
  updateSellCattleQuery
}