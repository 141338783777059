import { FEED_COLUMN_NAME, FEED_ENTERY_INPUT, MILK_ENTERY_INPUT, MILK_ENTRY_TIME, MILK_QALITY_INPUT, NUMBER } from "../../constants/app-constants";
import { EVENING_MILK_TIME, getMilkTime, MORNING_MILK_TIME } from "./get-day-from-date";
import { VALID_MILK_NUMBER } from "./validation";
/* eslint-disable array-callback-return */
const COLUMN = ['milkYieldFromAnimal', 'calfDrinkFromTeat'];//calfDrinkFromBottle
const milkQalityColumn = ["snfPercentage", "fatPercentage", "waterPercentage", "densityValue"];
const FEED_COLUMN = [FEED_COLUMN_NAME.OFFERED];
const feedEntery = { concentrate: FEED_ENTERY_INPUT, roughage: FEED_ENTERY_INPUT, silage: FEED_ENTERY_INPUT };
const milkYeildEntery = { ...MILK_ENTERY_INPUT, recordId: "", isUpdate: false, milkingTime: "", pastDateMilkRecord: [] };
export class MilkingService {
    static getMilkYeildColumn = (data: any) => {
        return {
            milkYieldFromAnimal: data.milkYieldFromAnimal || "",
            calfDrinkFromTeat: data.calfDrinkFromTeat || "",
            calfDrinkFromBottle: data.calfDrinkFromBottle || "0",
            pastDateMilkRecord: data.pastDateMilkRecord,
            recordId: data.recordId || "",
            ...(!data.milkYieldFromAnimal && { isUpdate: false }),
        }
    }
    static getMapMilkRecord = (records: any) => {
        const animalsMilkRecords = records.filter((item: any) => {
            const animalId = (item.id || item.animalId);
            item.animalId = animalId;
            const morning = MilkingService.getMilkYeildColumn(item.morning) || milkYeildEntery;
            const evening = MilkingService.getMilkYeildColumn(item.evening) || milkYeildEntery;
            const morningPastAverage = [...morning.pastDateMilkRecord, ...evening.pastDateMilkRecord];
            const eveningPastAverage = [...morningPastAverage, morning];
            const morningAverage = MilkingService.geMilkAverageReport(morningPastAverage, morning.milkYieldFromAnimal, "milkYieldFromAnimal");
            const eveningAverage = MilkingService.geMilkAverageReport(eveningPastAverage, evening.milkYieldFromAnimal, "milkYieldFromAnimal");
            item.morning = morning;
            item.morningMilkAverage = morningAverage;
            item.evening = evening;
            item.eveningMilkAverage = eveningAverage;
            //yesterday avg =((yesterdayMorningRecord+yesterdayEvening+todayMorning)/totalPastRecord)
            return item;
        });
        return animalsMilkRecords;
    }

    static geMilkAverageReport = (data: Array<any>, currentRecord: any, column: string) => {
        let totalMilkYieldFromAnimal: any = 0;
        data.forEach((item: any) => {
            const number = parseFloat(item[column]) || 0;
            if (number && VALID_MILK_NUMBER.test(number)) {
                totalMilkYieldFromAnimal += number;
            }
        });
        const currentMilkRecord = parseFloat(currentRecord);
        if (currentMilkRecord && totalMilkYieldFromAnimal && VALID_MILK_NUMBER.test(currentMilkRecord)) {
            const milkAverage = (totalMilkYieldFromAnimal / data.length).toFixed(2);
            const averageReport: any = (currentMilkRecord - parseFloat(milkAverage)) / parseFloat(milkAverage) * NUMBER.HUNDRED;
            return (Math.round(averageReport * NUMBER.HUNDRED) / NUMBER.HUNDRED).toFixed(NUMBER.TWO);
        }
        return null
    }
    static getTimeColumn = (time: string) => {
        if (time === MORNING_MILK_TIME) {
            return MILK_ENTRY_TIME.MORNING.toLowerCase()
        } else if (time === EVENING_MILK_TIME) {
            return MILK_ENTRY_TIME.EVENING.toLowerCase()
        }
    }
    static getMapCreateUpdateMilkYeildRecord = (item: any, elem: any) => {
        const milkingTime = elem.milkingTime.replace(/\n/g, " ").split(" ")[1];
        const time = MilkingService.getTimeColumn(milkingTime);
        if (time && ['morning', "evening"].includes(time)) {
            if (elem.docId) {
                item[time].recordId = elem.docId;
            }
            else if (elem.recordId) {
                item[time].recordId = elem.recordId;
            }
            item[time].milkingTime = milkingTime;
            item[time].calfDrinkFromBottle = elem.calfDrinkFromBottle;
            item[time].calfDrinkFromTeat = elem.calfDrinkFromTeat;
            item[time].milkYieldFromAnimal = elem.milkYieldFromAnimal;
            item[time]["isUpdate"] = null;
        }
        return item
    }
    static getCreateUpdateMilkRecord = async (data: Array<any>, milkRecords: Array<any>,) => {
        const updatedRecord = JSON.parse(JSON.stringify(milkRecords));
        const allRecord = updatedRecord.filter((item: any, i: number) => {
            data.map((elem) => {
                if ((item.animalId === elem.animalId && elem.docId && elem.milkingTime)) {
                    return MilkingService.getMapCreateUpdateMilkYeildRecord(item, elem);
                }
                else if ((item.animalId === elem.animalId && elem.recordId)) {
                    return MilkingService.getMapCreateUpdateMilkYeildRecord(item, elem);
                }
                return elem
            });
            return item
        });
        return allRecord;
    }
    static getMilkQualityRecord = (list: Array<any>, data?: Array<any>) => {
        if (data && data.length) {
            const records = JSON.parse(JSON.stringify(list));
            const allRecord = records.filter((item: any, i: number) => {
                data.map((elem) => {
                    if ((item.animalId === elem.animalId && elem.recordId)) {
                        item.id = elem.recordId;
                        item.densityValue = elem.densityValue;
                        item.fatPercentage = elem.fatPercentage;
                        item.snfPercentage = elem.snfPercentage;
                        item.waterPercentage = elem.waterPercentage;
                    }
                    return elem
                });
                return item
            })
            return allRecord;
        } else {
            let qualityRecord: any = [];
            list.map((item: any) => {
                if (item.milkQualityRecord) {
                    qualityRecord.push({
                        animalId: item.animalId, tag: item.tag, ...item.milkQualityRecord
                    });
                } else {
                    qualityRecord.push({ animalId: item.animalId, tag: item.tag, ...MILK_QALITY_INPUT });
                }
            });
            return qualityRecord
        }
    }
    static getval = (data: any) => {
        let val = Object({});
        Object.keys(data).forEach((obj: any) => {
            val[obj] = data[obj] ? data[obj] : FEED_ENTERY_INPUT;
        });
        return val
    }
    static getMapMilFeedkRecord = (records: any) => {
        let feedTrackerRecords: any = [];
        records.map((item: any, i: number) => {
            const getObject = ((data: any, key: any) => (data[key] ? MilkingService.getval(data[key]) : data[key] || feedEntery) || feedEntery);
            const morning = getObject(item, 'morning');
            const evening = getObject(item, 'evening');
            feedTrackerRecords.push({
                tag: item.tag,
                animalId: item.id,
                morning: morning,
                evening: evening
            })
        });
        return feedTrackerRecords;
    }
    static getMapUpdateRecord = (updateRecord: any, records: any, entryTime: any) => {
        let updatedRecords: any = [];
        records.map((item: any) => {
            if (updateRecord.recordId === item[entryTime].recordId) {
                const obj = {
                    ...item,
                    isUpdate: null,
                    recordId: item[entryTime].recordId,
                    milkYieldFromAnimal: updateRecord.milkYieldFromAnimal,
                    calfDrinkFromTeat: updateRecord.calfDrinkFromTeat,
                    calfDrinkFromBottle: updateRecord.calfDrinkFromBottle,
                }
                updatedRecords.push(obj);
            } else {
                updatedRecords.push(item);
            }
        });
        return updatedRecords;
    }
    static getIsMilkYeildFill = (item: any) => {
        return (parseFloat(item.milkYieldFromAnimal) > 0) && (parseFloat(item.calfDrinkFromTeat) === 0 || parseFloat(item.calfDrinkFromTeat) >= 0);
    }
    static getQualityIsFill = (item: any) => {
        return (item.snfPercentage || item.snfPercentage === 0) && (item.fatPercentage || item.fatPercentage === 0) && (item.waterPercentage || item.waterPercentage === 0) && (item.densityValue || item.densityValue === 0);
    }
    static checkProperties = (obj: any, column: Array<any>, index: number) => {
        let currentField;
        Object.keys(obj).forEach((key) => {
            if ((column.includes(key) && obj[key]) && (column.includes(key) && parseFloat(obj[key]) >= 0)) {
                currentField = index;
            }
        })
        return currentField;
    }
    static checkMilkProperties = (obj: any, column: Array<any>, index: number) => {
        let currentField;
        Object.keys(obj).forEach((key) => {
            if (((column.includes(key) && obj[key]) && (column.includes(key) && obj[key] !== 0)) && ((column.includes(key) && obj[key] !== undefined))) {
                currentField = index;
            }
        });
        return currentField;
    }
    static getMilkPayload = (item: any, date: string, isCreate: any) => {
        return {
            animalId: item.animalId,// ...(isCreate && { animalId: item.animalId }),
            ...(!isCreate && { recordId: item.recordId }),
            milkYieldFromAnimal: parseFloat(item.milkYieldFromAnimal),
            calfDrinkFromTeat: parseFloat(item.calfDrinkFromTeat),
            calfDrinkFromBottle: parseFloat(item.calfDrinkFromBottle),
            milkingTime: date,//...(isCreate && { milkingTime: date })
        };
    }
    static getMilkYieldFillRecord = (record: Array<any>, date: string) => {
        let createRecords: any = [], validateField: any = [], updateRecords: any = [];
        record.filter((item: any, index: number) => {
            Object.keys(item).forEach((key) => {
                const ignoreItem = ["morningMilkAverage", "eveningMilkAverage", "id", "tag"].includes(key);
                const foundIndex: any = (!ignoreItem) && MilkingService.checkMilkProperties(item[key], COLUMN, index);
                if (!ignoreItem && item.animalId && foundIndex >= 0 && !MilkingService.getIsMilkYeildFill(item[key])) {
                    validateField.push({ index: foundIndex, time: key });
                }
                if (!ignoreItem && item.animalId && MilkingService.getIsMilkYeildFill(item[key])) {
                    const dateTime = `${date} ${getMilkTime(key)}`;
                    const record = { ...item[key], animalId: item.animalId };
                    if (item[key].recordId && item[key].isUpdate) {
                        updateRecords.push(MilkingService.getMilkPayload(record, dateTime, false));
                    } else if (!item[key].recordId) {
                        createRecords.push(MilkingService.getMilkPayload(record, dateTime, true))
                    }
                }
            });
        });
        return { createRecords, validateField, updateRecords };
    }
    static validateMilkYeild = (inputValidate: Array<any>, obj: any, name: any, index: number, time: any) => {
        let isValid = true;
        const isCalfDrinkFromTeat = (name === "calfDrinkFromTeat");
        inputValidate.forEach((validate: any) => {
            if ((obj && validate) && (validate.index === index) && (time === validate.time)) {
                if (parseFloat(obj.milkYieldFromAnimal) > 0 && (name === "milkYieldFromAnimal")) {
                    isValid = true
                } else if ((obj.calfDrinkFromTeat === "" && isCalfDrinkFromTeat) || (parseFloat(obj.calfDrinkFromTeat) < 0 && isCalfDrinkFromTeat)) {
                    isValid = false;
                } else if (!isCalfDrinkFromTeat) {
                    isValid = false
                }
            }
        })
        return isValid;
    }
    static getMilkQualityPayload = (item: any, dateTime: string, isCreate: any) => {
        return {
            animalId: item.animalId,
            // ...(!isCreate && { recordTime: item.recordTime }),
            fatPercentage: parseFloat(item.fatPercentage),
            snfPercentage: parseFloat(item.snfPercentage),
            waterPercentage: parseFloat(item.waterPercentage),
            densityValue: parseFloat(item.densityValue),
            sourceLocation: "Customer",
            densityUom: item.densityUom,
            recordTime: dateTime,
            ...(!isCreate && { recordId: item.id })
        };
    }
    static getMilkQualityFillRecord = (record: Array<any>, date: string) => {
        const time = "morning";
        let createRecords: any = [], validateField: any = [], updateRecords: any = [];
        record.filter((item: any, index: number) => {
            const foundIndex: any = MilkingService.checkMilkProperties(item, milkQalityColumn, index);
            if (item.animalId && foundIndex >= 0 && !MilkingService.getQualityIsFill(item)) {
                validateField.push({ index: foundIndex, });
            }
            if (item.animalId && MilkingService.getQualityIsFill(item)) {
                const dateTime = `${date} ${getMilkTime(time)}`;
                const record = { ...item, animalId: item.animalId };
                if (item.id && item.isUpdate) {
                    updateRecords.push(MilkingService.getMilkQualityPayload(record, dateTime, false));
                } else if (!item.id) {
                    createRecords.push(MilkingService.getMilkQualityPayload(record, dateTime, true))
                }
            }
        });
        return { createRecords, validateField, updateRecords };
    }
    static getFeedPayload = (item: any, isCreate: any) => {
        return {
            ...(isCreate && { animalId: item.animalId }),
            ...(!isCreate && { documentId: item.id }),
            feedId: item.feedId,
            ...({ weight: { value: parseFloat(item.weightValue), uom: "kg" } }),
            recordTime: item.recordTime,
            sourceLocation: item.sourceLocation,
        };
    }
    static getFeedFill = (item: any, time: any, cell: any) => {
        return item[time][cell]['weightValue'];
    }
    static getFillFeedRecord = (records: Array<any>, obj: any) => {
        let createRecords: any = [], validateField: any = [], updateRecords: any = [];
        records.filter((item: any, index: number) => {
            Object.keys(item).forEach((time) => {
                Object.keys(item[time]).forEach((cell: any) => {
                    const filllIndex: any = MilkingService.checkProperties(item[time][cell], FEED_COLUMN, index);
                    if (item.animalId && filllIndex >= 0 && !MilkingService.getFeedFill(item, time, cell)) {
                        validateField.push(filllIndex);
                    }
                    if (item.animalId && MilkingService.getFeedFill(item, time, cell)) {
                        const recordTime = `${obj.date} ${getMilkTime(time)}`;
                        const feedId = obj.feedIdToType[cell];
                        const record = { ...item[time][cell], feedId: feedId, animalId: item.animalId, recordTime: recordTime, sourceLocation: obj.sourceLocation };
                        if (item[time][cell].feedId && item[time][cell].isUpdate) {
                            updateRecords.push(MilkingService.getFeedPayload(record, false));
                        } else if (!item[time][cell].feedId) {
                            createRecords.push(MilkingService.getFeedPayload(record, true))
                        }
                    }
                });
            });
        });
        return { createRecords, validateField, updateRecords };
    }
    static getMilkTableHeight = (ref: any, tableHeader = 90, pageHeader = 100) => {
        const offsetHeight = ref?.current?.offsetHeight || 0;
        const offset = offsetHeight + tableHeader + pageHeader;
        return `calc(100vh - ${offset}px)`;
    }
}
