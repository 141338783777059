import { createSlice } from "@reduxjs/toolkit";
import { DealReducerModel, UpdateDealModel } from "../../models/deal/deal.models";
import { DealThunk } from "./actions";

export const initialState: DealReducerModel = {
  dealList: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isEditLoading: false,
  editDeal: { id: "", status: "" }
};
export const DealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    editDeal: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.editDeal = action.payload as UpdateDealModel;
    },
    setDeal: (state, action) => {
      state.dealList = action.payload as any;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(DealThunk.fetchDeals.pending, (state) => {
        state.dealList = [];
        state.isLoading = true;
      })
      .addCase(DealThunk.fetchDeals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dealList = action.payload as any;
        state.editDeal = { id: "", status: "" }
      })
      .addCase(DealThunk.fetchDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.dealList = [];
        state.editDeal = { id: "", status: "" }
      })
      .addCase(DealThunk.updateDealStatus.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(DealThunk.updateDealStatus.fulfilled, (state, action) => {
        state.dealList = action.payload as any;
        state.isEditLoading = false;
      })
      .addCase(DealThunk.updateDealStatus.rejected, (state, action) => {
        // console.log("rejected");
      })
      .addCase(DealThunk.createDeal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(DealThunk.createDeal.fulfilled, (state, action) => {
        state.dealList = action.payload as any;
        state.isLoading = false;
      })
      .addCase(DealThunk.createDeal.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
        // console.log("rejected");
      })
  },
});

const { editDeal, setDeal } = DealSlice.actions;
export const DealReducerActions = {
  editDeal,
  setDeal,
}
export default DealSlice.reducer;
