import { createSlice } from "@reduxjs/toolkit";
import { getSearchData } from "../../helper/search-input";
import {
  AssignedModel,
  QualityCheckReducerModel,
} from "../../models/quality-check/qc.models";
import { QualityCheckThunk } from "./action";

export const initialState: QualityCheckReducerModel = {
  reviewList: [],
  assignedList: [],
  isLoading: false,
  questionList: [],
  filterList: [],
  search: "",  
  selectedCattle: { cattleId: "" ,orderId:''},
  isQuestionLoading: false,
  isqcOrderTagNameUpdating: false,
  isError:false,
  isCloseReportLoading:false,
  isSubmitReportLoading:false,
  message:"",
  qcMilkingData:{
    averageMilking:null,
    minimumMilking:null,
    predictedMilking:null
  }
};
export const QualityCheckSlice = createSlice({
  name: "qualityCheck",
  initialState,
  reducers: {
    onUpdateQuestion: (state, action) => {
      state.questionList = action.payload;
    },
    onSearchAssigned: (state, action) => {
      const search = action.payload;
      state.search = search;
      const existingReview = JSON.parse(JSON.stringify(state.assignedList));
      const filterList: any = getSearchData(existingReview, search);
      state.filterList = search ? [...filterList] : [...state.assignedList];
    }, 
    onSelectCattle:(state,action)=>{
      state.selectedCattle=action.payload;
    },
    setIsLoading:(state, action) =>{ 
      state.isLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(QualityCheckThunk.fetchQCAssigned.pending, (state) => {
        state.assignedList = [];
      })
      .addCase(QualityCheckThunk.fetchQCAssigned.fulfilled, (state, action) => {
        state.isLoading = false;
        state.search = "";
        state.assignedList = action.payload as AssignedModel[];
        state.filterList = action.payload as AssignedModel[];
      })
      .addCase(QualityCheckThunk.fetchQCAssigned.rejected, (state, action) => {
        state.isLoading = false;
        state.assignedList = [];
      })
      .addCase(QualityCheckThunk.fetchQCQuestions.pending, (state) => {
        state.isQuestionLoading = true;
      })
      .addCase(
        QualityCheckThunk.fetchQCQuestions.fulfilled,
        (state, action) => {
          state.isQuestionLoading = false;
          state.questionList = action.payload as any;
        }
      )
      .addCase(QualityCheckThunk.fetchQCQuestions.rejected, (state, action) => {
        state.isQuestionLoading = false;
        state.questionList = [];
      })
      .addCase(QualityCheckThunk.submitQCReport.pending, (state) => {
        state.isSubmitReportLoading = true;
      })
      .addCase(QualityCheckThunk.submitQCReport.fulfilled,(state, action) => {
          state.isSubmitReportLoading = false; 
      })
      .addCase(QualityCheckThunk.submitQCReport.rejected, (state, action) => {
        state.isSubmitReportLoading = false; 
      })
      .addCase(QualityCheckThunk.closeQCReport.pending, (state) => {
        state.isCloseReportLoading = true;
      })
      .addCase(QualityCheckThunk.closeQCReport.fulfilled,(state, action) => {
          state.isCloseReportLoading = false; 
      })
      .addCase(QualityCheckThunk.closeQCReport.rejected, (state, action) => {
        state.isCloseReportLoading = false; 
      })
      .addCase(QualityCheckThunk.updateqcOrderTagName.pending, (state) => {
        state.isqcOrderTagNameUpdating = true;
      })
      .addCase(
        QualityCheckThunk.updateqcOrderTagName.fulfilled,
        (state, action) => {
          state.isqcOrderTagNameUpdating = false;
          if (action.payload.success) {
            state.selectedCattle = { cattleId: action.payload.newTagName as string,orderId:action.payload.orderId };
            state.filterList.map((qcOrder) => {
              if (qcOrder.order === action.payload.orderId) qcOrder.cattleId = action.payload.newTagName;
            });
            state.message=""
          }
          else state.message="Try again, duplicate Tag Name";
        }
      )
      .addCase(QualityCheckThunk.updateqcOrderTagName.rejected, (state, action) => {
        state.isqcOrderTagNameUpdating = false;
        state.message="Server failure, Try Again"
        state.isError = true;
        }
      )
      .addCase(QualityCheckThunk.fetchqcMilkingByAnimalTag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(QualityCheckThunk.fetchqcMilkingByAnimalTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.qcMilkingData=action.payload as any
      })
      .addCase(QualityCheckThunk.fetchqcMilkingByAnimalTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError=true;
        state.qcMilkingData = {
          averageMilking: null,
          minimumMilking: null,
          predictedMilking: null,
        };
        state.message=action.payload as any
      });
  },
});
const { setIsLoading,onUpdateQuestion, onSearchAssigned, onSelectCattle } =
  QualityCheckSlice.actions;
export const QCReducerAction = {
  setIsLoading,
  onUpdateQuestion,
  onSearchAssigned,
  onSelectCattle,
};
export default QualityCheckSlice.reducer;
