import { createAsyncThunk } from '@reduxjs/toolkit';
import { animalsService } from '../../services/api';
import { ActionType } from './action-types';
import { AnimalsQuery } from '../../query/animal-query';
import { MilkingService } from '../../helper/milking-service';
import { AppModel } from '../../models/app-model/app-model';
import { getYearMonthDate } from '../../helper/get-day-from-date';
const fetchAsyncFeedTracker = createAsyncThunk(
  ActionType.GET_FEED_RECORD,
  async (date: any, { getState, rejectWithValue }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(auth.token, AnimalsQuery.getFeedQuery(getYearMonthDate(date)));
      const milkRecords = response.data.data.getFeedConsumptions || [];
      const animalsMilkRecords: any = MilkingService.getMapMilFeedkRecord(milkRecords);
      return animalsMilkRecords;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data)
    }
  }
);
const createAsyncFeedRecord = createAsyncThunk(
  ActionType.SET_CREATE_FEED_RECORD,
  async (obj: any, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { auth, feedHistory } = getState() as AppModel;
    const { selectedDate, createRecords, isUpdate } = obj;
    await animalsService.getQueryRequest(auth.token, AnimalsQuery.createFeedQueryMutation(createRecords));
    if (!isUpdate) {
      const animalsMilkRecords: any = await dispatch(fetchAsyncFeedTracker(selectedDate));
      return animalsMilkRecords.payload;
    } else {
      return feedHistory.feedTrackerList;
    }
  }
);
const updateAsyncFeedRecord = createAsyncThunk(
  ActionType.SET_UPDATE_FEED_RECORD,
  async (data: any, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { auth, feedHistory } = getState() as AppModel;
    const { updateRecords, selectedDate, isCreate } = data;
    await animalsService.getQueryRequest(auth.token, AnimalsQuery.updateFeedQueryMutation(updateRecords));
    if (isCreate) {
      const animalsMilkRecords: any = await dispatch(fetchAsyncFeedTracker(selectedDate));
      return animalsMilkRecords.payload;
    } else {
      return feedHistory.feedTrackerList;
    }
  });
export const AnimalFeedThunk = { 
  fetchAsyncFeedTracker,
  createAsyncFeedRecord,
  updateAsyncFeedRecord
}
