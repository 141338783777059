import { FeedReducerModel } from "../../models/feed/feed.models"; 
import { createSlice } from '@reduxjs/toolkit';
import { AnimalFeedThunk } from "./actions";
import { getSearchColumn } from "../../helper/search-input";
export const initialState: FeedReducerModel = {
  feedTrackerList: [],
  filteredFeedRecords: [],
  isLoading: false,
  isFetching: false,
  message: "",
  isSuccess: false,
  isError: false,
  feedSearch: "",
};
 
export const FeedReducerSlice = createSlice({
    name: "feed",
    initialState,
    reducers: { 
      setMilkFeedTracker: (state,action) => { 
        state.filteredFeedRecords = action.payload;
      }, 
      onFeedSearch:(state,action)=>{
        const search=action.payload;
        state.feedSearch=search; 
        const existingMilkRecords = JSON.parse(JSON.stringify(state.feedTrackerList));
        const filterList:any=getSearchColumn(existingMilkRecords,search,'tag'); 
        state.filteredFeedRecords=search?[...filterList]:[...state.feedTrackerList];
      },
    },
    extraReducers: ( builder ) => {
      builder 
          .addCase(AnimalFeedThunk.fetchAsyncFeedTracker.pending, (state) => { 
            state.isLoading=true;
          })
          .addCase(AnimalFeedThunk.fetchAsyncFeedTracker.fulfilled, (state, action) => { 
            state.isLoading=false;
            state.feedTrackerList = action.payload;
            state.filteredFeedRecords= action.payload;
          })
          .addCase(AnimalFeedThunk.fetchAsyncFeedTracker.rejected, (state) => { 
             state.isLoading=false;
             state.feedTrackerList = [];
             state.filteredFeedRecords= [];
          }) 
          .addCase(AnimalFeedThunk.createAsyncFeedRecord.pending, (state) => { 
            state.isFetching=true;
          })
          .addCase(AnimalFeedThunk.createAsyncFeedRecord.fulfilled, (state, action) => { 
            state.isFetching=false; 
          })
          .addCase(AnimalFeedThunk.createAsyncFeedRecord.rejected, (state) => { 
              state.isFetching=false;
          })
          .addCase(AnimalFeedThunk.updateAsyncFeedRecord.pending, (state) => { 
            state.isFetching=true;
          })
          .addCase(AnimalFeedThunk.updateAsyncFeedRecord.fulfilled, (state, action) => { 
            state.isFetching=false; 
          })
          .addCase(AnimalFeedThunk.updateAsyncFeedRecord.rejected, (state) => { 
                state.isFetching=false;
          })  
    },
  });  
const {setMilkFeedTracker,onFeedSearch}=FeedReducerSlice.actions;
export const FeedReducerActions={  
  setMilkFeedTracker, 
  onFeedSearch
}
  export default FeedReducerSlice.reducer;
  
