import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer from '../root-reducer';
import storage from 'redux-persist/lib/storage';
import {
  persistStore, persistReducer, createMigrate,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { migrations } from './migration';
const persistConfig = {
  key: "root",
  storage,
  version: 5,
  migrate: createMigrate(migrations),
  whitelist: [
    "farm",
    "animals"
  ]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
