import React, { Fragment, useEffect } from "react";
import "./style.scss";
import UserDropdown from "./user-dropdown";
import { CHeaderBrand, CHeaderNav } from "@coreui/react";
import H1 from "../../shared/components/h1";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FarmThunk } from "../../shared/async-thunk/farm/actions";
import { AppModel } from "../../shared/models/app-model/app-model";
import { useLocation } from "react-router-dom";
import { FarmReducerAction } from "../../shared/async-thunk/farm/reducer";
import { UserThunk } from "../../shared/async-thunk/user/actions";
import MainLogo from "../../shared/images/main-logo.png";
interface NavbarProps {
  onClickNav?: (e: any, b?: any) => void;
}
function Navbar(props: NavbarProps) {
  const { onClickNav = () => {} } = props;
  const { pathname } = useLocation();
  const { token, authDetail, selectedFarm } = useAppSelector(
    (state: AppModel) => ({
      token: state.auth.token,
      authDetail: state.auth.authDetail,
      selectedFarm: state.farm.selectedFarm,
    })
  );
  const { customAttributes } = authDetail || {};
  const { roles = [] } = customAttributes || {};
  const dispatch = useAppDispatch();

  useEffect(() => {
    const path = pathname.split("/") || [""];
    const filterPath =
      path.some((name) =>
        ["cattle-directory", "cattle-detail"].includes(name)
      ) || false;
    if (
      selectedFarm &&
      selectedFarm.id &&
      ["ALL"].includes(selectedFarm.id) &&
      !filterPath
    ) {
      dispatch(FarmReducerAction.setSelectFarm({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    if (roles.length && token) {
      dispatch(FarmThunk.getAllFarm());
      dispatch(
        UserThunk.getUserByRole({
          role: "QCRep",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, roles]);
  return (
    <Fragment>
      <CHeaderBrand className="">
        <img src={MainLogo} alt={""} className={"brand-pic"} />
        <H1 text={"Sanjay Dashboard"} customClass={"brand-logo"} />
      </CHeaderBrand>
      <CHeaderNav className="d-none d-md-flex me-auto">
        {/* {(!!farmList.length) && <Select customClass={"select-farm"} options={farmList} onChange={onchangeFarm} placeholder={"Select farm...."} />} */}
      </CHeaderNav>
      <CHeaderNav></CHeaderNav>
      <CHeaderNav onClick={onClickNav} className="ms-3">
        <UserDropdown />
      </CHeaderNav>
    </Fragment>
  );
}

export default Navbar;
