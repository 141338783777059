
import { AnimalMilkThunk } from "./actions";
import { AnimalMilkQualityRecord, AnimalMilkRecord } from "../../models/animal/animal.models";
const extraReducers = (builder: any) => {
    builder
        .addCase(AnimalMilkThunk.fetchAsyncMilkRecord.pending, (state: any) => {
            state.isLoading = true;
            state.inputValidate = [];
        })
        .addCase(
            AnimalMilkThunk.fetchAsyncMilkRecord.fulfilled,
            (state: any, action: any) => {
                state.isLoading = false;
                state.isSuccess = true;
                // state.milkYeildRecords = action.payload.milkYeildRecords as AnimalMilkRecord[];
                state.filterYeildList = action.payload.milkYeildRecords as AnimalMilkRecord[];
                state.totalRecords = action.payload.totalRecords as number;
            }
        )
        .addCase(
            AnimalMilkThunk.fetchAsyncMilkRecord.rejected,
            (state: any, action: any) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload as string;
                // state.milkYeildRecords = [];
                state.filterYeildList = [];
            }
        )
        .addCase(AnimalMilkThunk.createAsyncMilkRecord.pending, (state: any) => {
            state.isFetching = true;
        })
        .addCase(AnimalMilkThunk.createAsyncMilkRecord.fulfilled, (state: any, action: any) => {
            state.isFetching = false;
            // state.milkYeildRecords = action.payload as AnimalMilkRecord[];
            state.filterYeildList = action.payload as AnimalMilkRecord[];
            state.milkSearch = "";
        }
        )
        .addCase(AnimalMilkThunk.createAsyncMilkRecord.rejected, (state: any) => {
            state.isFetching = false;
        })
        .addCase(AnimalMilkThunk.updateAsyncMilkRecord.pending, (state: any) => {
            state.isFetching = true;
        })
        .addCase(AnimalMilkThunk.updateAsyncMilkRecord.fulfilled, (state: any, action: any) => {
            state.isFetching = false;
            // state.milkYeildRecords = action.payload as AnimalMilkRecord[];
            state.filterYeildList = action.payload as AnimalMilkRecord[];
            state.milkSearch = "";
        }
        )
        .addCase(AnimalMilkThunk.updateAsyncMilkRecord.rejected, (state: any) => {
            state.isFetching = false;
        })
        .addCase(
            AnimalMilkThunk.fetchAsyncMilkRecordByAnimalId.pending,
            (state: any) => {
                state.isFetching = true;
                state.animalMilkRecords = [];
            }
        )
        .addCase(
            AnimalMilkThunk.fetchAsyncMilkRecordByAnimalId.fulfilled,
            (state: any, action: any) => {
                state.isFetching = false;
                state.animalMilkRecords = action.payload as any;
            }
        )
        .addCase(
            AnimalMilkThunk.fetchAsyncMilkRecordByAnimalId.rejected,
            (state: any, action: any) => {
                state.isFetching = false;
                state.animalMilkRecords = [];
                state.isError = true;
                state.message = action.payload as string;
            }
        ).addCase(
            AnimalMilkThunk.fetchAsyncMilkQuality.pending,
            (state: any) => {
                state.isLoading = true;
            }
        )
        .addCase(
            AnimalMilkThunk.fetchAsyncMilkQuality.fulfilled,
            (state: any, action: any) => {
                state.isLoading = false;
                state.milkQualityRecords = action.payload as any;
                state.filteredMilkQuality = action.payload as any;
            }
        )
        .addCase(
            AnimalMilkThunk.fetchAsyncMilkQuality.rejected,
            (state: any, action: any) => {
                state.isLoading = false;
                state.milkQualityRecords = [];
                state.filteredMilkQuality = [];
                state.isError = true;
                state.message = action.payload as string;
            }
        )
        .addCase(AnimalMilkThunk.createAsyncMilkQuality.pending, (state: any) => {
            state.isFetching = true;
        })
        .addCase(AnimalMilkThunk.createAsyncMilkQuality.fulfilled, (state: any, action: any) => {
            state.isFetching = false;
            state.milkQualityRecords = action.payload as AnimalMilkQualityRecord[];
            state.filteredMilkQuality = action.payload as AnimalMilkQualityRecord[];
            state.milkSearch = "";
        }
        )
        .addCase(AnimalMilkThunk.createAsyncMilkQuality.rejected, (state: any) => {
            state.isFetching = false;
        })
        .addCase(AnimalMilkThunk.updateAsyncMilkQuality.pending, (state: any) => {
            state.isFetching = true;
        })
        .addCase(AnimalMilkThunk.updateAsyncMilkQuality.fulfilled, (state: any, action: any) => {
            state.isFetching = false;
            state.milkQualityRecords = action.payload as AnimalMilkQualityRecord[];
            state.filteredMilkQuality = action.payload as AnimalMilkQualityRecord[];
            state.milkSearch = "";
        }
        )
        .addCase(AnimalMilkThunk.updateAsyncMilkQuality.rejected, (state: any) => {
            state.isFetching = false;
        })
}
export default extraReducers;