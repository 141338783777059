import axios from "axios";
import { baseApiUrl ,DEV_API_BASE_URL} from "../../../constants/url-constants";
const BASE_API_URL = baseApiUrl() || DEV_API_BASE_URL
const DEBUG = process.env.NODE_ENV === "development";
/* Create axios instance */ 
const api = axios.create({
    baseURL: BASE_API_URL
});


api.interceptors.request.use(
    (config) => {
        /** In dev, intercepts request and logs it into console for dev */
        if (DEBUG) {
            console.info('✉️ ', config);
        }
        return config;
    },
    (error) => {
        if (DEBUG) {
            console.error('✉️ ', error);
        }
        return Promise.reject(error);
    }
);

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use(
    (response) => {
        if (DEBUG) {
            console.info('📩 ', response);
        }
        return response;
    },
    (error) => {
        // if (!IGNORE_PRIVATE_PRODUCT_APIS.includes(error.config.url)) {
        //     captureException(error); //sentry
        // }

        if (error.response && error.response.data) {
            if (DEBUG) {
                console.error('Error: ', error.response.data);
            }
            return Promise.reject(error.response.data);
        }
        if (DEBUG) {
            console.error('📩 ', error);
        }
        return Promise.reject(error.message);
    }
);

export default api;
