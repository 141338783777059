import { memo } from "react";
import Icon from "../../images/booking-icon.svg";
interface BookingIconProps {
  alt?: string;
  className?: string;
}
const BookingIcon = (props: BookingIconProps) => {
  const { alt = "", className = "" } = props;
  return <img src={Icon} alt={alt} className={className} />;
};
export default memo(BookingIcon);
