import { memo } from "react";
import Icon from "../../images/feed-icon.svg";
interface FeedIconProps {
    alt?: string;
    className?:string;
}
const FeedIcon = (props: FeedIconProps) => {
    const { alt = "", className = "" } = props;
    return <img src={Icon} alt={alt} className={className} />
}
export default memo(FeedIcon);