import { ActionType } from './action-types';
import { auth } from "../../../firebase";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthDetail, userRoleIsAdmin } from '../../helper/map-service';
import { AuthReducerAction } from "./reducer";
const setAuthDetail = createAsyncThunk(
  ActionType.SET_USER_Detail,
  async (data: any, thunkAPI) => {
    thunkAPI.dispatch(AuthReducerAction.setIsAdmin(userRoleIsAdmin(data)));
    return data;
  }
);
const loginAuthDetail = createAsyncThunk(
  ActionType.GET_USER_Detail,
  async (_, thunkAPI) => {
    auth.onAuthStateChanged((user: any) => {
      thunkAPI.dispatch(setAuthDetail(getAuthDetail(user)));
      localStorage.removeItem('isTokenExp');
    })
  }
);

const signOut = createAsyncThunk(
  ActionType.SET_USER_SIGN_OUT,
  async () => {
    auth.signOut();
  }
);
export const AuthThunk = {
  setAuthDetail,
  loginAuthDetail,
  signOut
};