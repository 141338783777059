/* eslint-disable import/no-anonymous-default-export */
import { AxiosInstance } from 'axios';
import { API_PATH_ALTERNATE } from "../../../constants/api-constants";
import { auth } from "../../../firebase"
export default (api: AxiosInstance) => {
    const parseJwt = (token: any) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const getToken = async () => {
        let token: any = "";
        await auth.onAuthStateChanged((user) => {
            if (user) {
                const idToken = user.getIdToken(true).then((idToken) => {  // <------ Check this line
                    // It shows the Firebase token now
                    return idToken;
                });
                token = idToken
            }
        });
        return token
    }
    const getQueryRequest = async (token: any, data: any) => {
        let accessToken = token;
        const decodedJwt: any = parseJwt(token);
        const isExp = (decodedJwt.exp * 1000 < Date.now());
        if (isExp) {
            localStorage.setItem('isTokenExp', 'true');
            accessToken = await getToken();
        }
        const header = { headers: { "Authorization": `Bearer ${accessToken}`, "content-type": "application/json", } }
        const response = await api.post(API_PATH_ALTERNATE.GRAPHQL_PATH, data, header);
        return response;
    };
    return {
        getQueryRequest
    };
};
