import React from 'react'
import Navbar from '../navbar/navbar'
import { CContainer, CHeader } from '@coreui/react'
interface HeaderProps {
  onClickNav?: (e:any,b:any) => void;
}
function Header(props: HeaderProps) {
  return (
    <CHeader position="sticky" className=" mb-0">
      <CContainer fluid>
        <Navbar {...props} />
      </CContainer>
    </CHeader>
  )
}

export default Header
