import React, { Suspense,FC} from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; //use browserRouter for localhost, hashRouter for github pages
import LoginPage from "../pages/login/login";
import MainLayout from '../layouts/layout'; 
import ProtectedRoute from './protected-route';
import Loader from '../shared/components/loader';

// Pages
const AppRoutes:FC=()=>(
    <Router> 
        <Suspense fallback={<Loader/>}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<ProtectedRoute><MainLayout/></ProtectedRoute>} />  
          </Routes>
        </Suspense> 
    </Router>
) 
export default AppRoutes;