
import { createSlice } from '@reduxjs/toolkit';
import { NavBarModel } from '../../models/navbar/navbar.models';

export const initialState: NavBarModel = {
  sidebarUnfoldable: true,
  sidebarShow: false, 
};
export const NavBarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setSidebar: (state, action) => {
      state.sidebarShow=action.payload;
    },
    setSidebarUnfoldable: (state, action) => {
      state.sidebarUnfoldable=action.payload;
    }
  },
  extraReducers: () => {}
});
const { setSidebarUnfoldable,setSidebar } = NavBarSlice.actions;
export const NavBarReducerAction = {
  setSidebarUnfoldable,setSidebar
}
export default NavBarSlice.reducer;


