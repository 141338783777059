import { CNavItem } from "@coreui/react";
import { routePath, staticRoute } from "../../routes/main-route-list";
import { ROUTE_ROLE_PERMISSION } from "../../constants/app-permission";
import CIcon from "@coreui/icons-react";
import { cilHome } from "@coreui/icons";
import { useAppSelector } from "../../app/hooks";
import { AppModel } from "../../shared/models/app-model/app-model";
import CheckListIcon from "../../shared/components/icon/checklist-icon";
import MilkIcon from "../../shared/components/icon/milk-icon";
import CattleDirectoryIcon from "../../shared/components/icon/cattle-directory-icon";
import FeedIcon from "../../shared/components/icon/feed-icon";
import SellCattleIcon from "../../shared/components/icon/sell-cattle-icon";
import BookingIcon from "../../shared/components/icon/booking-icon";
import DealIconWhite from "../../shared/components/icon/deal-icon-white";
// import FeedTrakerIcon from '../../shared/components/icon/feed-traker-icon';
const {
  Home,
  CATTLE_DIRECTORY, //TEAM, ASSIGNED_BUFFALO, QUALITY_CHECK, CHECKLIST,
  MILKING_RECORD,
  FEED,
  SELL_CATTLE,
  CHECKLIST,
  BOOKING,
} = ROUTE_ROLE_PERMISSION; //ANIMAL_TRANSFER

export const navList = [
  // {
  //   component: CNavItem,
  //   name: "Home",
  //   icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  //   to: routePath.dashboard,
  //   role: Home,
  // },
  {
    component: CNavItem,
    name: "Cattle Directory",
    to: routePath.cattleDirectory,
    role: CATTLE_DIRECTORY,
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    // icon: <CattleDirectoryIcon className="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: "Team",
  //   to: routePath.team,
  //   role: TEAM,
  // },
  // {
  //   component: CNavItem,
  //   name: "Assign Buffalo",
  //   to: routePath.assignBuffalo,
  //   role: ASSIGNED_BUFFALO,
  // },
  // {
  //   component: CNavItem,
  //   name: "Payment",
  //   to: routePath.buffaloPayment,
  //   role: ASSIGNED_BUFFALO,
  // },
  // {
  //   component: CNavGroup,
  //   to: "/nav",
  //   name: "Quality Check",
  //   role: QUALITY_CHECK,
  //   icon: <CheckListIcon className='nav-icon' />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "Reviews",
  //       to: routePath.qualityReviews,
  //       role: QUALITY_CHECK,
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Assigned",
  //       to: routePath.qualityAssigned,
  //       role: QUALITY_CHECK,
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   to: staticRoute.dailyVetList,
  //   name: "Check Lists",
  //   role: CHECKLIST,
  //   icon: <CheckListIcon className='nav-icon' />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "Daily Vet Task",
  //       to: staticRoute.dailyVetList,
  //       role: CHECKLIST,
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Outgoing Checklist",
  //       to: staticRoute.outgoingCheckList,
  //       role: CHECKLIST,
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Incoming Checklist",
  //       to: staticRoute.incomingCheckList,
  //       role: CHECKLIST,
  //     },
  //   ],
  // },
  {
    component: CNavItem,
    name: "Outgoing Checklist",
    to: staticRoute.outgoingCheckList,
    role: CHECKLIST,
    icon: <CheckListIcon className="nav-icon" />,
  },
  {
    component: CNavItem,
    to: routePath.milkingRecordYeild,
    name: "Milking Record",
    role: MILKING_RECORD,
    icon: <MilkIcon className="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   to: "/feed-tracker",
  //   name: "Feed Tracker",
  //   role: MILKING_RECORD,
  //   icon:<FeedTrakerIcon  className='nav-icon'/>
  // },
  {
    component: CNavItem,
    to: "/feeds",
    name: "Feeds",
    role: FEED,
    icon: <FeedIcon className="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   to: routePath.AnimalTransfer,
  //   name: 'Animal Transfer',
  //   role: ANIMAL_TRANSFER,
  // }
  // {
  //   component: CNavItem,
  //   to: '/inventory-feed-management',
  //   name: 'Inventory/Feed Management',
  //   role: MILKING_RECORD,
  // }
  {
    component: CNavItem,
    to: "/sell-cattle",
    name: "Sell Cattle",
    role: SELL_CATTLE,
    icon: <SellCattleIcon className="nav-icon" />,
  },
  {
    component: CNavItem,
    to: "/booking",
    name: "Booking",
    role: BOOKING,
    icon: <BookingIcon className="nav-icon" />,
  },
  {
    component: CNavItem,
    to: "/deal",
    name: "Deal",
    role: BOOKING,
    icon: <DealIconWhite className="nav-icon" />,
  },
];
export const SidebarNavList = () => {
  const { authDetail } = useAppSelector((state: AppModel) => state.auth);
  const { roles } = authDetail.customAttributes || [];
  const getSidebarNavList = () => {
    const list = navList.filter((item: any) => {
      const inner = item.role.some((role: any) => {
        return roles.some((elm: any) => {
          if (elm === role) return item;
        });
      });
      // item.role = [];
      return inner;
    });
    return list;
  };
  return {
    getSidebarNavList,
    navList,
  };
};
