import { createSlice } from '@reduxjs/toolkit';
import extraReducers from './extra-reducer';
import { MilkReducerModel } from "../../models/animal/animal.models";
import { getSearchColumn } from "../../helper/search-input";
import { MILK_HISTORY_ROUTE } from "../../../constants/app-constants";
import { getLastDateMonthYear } from "../../helper/get-day-from-date";
const toDay = getLastDateMonthYear(new Date(), 0)
export const initialState: MilkReducerModel = {
  // milkYeildRecords: [],
  totalRecords: null,
  animalMilkRecords: [],
  filterYeildList: [],
  milkQualityRecords: [],
  filteredMilkQuality: [],
  isLoading: false,
  isFetching: false,
  message: "",
  isSuccess: false,
  isError: false,
  milkSearch: "",
  currentpage: 1,
  perPageRow: 200,
  milkFeature: "",
  inputValidate: [],
  selectedDate: new Date(toDay).toString()
};

export const MilkSlice = createSlice({
  name: "milk-records",
  initialState,
  reducers: {
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentpage = action.payload;
    },
    setAnimalMilkEntry: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.filterYeildList = action.payload;
    },
    setAnimalMilkQuality: (state, action) => {
      state.filteredMilkQuality = action.payload;
    },
    onMilkSearch: (state, action) => {
      const search = action.payload;
      const isMilkQuality = [MILK_HISTORY_ROUTE.QUALITY].includes(state.milkFeature)
      state.milkSearch = search;
      if (isMilkQuality) {
        const existingMilkRecords = JSON.parse(JSON.stringify(state.milkQualityRecords));
        const filterList: any = getSearchColumn(existingMilkRecords, search, 'tag');
        state.filteredMilkQuality = search ? [...filterList] : [...state.milkQualityRecords];
      // } else {
      //   const existingMilkRecords = JSON.parse(JSON.stringify(state.milkYeildRecords));
      //   const filterList: any = getSearchColumn(existingMilkRecords, search, 'tag');
      //   state.filterYeildList = search ? [...filterList] : [...state.milkYeildRecords];
      }
    },
    setInputValidate: (state, action) => {
      state.inputValidate = action.payload;
    },
    setMilkFeature: (state, action) => {
      state.milkFeature = action.payload;
      state.milkSearch = "";
    },
    clearMilkEntry: (state) => {
      state.milkSearch = "";
      // state.milkYeildRecords = [];
      state.filterYeildList = [];
      state.milkQualityRecords = [];
      state.filteredMilkQuality = [];
    }
  },
  extraReducers: (builder) => extraReducers(builder),
});
const { setSelectedDate, setAnimalMilkEntry, onMilkSearch, setAnimalMilkQuality, setCurrentPage, setInputValidate, setMilkFeature, clearMilkEntry } = MilkSlice.actions;
export const MilkReducerActions = {
  setSelectedDate,
  setAnimalMilkEntry,
  onMilkSearch,
  setAnimalMilkQuality,
  setCurrentPage,
  setInputValidate,
  setMilkFeature,
  clearMilkEntry
}
export default MilkSlice.reducer;