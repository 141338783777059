import { AnimalsReducerModel } from "../../models/animal/animal.models";
import { AnimalsThunk } from "./action";
const AnimalExtraReducers = (builder: any) => {
    builder
        .addCase(AnimalsThunk.fetchAsyncAnimalDetail.pending, (state: AnimalsReducerModel) => {
            state.isAnimalLoading = true;
        })
        .addCase(
            AnimalsThunk.fetchAsyncAnimalDetail.fulfilled,
            (state: AnimalsReducerModel, action: any) => {
                state.isAnimalLoading = false;
                state.isAnimalSuccess = true;
                state.animal = action.payload as any;
            }
        )
        .addCase(
            AnimalsThunk.fetchAsyncAnimalDetail.rejected,
            (state: AnimalsReducerModel, action: any) => {
                state.isAnimalLoading = false;
                state.isAnimalError = true;
                state.animalMessage = action.payload as string;
                state.animal = null;
            }
        )
        //animals list
        .addCase(AnimalsThunk.fetchAsyncAnimalsList.pending, (state: AnimalsReducerModel) => {
            state.farmAnimal.isLoading = true;
            state.farmAnimal.isSuccess = false;
            state.farmAnimal.isFetching = false;
        })
        .addCase(
            AnimalsThunk.fetchAsyncAnimalsList.fulfilled,
            (state: AnimalsReducerModel, action: any) => {
                const { animals = [], filteredAnimal = [], farmId = "" } = action.payload as any;
                state.farmAnimal.isLoading = false;
                state.farmAnimal.isSuccess = true;
                state.farmAnimal.animals = animals;
                state.farmAnimal.filteredAnimal = filteredAnimal;
                state.farmAnimal.farmId = farmId;
            }
        )
        .addCase(AnimalsThunk.fetchAsyncAnimalsList.rejected, (state: AnimalsReducerModel, action: any) => {
            state.farmAnimal.isLoading = false;
            state.farmAnimal.isSuccess = false;
            state.farmAnimal.isFirstCallAnimal = false;
            state.farmAnimal.isError = true;
            state.farmAnimal.message = action.payload as string;
            state.farmAnimal.filteredAnimal = [];
            state.farmAnimal.animals = [];
        })

        // Signed Url Image
        .addCase(AnimalsThunk.updateSignedUrlImage.pending, (state: AnimalsReducerModel) => {
            state.isSignedUrlImageLoading = true;
        })
        .addCase(AnimalsThunk.updateSignedUrlImage.fulfilled, (state: AnimalsReducerModel, action: any) => {
            state.isSignedUrlImageLoading = false;
            state.isSignedUrlImageSuccess = true;
            state.signedUrlImage = action.payload as string;
        })
        .addCase(AnimalsThunk.updateSignedUrlImage.rejected, (state: AnimalsReducerModel, action: any) => {
            state.isSignedUrlImageLoading = false;
            state.isSignedUrlImageError = true;
            state.signedUrlMessage = action.payload as string;
            state.signedUrlImage = "";
        })
        //status update
        .addCase(AnimalsThunk.updateAsyncAnimalStatus.pending, (state: AnimalsReducerModel) => {
            state.farmAnimal.isFetching = true;
        })
        .addCase(AnimalsThunk.updateAsyncAnimalStatus.fulfilled, (state: AnimalsReducerModel, action: any) => {
            const { animals = [], errorMessage = "", filteredAnimal = [] } = action.payload as any || {};
            state.farmAnimal.isFetching = false;
            if (!errorMessage) {
                state.farmAnimal.filteredAnimal = filteredAnimal;
                state.farmAnimal.animals = animals;
                state.farmAnimal.message = errorMessage;
            } else {
                state.farmAnimal.message = errorMessage;
            }
        })
        .addCase(AnimalsThunk.updateAsyncAnimalStatus.rejected, (state: AnimalsReducerModel, action: any) => {
            state.farmAnimal.isFetching = false;
        })
        .addCase(AnimalsThunk.updateAnimalTag.pending, (state: AnimalsReducerModel) => {
            state.isFetching = true;
        })
        .addCase(AnimalsThunk.updateAnimalTag.fulfilled, (state: AnimalsReducerModel, action: any) => {
            const { tag = "", } = action.payload as any || {};
            state.isFetching = false;
            if (state.animal && tag)
                state.animal.tag = tag;
        })
        .addCase(AnimalsThunk.updateAnimalTag.rejected, (state: AnimalsReducerModel, action: any) => {
            state.isFetching = false;
        })
        .addCase(AnimalsThunk.updateMediaTag.pending, (state: AnimalsReducerModel) => {
            state.isFetching = true;
        })
        .addCase(AnimalsThunk.updateMediaTag.fulfilled, (state: AnimalsReducerModel, action: any) => {
            const { mediaTags = "",mediaId="" } = action.payload as any || {};
            // console.log("state.animal",state.animal, mediaTags, mediaId)
            state.isFetching = false;
        })
        .addCase(AnimalsThunk.updateMediaTag.rejected, (state: AnimalsReducerModel, action: any) => {
            state.isFetching = false;
        })
}
export default AnimalExtraReducers;