import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionType } from './action-types';
import { AppModel } from '../../models/app-model/app-model';
import { animalsService } from '../../services/api';
import { AnimalsQuery } from '../../query/animal-query';
import { getYearMonthDate } from '../../helper/get-day-from-date';
import { MilkingService } from '../../helper/milking-service';
import { MilkYeildQuality } from '../../query/milk-record-query';
import { AuthReducerAction } from '../auth/reducer';
import { TOAST_MESSAGE_PAGE } from '../../../constants/app-constants';
import { getSetToastMessage } from '../../helper/toast-message';

const fetchAsyncMilkRecord = createAsyncThunk(
  ActionType.GET_SET_MILK_ENTRY,
  async ({ startDate, endDate }: any, { getState }) => {
    const { auth, farm ,milkHistory} = getState() as AppModel;
    const { selectedFarm } = farm;
    const { id = "" } = selectedFarm;
    const dateParam = { startDate: getYearMonthDate(startDate), endDate: getYearMonthDate(endDate) }
    try {
      if (selectedFarm && selectedFarm.id) {
        let milkRecordData; 
        const queryParam = { ...dateParam, farmId: id, lactating: true };
        const response = await animalsService.getQueryRequest(auth.token, MilkYeildQuality.getMilkYeildQuery(queryParam));
        milkRecordData = response.data.data.getMilkYeildByFarmId || {};
        
        const animalsMilkRecords: any = MilkingService.getMapMilkRecord(milkRecordData.milkRecords) || [];
        return { milkYeildRecords: animalsMilkRecords, totalRecords: milkRecordData.totalRecords };
      }else{
        return { milkYeildRecords: milkHistory.filterYeildList, totalRecords: milkHistory.filterYeildList.length };
      }
    } catch (error) {
      return { milkYeildRecords: [], totalRecords: 0 };
    }
  });

const fetchAsyncMilkRecordByAnimalId = createAsyncThunk(
  ActionType.GET_SET_ANIMAL_MILK_ENTRY,
  async ({ animalId, startDate, endDate }: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(
        auth.token,
        AnimalsQuery.getMilkingRecordByAnimalIdQuery(
          animalId,
          getYearMonthDate(startDate),
          getYearMonthDate(endDate)
        )
      );
      const milkRecords = response.data.data.milkYieldRecordByAnimalId || [];
      return milkRecords;
    } catch (error) {
      return []
    }
  }
);


const createAsyncMilkRecord = createAsyncThunk(
  ActionType.SET_CREATE_MILK_ENTRY,
  async (obj: any, { getState, dispatch }) => {
    const { auth, milkHistory } = getState() as AppModel;
    const { createRecords, isUpdate } = obj || {};
    try {
      const response = await animalsService.getQueryRequest(auth.token, MilkYeildQuality.createMilkRecordQuery(createRecords));
      const createResponse = response?.data?.data?.createMultipleMilkYieldRecords || [];
      if (createResponse.length && !isUpdate) {
        const toastData = getSetToastMessage(TOAST_MESSAGE_PAGE.MILK_YEILD, TOAST_MESSAGE_PAGE.CREATE);
        dispatch(AuthReducerAction.setToastMessage(toastData))
      }
      const milkRecords: any = MilkingService.getCreateUpdateMilkRecord(createResponse, milkHistory.filterYeildList);
      return milkRecords;
    } catch (error) {
      return milkHistory.filterYeildList
    }
  }
);
const updateAsyncMilkRecord = createAsyncThunk(
  ActionType.SET_UPDATE_MILK_ENTRY,
  async (obj: any, { getState, dispatch }) => {
    const { auth, milkHistory } = getState() as AppModel;
    const { updateRecords, isCreate } = obj || {};
    try {
      const response = await animalsService.getQueryRequest(auth.token, MilkYeildQuality.updateMilkRecordQuery(updateRecords));
      const updateResponse = response.data.data.updateMultipleMilkYieldRecords || [];
      if (updateResponse.length) {
        let toastData;
        if (isCreate) {
          toastData = getSetToastMessage(TOAST_MESSAGE_PAGE.MILK_YEILD, TOAST_MESSAGE_PAGE.CREATE_UPDATE);
        } else {
          toastData = getSetToastMessage(TOAST_MESSAGE_PAGE.MILK_YEILD, TOAST_MESSAGE_PAGE.UPDATE);
        }
        dispatch(AuthReducerAction.setToastMessage(toastData))
      }
      const milkRecords: any = MilkingService.getCreateUpdateMilkRecord(updateResponse, milkHistory.filterYeildList);
      return milkRecords || []
    } catch (error) {
      return milkHistory.filterYeildList
    }
  });

const fetchAsyncMilkQuality = createAsyncThunk(
  ActionType.GET_SET_MILK_QUALITY,
  async ({ startDate, endDate, farmId = null }: any, { getState }) => {
    const { auth, farm } = getState() as AppModel; //milkHistory, 
    const { selectedFarm } = farm;
    const { id = "" } = selectedFarm;
    const dateParam = { startDate: getYearMonthDate(startDate), endDate: getYearMonthDate(endDate) }
    try {
      let milkRecords; 
      const queryParam = { ...dateParam, farmId: id, lactating: true };
      const response = await animalsService.getQueryRequest(auth.token, MilkYeildQuality.getMilkQualityQuery(queryParam));
      milkRecords = response.data.data.getMilkQualityByFarmId || [];
      const milkQualityRecords: any = MilkingService.getMilkQualityRecord(milkRecords) || [];
      return milkQualityRecords;
    } catch (error) {
      return []
    }
  });

const createAsyncMilkQuality = createAsyncThunk(
  ActionType.SET_CREATE_MILK_QUALITY,
  async (obj: any, { getState, dispatch }) => {
    const { auth, milkHistory } = getState() as AppModel;
    const { createRecords, isUpdate } = obj || {};
    try {
      const response = await animalsService.getQueryRequest(auth.token, MilkYeildQuality.createMilkQualityQuery(createRecords));
      const milkRecords = response.data.data.createMilkQualityRecord || [];
      if (milkRecords.length && !isUpdate) {
        const toastData = getSetToastMessage(TOAST_MESSAGE_PAGE.MILK_QUALITY, TOAST_MESSAGE_PAGE.CREATE);
        dispatch(AuthReducerAction.setToastMessage(toastData))
      }
      const milkQualityRecords: any = MilkingService.getMilkQualityRecord(milkHistory.milkQualityRecords, milkRecords);
      return milkQualityRecords;
    } catch (error) {
      return milkHistory.milkQualityRecords
    }
  }
);
const updateAsyncMilkQuality = createAsyncThunk(
  ActionType.SET_UPDATE_MILK_QUALITY,
  async (obj: any, { getState, dispatch }) => {
    const { auth, milkHistory } = getState() as AppModel;
    const { updateRecords, isCreate } = obj || {};
    try {
      const response = await animalsService.getQueryRequest(auth.token, MilkYeildQuality.updateMilkQualityQuery(updateRecords));
      const milkRecords = response.data.data.updateMilkQualityRecord || [];
      if (milkRecords.length) {
        let toastData;
        if (isCreate) {
          toastData = getSetToastMessage(TOAST_MESSAGE_PAGE.MILK_QUALITY, TOAST_MESSAGE_PAGE.CREATE_UPDATE);
        } else {
          toastData = getSetToastMessage(TOAST_MESSAGE_PAGE.MILK_QUALITY, TOAST_MESSAGE_PAGE.UPDATE);
        }
        dispatch(AuthReducerAction.setToastMessage(toastData))
      }
      const milkQualityRecords: any = MilkingService.getMilkQualityRecord(milkHistory.milkQualityRecords, milkRecords);
      return milkQualityRecords;
    } catch (error) {
      return milkHistory.milkQualityRecords
    }
  });

export const AnimalMilkThunk = {
  fetchAsyncMilkRecord,
  fetchAsyncMilkRecordByAnimalId,
  createAsyncMilkRecord,
  updateAsyncMilkRecord,
  fetchAsyncMilkQuality,
  createAsyncMilkQuality,
  updateAsyncMilkQuality
}

