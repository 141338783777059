import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppModel } from "../../models/app-model/app-model";
import { UserModel } from "../../models/user/user.models";
import { UserQuery } from "../../query/user-query";
import { animalsService } from "../../services/api";
import { ActionType } from "./action-types";


const getUserByRole = createAsyncThunk(
  ActionType.GET_USER,
  async (queryInput: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const { data } = await animalsService.getQueryRequest(auth.token, UserQuery.getUserByRole(queryInput));
      const userList = data.data.getEmployees || [];
      const returnList = userList.filter((item: UserModel) => {
        item.label = item.name;
        item.value = item.userId;
        return item;
      })
      return returnList;
    } catch (error) {
      return []
    }
  });

const getCustomerByPhoneNo = createAsyncThunk(
  ActionType.GET_CUSTOMER,
  async (queryInput: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const { data } = await animalsService.getQueryRequest(auth.token, UserQuery.getCustomerByPhoneNumber(queryInput));
      return data.data && data.data.customer && data.data.customer || {};
    } catch (error) {
      return []
    }
  });
export const UserThunk = {
  getUserByRole,
  getCustomerByPhoneNo
};