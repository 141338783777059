import { createAsyncThunk } from "@reduxjs/toolkit";
import { animalsService } from "../../services/api";
import { ActionType } from "./action-types";
import { AppModel } from "../../models/app-model/app-model";
import { FeedsQuery } from "../../query/feed-query";
import {
  CreateFeedParams,
  DeleteMediaParams,
  SignedUrlParam,
  UpdateMediaParams,
  FetchFeedsParam,
  UpdateFeedParams
} from "../../models/feeds/feeds.models";
import axios from "axios";

const fetchAsyncFeeds = createAsyncThunk(
  ActionType.GET_FEEDS,
  async (_, { getState }) => {
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(
      auth.token,
      FeedsQuery.getFeeds()
    );
    const feedsRecords = response.data.data.feeds || [];
    return feedsRecords;
  }
);

const fetchAsyncFeed = createAsyncThunk(
  ActionType.GET_FEED,
  async (fetchFeedsParam: FetchFeedsParam, { getState }) => {
    const { auth } = getState() as AppModel;

    const response = await animalsService.getQueryRequest(
      auth.token,
      FeedsQuery.getFeeds(fetchFeedsParam.id)
    );
    console.log("feed Fetched", fetchFeedsParam, response.data.data.feeds);
    console.log(response.data.data.feeds);
    const feedRecord = response.data.data.feeds[0];
    if(feedRecord.length===0){
      throw new Error("Feed Don't Exist")
    }
    return feedRecord;
  }
);

const _createLocalFeed = ({
  id,
  name,
  sku,
  recommendedFor,
  fat,
  protein,
  benefitsDescription,
  dosageDescription,
  usageDescription,
  compositionDescription
}:any) => {
  return{
    id: id,
    name: name,
    sku: sku,
    recommendedFor: recommendedFor,
    specification:{
      protein: protein,
      fat: fat
    },
    benefits:{
      description: benefitsDescription,
      mediaCollection:[]
    },
    dosage:{
      description: dosageDescription,
      mediaCollection:[]
    },
    usage:{
      description:usageDescription,
      mediaCollection:[]
    },
    composition:{
      description:compositionDescription,
      mediaCollection:[]
    }
  }
};

const createFeed = createAsyncThunk(
  ActionType.CREATE_FEED,
  async (createFeed: CreateFeedParams, { getState }) => {
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(
      auth.token,
      FeedsQuery.createFeedQuery(createFeed)
    );
    console.log(response.data.data.createFeed.id);

    const feed = _createLocalFeed({
      ...createFeed,
      id: response.data.data.createFeed.id
    })
    return {
      createdFeed: feed,
      success: true
    };
  }
);

const updateFeed = createAsyncThunk(
  ActionType.UPDATE_FEED,
  async (updateFeed: UpdateFeedParams, { getState }) => {
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(
      auth.token,
      FeedsQuery.updateFeedQuery(updateFeed)
    );
    const feed = _createLocalFeed(updateFeed);
    console.log("Feed Updated:",feed)
    return {
      success: response.data.data.updateFeed.success,
      updatedFeed: feed
    };
  }
);

const createFeedMedia = createAsyncThunk(
  ActionType.SET_SIGNED_URL,
  async (signedUrlParam: SignedUrlParam, { getState }) => {
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(
      auth.token,
      FeedsQuery.signedUrlQuery(signedUrlParam)
    );

    const signedUrl = response.data.data.getFeedsSignedUrl.signedUrl;
    // const signedUrl = "https://limitless-mountain-49979.herokuapp.com/" + response.data.data.getFeedsSignedUrl.signedUrl;
    const headers = {
      "content-type": signedUrlParam.mimeType,
      Authorization: `Bearer ${auth.token}`,
    };
    const uploadImageResponse = await axios.put(
      signedUrl,
      signedUrlParam.newMedia,
      {
        headers: headers,
      }
    );
    console.log(signedUrl, uploadImageResponse);
    return uploadImageResponse.data;
  }
);

const deleteFeedMedia = createAsyncThunk(
  ActionType.DELETE_FEED_MEDIA,
  async (deleteMediaParams: DeleteMediaParams, { getState }) => {
    const { auth } = getState() as AppModel;
    console.log(deleteMediaParams);
    const response = await animalsService.getQueryRequest(
      auth.token,
      FeedsQuery.deleteMedia(deleteMediaParams)
    );
    console.log(response.data.data.deleteMedia);
    return response.data.data.deleteMedia;
  }
);

const updateFeedMedia = createAsyncThunk(
  ActionType.UPDATE_FEED_MEDIA,
  async (updateMediaParams: UpdateMediaParams, { getState }) => {
    const { auth } = getState() as AppModel;

    const signedUrlParam: SignedUrlParam = {
      feedId: updateMediaParams.feedId,
      mediaOf: updateMediaParams.mediaOf,
      mimeType: updateMediaParams.mimeType,
      newMedia: updateMediaParams.newMedia,
      action: updateMediaParams.action,
    };
    const signedUrlResponse = await animalsService.getQueryRequest(
      auth.token,
      FeedsQuery.signedUrlQuery(signedUrlParam)
    );
    const signedUrl = signedUrlResponse.data.data.getFeedsSignedUrl.signedUrl;
    const headers = {
      "content-type": signedUrlParam.mimeType,
      Authorization: `Bearer ${auth.token}`,
    };
    try {
      const uploadImageResponse = await axios.put(
        signedUrl,
        signedUrlParam.newMedia,
        {
          headers: headers,
        }
      );
      console.log(signedUrl, uploadImageResponse);
    } catch (error) {
      console.log("kjbhmvjhgvjhvfjh")
    }
    
    // return uploadImageResponse.data;

    const deleteMediaParams: DeleteMediaParams = {
      feedId: updateMediaParams.feedId,
      mediaOf: updateMediaParams.mediaOf,
      path: updateMediaParams.path,
    };
    const response = await animalsService.getQueryRequest(
      auth.token,
      FeedsQuery.deleteMedia(deleteMediaParams)
    );
    console.log(response.data.data.deleteMedia);
    return response.data.data.deleteMedia;
  }
);



export const AnimalFeedsThunk = {
  fetchAsyncFeeds,
  fetchAsyncFeed,
  createFeed,
  updateFeed,
  createFeedMedia,
  deleteFeedMedia,
  updateFeedMedia,
};
