export const ActionType = {
  SET_ANIMALS_LIST: 'animals/set_animals_list',
  SET_ANIMALS_LISTING: 'animals/set_animals_listing',
  IS_LOADING: 'animals/is_loading',
  SET_ERROR: 'animals/set_error',
  SET_INCOMING_CATTLE_LIST: 'animals/set_incoming_cattle_list',
  SET_OUTGOING_CATTLE_LIST: 'animals/set_outgoing_cattle_list',
  SET_SIGNED_URL: 'animals/set_signed_url',
  SET_ANIMAL_DETAIL: 'animal/detail',
  SET_FARM_ANIMAL_STATUS: 'animals/set_farm_animal_status',
  SET_ANIMAL_WITH_MILK_AVERAGE: 'animals/set_animal_with_milk_average',
  SET_CHANGE_ANIMAL_TAG: "animal/tag",
  SET_CHANGE_MEDIA_TAG: "animal/Media/tag",
  SET_CHANGE_ANIMAL_MEDIA: "animal/Media/",
  GET_BAZAR_ANIMAL_MEDIA: "bazar/animal/Media/"
}