import React, { Suspense, memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { permission } from '../constants/app-permission';
import { AppModel } from '../shared/models/app-model/app-model';
// routes config
import MainRouteList from './main-route-list';

const MainRoutes = () => {
  const { token, authDetail } = useAppSelector((state: AppModel) => state.auth);
  const { customAttributes } = authDetail || {}; 
  const { roles = [] } = customAttributes || {}
  return (
    <Suspense fallback={""}>
      {token && <Routes>
        {MainRouteList.map((route: any, idx: any) => {
          const routePermission = permission.routeRolesPermission(roles, route.roles);
          return (
            (routePermission && route.element) ?
              (<Route
                key={idx}
                path={route.path}
                element={<route.element />}
              />
              ) : <Route
                key={idx}
                path={route.path}
                element={<Navigate replace to="/" />}
              />
          )
        })}
      </Routes>}
    </Suspense>
  )
}

export default memo(MainRoutes)
