import "./login.scss";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EmailAuthProvider, GoogleAuthProvider } from "@firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { auth } from "../../firebase";
import { useAppSelector } from "../../app/hooks";
import { AppModel } from "../../shared/models/app-model/app-model";
function LoginPage() {
  const navigate = useNavigate();
  const { token, isLoading } = useAppSelector((state: AppModel) => state.auth);
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/",
    signInOptions: [
      EmailAuthProvider.PROVIDER_ID,
      GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => true,
    },
  };
  useEffect(() => {
    if (!isLoading && token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, token]);

  return (
    <StyledFirebaseAuth
      uiCallback={(ui) => ui.disableAutoSignIn()}
      uiConfig={uiConfig}
      firebaseAuth={auth}
    />
  );
}

export default LoginPage;
