import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppModel } from '../../models/app-model/app-model';
import { ActionType } from './action-types';
import { animalsService } from '../../services/api';
import { getPayloadString } from '../../helper/map-service';
import { DealQuery } from '../../query/deal-query';
import { getSetToastMessage } from '../../helper/toast-message';
import { AuthReducerAction } from '../auth/reducer';
import { TOAST_MESSAGE_PAGE } from '../../../constants/app-constants';

const fetchDeals = createAsyncThunk(
  ActionType.GET_DEAL_LIST,
  async (queryInput: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(auth.token, DealQuery.getDealQuery(queryInput));
      return response.data.data.deals || [];
    } catch (error) {
      return []
    }
  }
);
const updateDealStatus = createAsyncThunk(
  ActionType.UPDATE_DEAL_STATUS,
  async (obj: any, { getState, dispatch }) => {
    const { queryInput, editDeal } = obj;
    const { auth } = getState() as AppModel;
    try {
      const payloadString = getPayloadString(editDeal);
      const { data } = await animalsService.getQueryRequest(auth.token, DealQuery.updateDealQuery(payloadString));
      // const response = await animalsService.getQueryRequest(auth.token, DealQuery.getDealQuery(queryInput));
      const response = data?.data?.updateDeal || null;
      const toastData = getSetToastMessage(`${TOAST_MESSAGE_PAGE.DEAL_STATUS_UPDATE}`, TOAST_MESSAGE_PAGE.UPDATE);
      dispatch(AuthReducerAction.setToastMessage(toastData));
      return response;
    } catch (error) {
      return []
    }
  }
);

const createDeal = createAsyncThunk(
  ActionType.CREATE_DEAL,
  async (obj: any, { getState }) => {
    const { createDeal } = obj;
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(auth.token, DealQuery.createDealQuery(createDeal));
    // const response = await animalsService.getQueryRequest(auth.token, AppointmentQuery.appointmentsByDateQuery(queryInput));
    return response.data.data.createDeal;
  }
);

export const DealThunk = {
  fetchDeals,
  updateDealStatus,
  createDeal
}