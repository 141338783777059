import React  from "react"; 
import { CModal,CModalHeader,CModalTitle,CModalBody,CModalFooter} from "@coreui/react"; 
import "./modal.scss"
interface ModalProps{
    isHeader?:boolean;
    isFooter?:boolean;
    isVisible?:boolean;
    header?:any;
    footer?:any;
    children?:any;
    onClose?:()=>void;
    customClass?:string; 
    size?: 'sm' | 'lg' | 'xl';
    closeButton?:boolean;
    unmountOnClose?:boolean;
    backdrop?:boolean | 'static';
}
const Modal=(props:ModalProps)=>{
    const {isHeader=false,isFooter=false,isVisible,onClose=()=>{},header="",footer="",children,size="xl",customClass="",closeButton=true,
    unmountOnClose=true,backdrop='static'}=props;
   
   return( 
        <CModal alignment="center" className={customClass} visible={isVisible} onClose={() => onClose()} size={size} 
           unmountOnClose={unmountOnClose} backdrop={backdrop}>
            {isHeader && <CModalHeader closeButton={closeButton}>
                <CModalTitle> {header}</CModalTitle>
            </CModalHeader>}
            <CModalBody>
               {children}
            </CModalBody>
            {isFooter && <CModalFooter> 
                {footer}
            </CModalFooter>}
    </CModal> 

   )
}
export default Modal; 