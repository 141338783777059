import React from "react"; 
import "./styles.scss"; 
export interface Heading{
    text:string; 
    customClass?:string;
    subtext?:string;
}

const H1: React.FC<Heading> = (props: Heading) => {
    const { text,customClass="",subtext="" } = props;
    let heading = (<h1 className={`${customClass?customClass:""} headingh1`}>{text}{subtext && <span>{subtext}</span>}</h1>);
    return heading;
};
 

export default H1;
