import React, { memo } from "react";
import CheckList from "../../images/milk-icon.svg";
interface CheckListIconProps {
  alt?: string;
  className?: string;
}
const MilkIcon = (props: CheckListIconProps) => {
  const { alt = "", className = "" } = props;
  return <img src={CheckList} alt={alt} className={className} />;
};
export default memo(MilkIcon);
