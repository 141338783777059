const { REACT_APP_ROLE_HOME,
    // REACT_APP_ROLE_TEAM, 
    REACT_APP_ROLE_CATTLE_DIRECTORY,
    // REACT_APP_ROLE_ASSIGNED_BUFFALO,
    // REACT_APP_ROLE_QUALITY_CHECK, 
    REACT_APP_ROLE_CHECKLIST,
    REACT_APP_ROLE_MILKING_RECORD,
    REACT_APP_ROLE_INCOMING_CATTLE, REACT_APP_ROLE_OUTGOING_CATTLE,
    REACT_APP_ROLE_APPOINTMENT, REACT_APP_ROLE_FEED, REACT_APP_ROLE_SELL_CATTLE, REACT_APP_ROLE_BOOKING, REACT_APP_ROLE_DEAL } = process.env;//REACT_APP_ROLE_ASSIGNED_CATTLE_TO_FARM
export const ROUTE_ROLE_PERMISSION = {
    Home: REACT_APP_ROLE_HOME || ["Admin", "HubManager", "Vet", "DataAdmin", "QChead", "ProcurementManager", "ProcurementHead", "CS", "DevTeam", "Farmer"], //"Dev", "FieldOfficer", "DevTeam", "Farmer"
    CATTLE_DIRECTORY: REACT_APP_ROLE_CATTLE_DIRECTORY || ["Admin", "Vet", "QChead", "HubManager", "DataAdmin", "ProcurementManager", "CS", "DevTeam"], //"FieldOfficer", "DevTeam",
    MILKING_RECORD: REACT_APP_ROLE_MILKING_RECORD || ["Admin", "Vet", "HubManager", "DataAdmin", "DevTeam", "CS"],
    INCOMING_CATTLE: REACT_APP_ROLE_INCOMING_CATTLE || ["Admin", "Vet", "HubManager", "DataAdmin", "ProcurementHead", "QChead", "DevTeam"],
    OUTGOING_CATTLE: REACT_APP_ROLE_OUTGOING_CATTLE || ["Vet", "HubManager", "DataAdmin", "ProcurementHead", "Admin", "DevTeam"],
    APPOINTMENT: REACT_APP_ROLE_APPOINTMENT || ["HubManager", "DataAdmin", "ProcurementHead", "Vet", "Admin", "CS"],
    // ANIMAL_TRANSFER: REACT_APP_ROLE_ASSIGNED_CATTLE_TO_FARM || ["Admin", "DataAdmin", "ProcurementManager"],
    // QUALITY_CHECK: REACT_APP_ROLE_QUALITY_CHECK || ["QChead", "ProcurementManager", "DataAdmin"],
    CHECKLIST: REACT_APP_ROLE_CHECKLIST || ["Admin", "Vet", "HubManager", "DataAdmin", "DevTeam"], //Vet , HubManager,  DataAdmin
    FEED: REACT_APP_ROLE_FEED || ["Admin", "DataAdmin"],
    SELL_CATTLE: REACT_APP_ROLE_SELL_CATTLE || ["Admin", "DataAdmin", "ProcurementManager", "CS", "DevTeam"],
    // ASSIGNED_BUFFALO: REACT_APP_ROLE_ASSIGNED_BUFFALO || ["Admin", "ProcurementManager"],
    // TEAM: REACT_APP_ROLE_TEAM || ["Admin", "ProcurementManager"],
    BOOKING: REACT_APP_ROLE_BOOKING || ["HubManager", "DataAdmin", "Vet", "Admin", "CS"],
    DEAL: REACT_APP_ROLE_DEAL || ["HubManager", "DataAdmin", "Vet", "Admin", "CS"],

}

const routeRolesPermission = (roles: any, route: any) => {
    const list = route.some((role: any) => {
        return roles.some((elm: any) => {
            if (elm === role) return role
        })
    });
    return list;
}
export const permission = {
    routeRolesPermission
}