import React, { Fragment, useRef } from "react";
import "./style.scss"; 
import { CButton ,CSpinner} from '@coreui/react'
import CIcon from '@coreui/icons-react'
export interface ButtonProps{ 
    onClick?: ()=>void,
    id?: any, 
    dataTitle?: string,
    text?: string, 
    disabled?: boolean, 
    customClass?: string,
    isLoading?: boolean, 
    hideLoader?: boolean,
    isThreeDotSpinner?: boolean,
    active?: boolean,
    color?:string,
    icon?:any,
    iconClass?:string,
    type?:string;
    height?:number;
    outerText?:string;
    children?:any;
}
const Button = (props:any) => {
    const {
        onClick=()=>{},
        id = "", 
        dataTitle = "",
        text = "",
        transparent = false,
        disabled = false, 
        customClass = "",
        isLoading = false,
        hideLoader = false,
        active=false, 
        isThreeDotSpinner = false,
        color="",
        icon='',
        iconClass="",
        type="button",
        height=30,
        outerText="",
        children,
        ...restProps
    } = props;

    const ref = useRef();

    const transparentStyle = transparent ? "transparent-btn" : "";
    const disabledStyle = disabled ? "disable-btn" : "";
    const styleName = `${active? "active":""} ${disabledStyle} ${transparentStyle}`;

    return (
        <Fragment>
            <CButton ref={ref} id={id} data-title={dataTitle}
                disabled={disabled || isLoading} onClick={()=>onClick(id,text)} color={color}
                type={type} className={`default-btn ${customClass} ${styleName}`}
                {...restProps}
            >  
            {icon && <span><CIcon className={iconClass} icon={icon} height={height} /></span>}
               {  
                    isLoading && !hideLoader && !isThreeDotSpinner &&
                    <CSpinner color="primary"/>

                } 
                {text}
                {children && children}
            </CButton>
            {outerText &&<div className="outer-text">{outerText}</div>}
        </Fragment>
    );
};

export default Button;
