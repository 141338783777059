import React from 'react'
import { CSidebar, CSidebarNav, CSidebarToggler } from '@coreui/react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { SidebarNav } from "../sidebar-nav/sidebar-nav";
import "./sidebar.scss"
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AppModel } from '../../shared/models/app-model/app-model';
import { SidebarNavList } from './sidebar-nav-list';
import { NavBarReducerAction } from '../../shared/async-thunk/sidebar/reducer';

interface AppSidebarProps {
  onClickNav?: (e: any, b: any) => void;
}
const AppSidebar = (props: AppSidebarProps) => {
  const { onClickNav = () => { } } = props;
  const { token, sidebarUnfoldable, sidebarShow } = useAppSelector((state: AppModel) => ({
    token: state.auth.token,
    sidebarUnfoldable: state.navbar.sidebarUnfoldable,
    sidebarShow: state.navbar.sidebarShow,
  }));
  const dispatch = useAppDispatch();
  const { getSidebarNavList } = SidebarNavList();
  const navList = getSidebarNavList();

  return (
    <CSidebar
      position="fixed"
      className="sidebar-wrap"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(NavBarReducerAction.setSidebar(visible))
      }}
    >
      {/* <CSidebarToggler
        className="sidebar-toggle d-lg-flex"
        onClick={() => dispatch(NavBarReducerAction.setSidebarUnfoldable(!sidebarUnfoldable))}
      /> */}
      <CSidebarNav>
        {token && <SimpleBar>
          <SidebarNav items={navList} onClickNav={onClickNav} />
        </SimpleBar>}
      </CSidebarNav>
    </CSidebar>
  )
}

export default React.memo(AppSidebar);
