
const getPayloadString = (obj: object) => {
  let str = '';
  for (let [p, val] of Object.entries(obj)) {
    if (typeof (val) === "string") val = `"${val}"`
    str += `${p}:${val},\n`;
  }
  return `{${str}}`;
}
const getAuthDetail = (user: any) => {
  const { stsTokenManager, reloadUserInfo } = user || {};
  const { expirationTime } = stsTokenManager || {};
  const { customAttributes } = reloadUserInfo || {}
  const getVal = (key: any) => user && user[key] ? user[key] : "";
  const userDetail = {
    customAttributes: customAttributes ? JSON.parse(customAttributes) : { roles: [] },
    // ...getVal('reloadUserInfo'),
    accessToken: getVal('accessToken'),
    displayName: getVal('displayName'), email: getVal('email'),
    phoneNumber: getVal('phoneNumber'), photoURL: getVal('photoURL'),
    expirationTime: expirationTime ? expirationTime : 0,
    userId: getVal('uid'),
  }
  return userDetail
}
const getIsUserRole = (roles: Array<any>, value: string) => (roles.includes(value))
const userRoleIsAdmin = (user: any) => {
  const { customAttributes } = user || {};
  const { roles = [] } = customAttributes || {};
  const isDataAdmin = getIsUserRole(roles, "DataAdmin") || false;
  const isAdmin = getIsUserRole(roles, "Admin") || false;
  const isHubManager = getIsUserRole(roles, "HubManager") || false;
  const isVet = getIsUserRole(roles, "Vet") || false;
  const isProcurementHead = getIsUserRole(roles, "ProcurementHead") || false;
  const isQCHead = getIsUserRole(roles, "QCHead") || false;
  const isFieldOfficer = getIsUserRole(roles, "FieldOfficer") || false;
  const isProcurementManager = getIsUserRole(roles, "ProcurementManager") || false;
  const isCST = getIsUserRole(roles, "CS") || false;
  return { isDataAdmin, isAdmin, isHubManager, isVet, isProcurementHead, isQCHead, isFieldOfficer, isProcurementManager ,isCST}
}

const getColumnTime = (time: string) => {
  const reg = /Offered|Remaining/gi;
  const value = time.replace(reg, "").replace(/\s/g, "");
  return value.toLowerCase();
} 
export {
  getPayloadString,
  getAuthDetail,
  userRoleIsAdmin,
  getColumnTime, 
}