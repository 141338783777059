import React, { Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { AppModel } from "../shared/models/app-model/app-model"; 
const ProtectedRoute = ({ children }: any) => {
  const { token, isLoading } = useAppSelector((state: AppModel) => state.auth);
  const location = useLocation();
  if (!token && !isLoading) {
    return <Navigate to="/login" replace={true} state={{ from: location }} />
  }
  if (token) {
    return children;
  } else {
    return <Fragment></Fragment>
  }
};

export default ProtectedRoute;
