const dealQuery = `
id
dealNo
validUntil
status
notes
createdAt
updatedAt
animal{
    id
    tag
    farm{
        id
        code
        name
    }
    pricingDetails{
      discountedPrice{
          amount
          currency
      }
    }
}
dealPrice{
    amount
    currency
}
sellingPrice{
    amount
    currency
}
customer{
    name
    phoneNo
    userId
}
createdBy{
    name
    userId
}
updatedBy{
    name
    userId
}
offeredAfter`
const getDealQuery = (fetchDealsInput: any) => {
  const { take = 100, skip = 0, farmIds = "", animalId = "", status = "", } = fetchDealsInput;
  return {
    "query": `query ($fetchDealsInput:FetchDealsInput!) {
    deals (fetchDealsInput:$fetchDealsInput) {
      ${dealQuery}
    }
  }`,
    variables: {
      fetchDealsInput: {
        "take": take, "skip": skip,
        ...(farmIds && { farmIds: [farmIds] }),
        ...(animalId && { animalId: animalId }),
        ...(status && { status: status })
      }
    }
  };
}

const updateDealQuery = (payloadString: any) => {
  return { "query": ` mutation{updateDeal(updateDealInput:${payloadString}){success }}`, variables: {} };
}

const createDealQuery = (payloadString: any) => {
  return {
    query: `mutation($createDealInput:CreateDealInput!) {
            createDeal(createDealInput:$createDealInput){
                id
                dealNo
        }
      }`,
    variables: {
      "createDealInput": payloadString
    },
  };
}


export const DealQuery = {
  getDealQuery,
  createDealQuery,
  updateDealQuery
}