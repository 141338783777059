import { memo } from "react";
import Icon from "../../images/sell-cattle-icon.svg";
interface CheckListIconProps {
  alt?: string;
  className?: string;
}
const SellCattleIcon = (props: CheckListIconProps) => {
  const { alt = "", className = "" } = props;
  return <img src={Icon} alt={alt} className={className} />;
};
export default memo(SellCattleIcon);
