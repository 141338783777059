import { memo } from "react";
import Icon from "../../images/deal-icon-white.svg";
interface DealIconProps {
  alt?: string;
  className?: string;
}
const DealIconWhite = (props: DealIconProps) => {
  const { alt = "", className = "" } = props;
  return <img src={Icon} alt={alt} className={className} />;
};
export default memo(DealIconWhite);
