import { CURRENCY } from "../../constants/app-constants";
import { isValid } from "date-fns";
import moment from "moment"

const currencyFormat = (value: any, isDecimal = false) => {
  if (!value) {
    return `${CURRENCY.INR}0`;
  }

  if (isDecimal) {
    value = Number(parseFloat(value).toFixed(2));
  } else {
    // remove decimal
    value = parseInt(value);
  }
  const formatted = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${CURRENCY.INR}${formatted}`;
};
const currencyInputFormat = (value: any, isDecimal = false) => {
  if (!value) {
    return `0`;
  }

  if (isDecimal) {
    value = Number(parseFloat(value).toFixed(2));
  } else {
    // remove decimal
    value = parseInt(value);
  }
  const formatted = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${formatted}`;
};

const getFormateDate = (value: string) => (isValid(new Date(value)) && moment.utc(value).format("DD-MMM-YYYY")) || "";
const getUTCFormateDate = (value: string) => (isValid(new Date(value)) && moment(value).format("DD-MMM-YYYY")) || "";
const getFormateDateForForm = (value: string) => (isValid(new Date(value)) && moment.utc(value).format("YYYY-MM-DD")) || "";
const getFormateTime = (value: string) => (isValid(new Date(value)) && (new Date(value)).toISOString().substring(11, 16)) || value;

export { currencyFormat, currencyInputFormat, getFormateDate, getFormateTime, getFormateDateForForm, getUTCFormateDate };
