import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppModel } from '../../models/app-model/app-model';
import { ActionType } from './action-types';
import { animalsService } from '../../services/api';
import { AppointmentQuery } from "../../query/appointment-query";
import { AnimalsQuery } from '../../query/animal-query';

const fetchAsyncAppointment = createAsyncThunk(
  ActionType.SET_APPOINTMENT_LIST,
  async (queryInput: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(auth.token, AppointmentQuery.appointmentsByDateQuery(queryInput));
      return response.data.data.appointmentsByDate || [];
    } catch (error) {
      return []
    }
  }
);
const updateAppointmentAction = createAsyncThunk(
  ActionType.SET_UPDATE_APPOINTMENT_LIST,
  async (obj: any, { getState }) => {
    const { queryInput, editAppointment } = obj;
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(auth.token, AppointmentQuery.updateAppointmentQuery(editAppointment));
    // const response = await animalsService.getQueryRequest(auth.token, AppointmentQuery.appointmentsByDateQuery(queryInput));
    return response.data.data.updateAppointment || { success: false };
  }
);

const createAppointmentAction = createAsyncThunk(
  ActionType.CREATE_APPOINTMENT,
  async (obj: any, { getState }) => {
    const { createAppointment } = obj;
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(auth.token, AppointmentQuery.createAppointmentQuery(createAppointment));
    // const response = await animalsService.getQueryRequest(auth.token, AppointmentQuery.appointmentsByDateQuery(queryInput));
    return response.data.data.appointment;
  }
);

const fetchAnimalAppointmentDetails = createAsyncThunk(
  ActionType.SET_SLOT_DETAIL,
  async (payload: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(
        auth.token,
        AnimalsQuery.getAnimalAppointmentDetails(payload)
      );
      const animal = response.data.data.getSingleAnimal || [];

      return animal[0] || { id: "", media: [], tag: "" }
    } catch (error) {
      return []
    }
  }
);

export const AppointmentThunk = {
  fetchAsyncAppointment,
  updateAppointmentAction,
  createAppointmentAction,
  fetchAnimalAppointmentDetails
}