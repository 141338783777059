import React from 'react';
import { ROUTE_ROLE_PERMISSION } from '../constants/app-permission';
const Dashboard = React.lazy(() => import('../pages/dashboard/dashboard'));
const CattleDetailPage = React.lazy(() => import('../pages/cattle-detail/cattle-detail'));
const CattleDirectoryPage = React.lazy(() => import('../pages/cattle-directory/cattle-directory'));
const CheckList = React.lazy(() => import('../pages/checklist'));
const CheckListDetail = React.lazy(() => import('../pages/checklist-detail'));
const MilkingHistory = React.lazy(() => import('../pages/milking-record/milking-record'));
// const QualityReviews = React.lazy(() => import('../pages/quality-reviews/quality-reviews'));
// const QualityAssigned = React.lazy(() => import('../pages/quality-assigned/quality-assigned'));
const FeedTracker = React.lazy(() => import('../pages/feed-tracker/feed-tracker'));
// const IncomingCattle = React.lazy(() => import('../pages/incoming-cattle/incoming-cattle'));
// const OutgoingCattle = React.lazy(() => import('../pages/outgoing-cattle/outgoing-cattle'));
const Appointments = React.lazy(() => import('../pages/appointments/appointments'));
// const Team = React.lazy(() => import('../pages/team/team'));
// const AssignBuffalo = React.lazy(() => import('../pages/assigned-buffalo/assigned-buffalo'));
// const BuffaloPayment = React.lazy(() => import('../pages/payment/buffalo/buffalo-payment'));
const FeedsPage = React.lazy(() => import('../pages/feeds/feeds'));
const CreateUpdateFeed = React.lazy(() => import('../pages/feeds/createUpdateFeed'));
const FeedsDetailPage = React.lazy(() => import("../pages/feeds/createUpdateFeed"));
// const AnimalTransfer = React.lazy(() => import("../pages/animal-transfer"));
const SellCattlePage = React.lazy(() => import("../pages/sell-cattle/sell-cattle"));
const Booking = React.lazy(() => import('../pages/booking/booking'));
const DealPage = React.lazy(() => import('../pages/deal/deal'));
export const staticRoute = {
  dailyVetList: "/checklist/daily-vet-task",
  outgoingCheckList: "/checklist/outgoing",
  incomingCheckList: "/checklist/incoming",
}
export const routePath = {
  // dashboard: "/",
  cattleDetailPageWithTab: "/cattle-detail/:id/:tab",
  cattleDetailPage: "/cattle-detail/:id/",
  cattleDirectory: "/",
  qualityReviews: "/quality-reviews",
  qualityAssigned: "/quality-assigned",
  milkingRecord: "/milking-record/:tab",
  milkingRecordYeild: "/milking-record/yeild",
  checkList: "/checklist/:tab",
  checkListDetail: "/checklist/:tab/:id",

  feedTracker: "/feed-tracker",
  incomingCattle: "/incoming-cattle",
  outgoingCattle: "/outgoing-cattle",
  appointments: '/appointments',
  team: '/team',
  assignBuffalo: "/assign-buffalo",
  buffaloPayment: '/buffalo-payment',
  feeds: "/feeds",
  feedsDetail: "/feeds/:id/",
  createUpdateFeed: "/feeds/create-update",
  AnimalTransfer: "/animal-transfer",
  sellCattle: "/sell-cattle",
  booking: "/booking",
  deal: "/deal"
};

const MainRouteList = [
  // { path: routePath.dashboard, exact: true, name: 'Dashboard', element: Dashboard, roles: ROUTE_ROLE_PERMISSION.Home },
  { path: routePath.cattleDetailPageWithTab, exact: true, name: 'CattleDetailPage', element: CattleDetailPage, roles: ROUTE_ROLE_PERMISSION.CATTLE_DIRECTORY },
  { path: routePath.cattleDetailPage, exact: true, name: 'CattleDetailPage', element: CattleDetailPage, roles: ROUTE_ROLE_PERMISSION.CATTLE_DIRECTORY },
  { path: routePath.cattleDirectory, exact: true, name: 'CattleDirectoryPage', element: CattleDirectoryPage, roles: ROUTE_ROLE_PERMISSION.CATTLE_DIRECTORY },
  // { path: routePath.qualityReviews, exact: true, name: 'QualityReviews', element: QualityReviews, roles: ROUTE_ROLE_PERMISSION.QUALITY_CHECK },
  // { path: routePath.qualityAssigned, exact: true, name: 'QualityAssigned', element: QualityAssigned, roles: ROUTE_ROLE_PERMISSION.QUALITY_CHECK },
  { path: routePath.milkingRecord, exact: true, name: 'MilkingRecord', element: MilkingHistory, roles: ROUTE_ROLE_PERMISSION.MILKING_RECORD },
  { path: routePath.checkList, exact: true, name: 'DailyChecklist', element: CheckList, roles: ROUTE_ROLE_PERMISSION.CHECKLIST },
  { path: routePath.checkListDetail, exact: true, name: 'DailyChecklistDetail', element: CheckListDetail, roles: ROUTE_ROLE_PERMISSION.CHECKLIST },
  // { path: routePath.arrivalCattle, exact: true, name: 'ArrivalCattle', element: ArrivalCattle, roles: ROUTE_ROLE_PERMISSION.CHECKLIST },
  // { path: routePath.cattleBeforeDelivery, exact: true, name: 'CattleBeforeDelivery', element: CattleBeforeDelivery, roles: ROUTE_ROLE_PERMISSION.CHECKLIST },
  { path: routePath.feedTracker, exact: true, name: 'feedTracker', element: FeedTracker, roles: ROUTE_ROLE_PERMISSION.MILKING_RECORD },
  // { path: routePath.incomingCattle, exact: true, name: 'incomingCattle', element: IncomingCattle, roles: ROUTE_ROLE_PERMISSION.INCOMING_CATTLE },
  // { path: routePath.outgoingCattle, exact: true, name: 'outgoingCattle', element: OutgoingCattle, roles: ROUTE_ROLE_PERMISSION.OUTGOING_CATTLE },
  { path: routePath.appointments, exact: true, name: 'appointments', element: Appointments, roles: ROUTE_ROLE_PERMISSION.APPOINTMENT },
  // { path: routePath.team, exact: true, name: 'team', element: Team, roles: ROUTE_ROLE_PERMISSION.TEAM },
  // { path: routePath.assignBuffalo, exact: true, name: 'assignBuffalo', element: AssignBuffalo, roles: ROUTE_ROLE_PERMISSION.ASSIGNED_BUFFALO },
  // { path: routePath.buffaloPayment, exact: true, name: 'buffaloPayment', element: BuffaloPayment, roles: ROUTE_ROLE_PERMISSION.ASSIGNED_BUFFALO },
  { path: routePath.feeds, exact: true, name: "feeds", element: FeedsPage, roles: ROUTE_ROLE_PERMISSION.FEED },
  { path: routePath.feedsDetail, exact: true, name: "feedsDetail", element: FeedsDetailPage, roles: ROUTE_ROLE_PERMISSION.FEED },
  { path: routePath.createUpdateFeed, exact: true, name: "createUpdateFeed", element: CreateUpdateFeed, roles: ROUTE_ROLE_PERMISSION.FEED },
  { path: routePath.sellCattle, exact: true, name: "sell-cattle", element: SellCattlePage, roles: ROUTE_ROLE_PERMISSION.SELL_CATTLE },
  { path: routePath.booking, exact: true, name: 'booking', element: Booking, roles: ROUTE_ROLE_PERMISSION.BOOKING },
  { path: routePath.deal, exact: true, name: 'deal', element: DealPage, roles: ROUTE_ROLE_PERMISSION.DEAL },
]

export default MainRouteList
