import { AuthReducerModel, AuthDetailModel } from "../../models/auth/auth.models";
import { createSlice } from '@reduxjs/toolkit';
import { AuthThunk } from './actions';
export const initialState: AuthReducerModel = {
  authDetail: {
    userId: "",
    accessToken: "",
    displayName: "",
    email: "",
    phoneNumber: "",
    photoURL: "",
    customAttributes: { roles: [] }
  },
  sourceLocation: "",
  token: "",
  userRoles: {
    isVet: false,
    isDataAdmin: false,
    isAdmin: false,
    isHubManager: false,
    isProcurementHead:false, 
    isQCHead:false,
    isFieldOfficer:false,
    isProcurementManager:false,
    isCST:false
  },
  isTokenExp: false,
  expirationTime: 0,
  isSuccess: false,
  isError: false,
  message: "",
  isLoading: true,
  toast: {
    isToast: false,
    type: "",
    heading: "",
    subHeading: "",
    content: "",
  }
};
export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    refreshAuth: (state, action) => {
      state.isTokenExp = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.userRoles = action.payload;
    },
    setToastMessage: (state, action) => {
      state.toast = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AuthThunk.setAuthDetail.pending, (state) => {
        state.isLoading = true;
        state.isTokenExp = false;
      })
      .addCase(AuthThunk.setAuthDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = action.payload.accessToken as any;
        state.expirationTime = action.payload.expirationTime as any;
        state.authDetail = action.payload as any;
        state.sourceLocation = "MeraPashu360 Hub";
        state.isTokenExp = false;
      })
      .addCase(AuthThunk.setAuthDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.token = '';
        state.expirationTime = 0;
        state.authDetail = {} as AuthDetailModel;
        state.isTokenExp = false;
      })
      .addCase(AuthThunk.signOut.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AuthThunk.signOut.fulfilled, (state) => {
        state.authDetail = {} as AuthDetailModel;
        state.token = '';
        state.expirationTime = 0;
        state.isLoading = false;
      })
      .addCase(AuthThunk.signOut.rejected, (state) => {
        state.isLoading = false;
      })
  },
});
const { refreshAuth, setIsAdmin, setToastMessage } = AuthSlice.actions;
export const AuthReducerAction = {
  refreshAuth,
  setIsAdmin,
  setToastMessage
}
export default AuthSlice.reducer;


