
export const milkQualityRecordObj = `{
  id
  snfPercentage
  fatPercentage
  waterPercentage
  densityValue
  densityUom
}`
export const milkYeildRecordObj = `
  recordId
  milkYieldFromAnimal
  calfDrinkFromTeat
  calfDrinkFromBottle
  milkingTime
  milkingDate
`;
const getMilkRecordQuery = (param: any) => {
  const { search, startDate, endDate, page, rowsPerPage } = param || {};
  const obj = `{ 
    recordId
    milkYieldFromAnimal
    calfDrinkFromTeat
    calfDrinkFromBottle
    milkingTime
  }`;
  return {
    query: `query ($queryInput:GetMilkingRecordByDateInput!){
      getMilkingRecordByDate (getMilkingRecordByDateInput:$queryInput){
        milkRecords{
          id
          tag
          evening ${obj}
          morning ${obj}
        }
        totalRecords
      }
    }`,
    variables: {
      queryInput: {
        startDate: startDate,
        endDate: endDate,
        currPage: page,
        rowsPerPage: rowsPerPage,
        ...(search && { searchTerm: search })
      },
    },
  };
};
const getMilkYeildQuery= (param: any) => {
  const { farmId, search, startDate, endDate, page, rowsPerPage, lactating = false } = param || {};
  const time = `{${milkYeildRecordObj}
  pastDateMilkRecord{milkYieldFromAnimal}}`
  return {
    query: `query ($queryInput:FarmAnimalMilkYeidlInput!){
      getMilkYeildByFarmId (farmAnimalMilkYeidlInput:$queryInput){
        milkRecords{
          id
          tag
          evening ${time}
          morning ${time}
        }
        totalRecords
      }
    }`,
    variables: {
      queryInput: {
        ...(farmId && { farmId: farmId }),
        ...(lactating && { lactating: lactating }),
        startDate: startDate,
        endDate: endDate,
        ...(page && { currPage: page }),
        ...(rowsPerPage && { rowsPerPage: rowsPerPage }),
        ...(search && { searchTerm: search })
      },
    },
  };
}
const createMilkRecordQuery = (createRecords: any) => {
  return {
    query: `mutation($queryInput:[CreateMultipleMilkYieldRecordsInput!]!) {
      createMultipleMilkYieldRecords(records:$queryInput) {
            animalId
            docId
            milkingTime
            milkYieldFromAnimal
            calfDrinkFromTeat
            calfDrinkFromBottle
          }
        }`,
    variables: {
      queryInput: createRecords,
    },
  };
};

const updateMilkRecordQuery = (updateRecords: any) => {
  return {
    query: `mutation ($queryInput:[UpdateMultipleMilkYieldRecordInput!]!) {
      updateMultipleMilkYieldRecords (records:$queryInput){
        recordId
        animalId
        milkingTime
        milkYieldFromAnimal
        calfDrinkFromTeat
        calfDrinkFromBottle
      }
    }`,
    variables: {
      queryInput: updateRecords,
    },
  };
};

const getMilkQualityQuery = (param: any) => {
  const { farmId, search, startDate, endDate, page = 0, rowsPerPage = 0, lactating = false } = param || {};
  return {
    query: `query ($queryInput:FarmAnimalMilkQualityInput!){
      getMilkQualityByFarmId(farmAnimalMilkQualityInput:$queryInput){
          animalId
          tag
          milkQualityRecord${milkQualityRecordObj}   
        }
      }`,
    variables: {
      queryInput: {
        farmId: farmId,
        startDate: startDate,
        endDate: endDate,
        ...(lactating && { lactating: lactating }),
        ...(page && { currPage: page }),
        ...(rowsPerPage && { rowsPerPage: rowsPerPage }),
        ...(search && { searchTerm: search })
      }
    }
  };
};
const createMilkQualityQuery = (createRecords: any) => {
  return {
    query: `mutation ($mutationInput:[CreateMilkQualityRecordInput!]! ) {
        createMilkQualityRecord (records:$mutationInput) {
          animalId
          recordId
          densityValue
          recordTime
          fatPercentage
          snfPercentage
          waterPercentage
          densityUom 
        }
      }`,
    variables: {
      mutationInput: createRecords,
    },
  };
};
const updateMilkQualityQuery = (updateRecords: any) => {
  return {
    query: `mutation ($mutationInput:[UpdateMilkQualityRecordInput!]!) {
        updateMilkQualityRecord (records:$mutationInput){
          animalId
          recordId
          snfPercentage
          waterPercentage
          fatPercentage
          waterPercentage
          densityValue 
          densityUom
        }
      }`,
    variables: {
      mutationInput: updateRecords,
    },
  };
};
export const MilkYeildQuality = {
  getMilkRecordQuery,
  getMilkYeildQuery,
  createMilkRecordQuery,
  updateMilkRecordQuery,
  getMilkQualityQuery, 
  createMilkQualityQuery,
  updateMilkQualityQuery
}