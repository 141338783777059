const userQuery = `
userId 
mobileNumber
name`

const getUserByRole = (param: any) => {
  const { role = "" } = param;
  return {
    "query": `query ($role:String!) {
    getEmployees (role: $role) {
      ${userQuery}
    }
  }`,
    variables: {
      role: role
    }
  };
}

const getCustomerByPhoneNumber = (param: any) => {
  const { phoneNo = "" } = param;
  return {
    "query": `query ($phoneNumber: String!) {
    customer (phoneNumber: $phoneNumber) {
        name
        phoneNo
        userId
    }
  }`,
    variables: {
      phoneNumber: "+91" + phoneNo
    }
  };
}
export const UserQuery = {
  getUserByRole,
  getCustomerByPhoneNumber
}