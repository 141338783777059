const sellerQuery = `
id
address{
    addressLine1
    city
    district
    pincode
    state
    addressLine2
    country
    village
    name
    nearby
    longitude
    latitude
}
name
mobileNumber
fathersName
milkingEvening
mobileVerified
milkingMorning
buffaloCount
cowCount
createdAt
updatedAt
createdBy
updatedBy
nextFollowUp`

const getSellerQuery = (param: any) => {
  const { mobileNumber = "", name = "", pincode = "", district = "" } = param;
  return {
    "query": `query ($id:String,$mobileNumber:String, $name: String, $pincode: String, $district: String) {
    findSeller (mobileNumber: $mobileNumber, id: $id, name: $name, pincode: $pincode, district: $district) {
      ${sellerQuery}
    }
  }`,
    variables: {
      "mobileNumber": mobileNumber,
      "name": name,
      "pincode": pincode,
      "district": district
    }
  };
}
export const SellerQuery = {
  getSellerQuery,
}