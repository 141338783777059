import { createSlice } from "@reduxjs/toolkit";
import { UserReducerModel } from "../../models/user/user.models";
import { UserThunk } from "./actions";

export const initialState: UserReducerModel = {
  userList: [],
  isLoading: true,
  isError: false,
  isFetching: false,
  message: "",
};
export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UserThunk.getUserByRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UserThunk.getUserByRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userList = action.payload as any;
      })
      .addCase(UserThunk.getUserByRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as any;
      })
  },
});
export default UserSlice.reducer;


