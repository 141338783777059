const assignedQuery = () => {
  return {
    query: `query {
          getqcOrders {
            assigned {
              order
              cattleId
              assignedTo
              customerName
              status
              location
            }
          }
        }`,
  };
};
const questionQuery = (orderId: string) => {
  return {
    query: `query ($queryInput:GetQcReportByIdInput! ){
          getqcReportById (getQcReportByIdInput:$queryInput) {
            questions
            qcReport
          }
        }`,
    variables: {
      queryInput: {
        orderId: orderId, //'0EERSWakSyAwvzQRzQE2'
      },
    },
  };
};

const qcMilkRecordByAnimalTagQuery=(animalTag:string)=>{
  return{
    query:`
    query{
      qcMilkRecordByAnimalTag(
        animalTag: "${animalTag}"
      ){
        animalTag
        firstMilking
        secondMilking
        thirdMilking
        predictedMilking
        }
    }
    `,
    variables:{}
  };
};

const updateqcOrdersTagNameQuery = (orderId: string, newTagName: string) => {
  return {
    query: `
    mutation{
      updateqcOrdersTagName(
        orderId:"${orderId}"
        newTagName:"${newTagName}"
      ){
        success
      }
    }`,
    variables: {},
  };
};
const submitQCReportQueryMutation = (payload: any) => {
  return {
    query: `mutation ($queryInput:QcReviewInput!) {
      postQcReview (qcReviewInput:$queryInput) {
        success
      }
    }`,
    variables: {
      queryInput: payload,
    },
  };
};

const closeQCMutationQuery= (payload: any) => {
  return {
    query: ` mutation ($queryInput:UpdateQcStatusInput!) {
      updateQcStatus (updateQcStatusInput:$queryInput) {
        success
      }
    }`,
    variables: {
      queryInput: payload,
    },
  };
}
export const QualityCheckQuery = {
  questionQuery,
  assignedQuery,
  updateqcOrdersTagNameQuery,
  submitQCReportQueryMutation,
  closeQCMutationQuery,
  qcMilkRecordByAnimalTagQuery
};
