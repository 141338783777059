import { combineReducers } from 'redux';
import AuthSlice from './shared/async-thunk/auth/reducer';
import AnimalsSlice from './shared/async-thunk/animals/reducer';
import MilkSlice from './shared/async-thunk/milk-history/reducer';
import AppointmentSlice from './shared/async-thunk/appointment/reducer';
import QualityCheckSlice from './shared/async-thunk/quality-check/reducer'
import FeedReducerSlice from './shared/async-thunk/feed-history/reducer'
import FeedsReducerSlice from './shared/async-thunk/feeds/reducer';
import FarmSlice from './shared/async-thunk/farm/reducer';
import NavBarSlice from './shared/async-thunk/sidebar/reducer';
import SellCattleSlice from './shared/async-thunk/sell-cattle/reducer';
import SellerSlice from './shared/async-thunk/seller/reducer';
import UserSlice from './shared/async-thunk/user/reducer';
import DealSlice from './shared/async-thunk/deal/reducer';
const rootReducer = combineReducers({
    navbar: NavBarSlice,
    auth: AuthSlice,
    farm: FarmSlice,
    appointment: AppointmentSlice,
    animals: AnimalsSlice,
    milkHistory: MilkSlice,
    feedHistory: FeedReducerSlice,
    feeds: FeedsReducerSlice,
    qualityCheck: QualityCheckSlice,
    sellCattle: SellCattleSlice,
    seller: SellerSlice,
    user: UserSlice,
    deal: DealSlice
});

export default rootReducer;
