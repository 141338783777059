import { createAsyncThunk } from '@reduxjs/toolkit'; 
import { animalsService } from '../../services/api';
import { QualityCheckQuery } from "../../query/qc-query";
import { ActionType } from './action-types'; 
import { AppModel } from '../../models/app-model/app-model';
import { QCReviewModel } from '../../models/quality-check/qc.models';
import { QualityService } from '../../helper/qc-service';
import { QCReducerAction } from './reducer';

const fetchQCAssigned = createAsyncThunk(
  ActionType.SET_QUALITY_CHECK_ASSIGNED_LIST,
  async (_,thunkAPI) => {
      const {getState,dispatch}=thunkAPI;
    const { auth } = getState() as AppModel;  
    const response = await animalsService.getQueryRequest(auth.token, QualityCheckQuery.assignedQuery());
    const qcList=response.data.data.getqcOrders.assigned;
    const item:QCReviewModel=qcList.length?qcList[0]:null;
    dispatch(QCReducerAction.onSelectCattle({cattleId:item.cattleId,orderId:item.order}));
    await dispatch(fetchQCQuestions(item.order)); 
    return qcList;
  }
); 
const fetchQCQuestions = createAsyncThunk(
  ActionType.SET_QUALITY_CHECK_QUESTION_LIST,
  async (orderId:string,{getState}) => { 
    const { auth } = getState() as AppModel; 
    const response = await animalsService.getQueryRequest(auth.token, QualityCheckQuery.questionQuery(orderId));
    const data=response.data.data.getqcReportById || {};
    const questionsData=QualityService.getQCQuestion(data); 
    return questionsData; 
  }
); 

const updateqcOrderTagName = createAsyncThunk(
  ActionType.SET_QC_ORDERS_TAG_NAME,
  async ({ cattleId,orderId, newTagName }: any, { getState }) => {
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(
      auth.token,
      QualityCheckQuery.updateqcOrdersTagNameQuery(orderId, newTagName)
    );
    const success = response.data.data.updateqcOrdersTagName.success
    if(success)return {orderId: orderId,cattleId:cattleId, newTagName: newTagName, success:success};
    else return { orderId: orderId,cattleId, success: success };
  }
); 
const submitQCReport = createAsyncThunk(
  ActionType.SET_SUBMIT_QUALITY_CHECK_REPORT,
  async (payload:any,{getState,dispatch}) => { 
    const { auth } = getState() as AppModel;  
     await animalsService.getQueryRequest(auth.token, QualityCheckQuery.submitQCReportQueryMutation(payload));
    const response =  await dispatch(fetchQCQuestions(payload.orderId));   
    return response.payload; 
  }
); 
const closeQCReport = createAsyncThunk(
  ActionType.SET_CLOSE_QUALITY_CHECK_REPORT,
  async (payload:any,{getState,dispatch}) => { 
    const { auth } = getState() as AppModel; 
    await animalsService.getQueryRequest(auth.token, QualityCheckQuery.closeQCMutationQuery(payload));
    await dispatch(fetchQCAssigned());   
  }
); 



const fetchqcMilkingByAnimalTag = createAsyncThunk(
  ActionType.SET_QC_MILKING_BY_TAG_NAME,
  async (animalTag: string, { getState }) => {
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(
      auth.token,
      QualityCheckQuery.qcMilkRecordByAnimalTagQuery(animalTag)
    );
    const qcMilkingData = response.data.data.qcMilkRecordByAnimalTag;

    const returnResponse = {
      averageMilking: (
        qcMilkingData.firstMilking + 
        qcMilkingData.secondMilking + 
        qcMilkingData.thirdMilking)/3,
      predictedMilking: qcMilkingData.predictedMilking,
      minimumMilking: Math.min(
        qcMilkingData.firstMilking, 
        qcMilkingData.secondMilking, 
        qcMilkingData.thirdMilking
      )
    };
    return returnResponse;
  }
); 


export const QualityCheckThunk={
   fetchQCAssigned, 
   fetchQCQuestions,
   updateqcOrderTagName,
   submitQCReport,
   closeQCReport,
   fetchqcMilkingByAnimalTag
}