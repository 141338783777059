import React, { Fragment } from 'react';
import { useAppDispatch } from '../../../app/hooks'
import Modal from '../modal/modal'
import Button from '../button/button'
import { AuthThunk } from '../../async-thunk/auth/actions';
import { THEME_COLOR_NAME } from '../../../constants/app-constants'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons';
const ExpTokenModal = () => {
    const dispatch = useAppDispatch();
    const isTokenExp = JSON.parse(localStorage.getItem('isTokenExp') || "false");
    const onLogin = async () => {
        localStorage.removeItem('persist:root');
        localStorage.removeItem('isTokenExp');
        await dispatch(AuthThunk.signOut());
    }
    const refreshToken = async () => {
        localStorage.removeItem('persist:root');
        localStorage.removeItem('isTokenExp');
        window.location.reload();
    }
    const Header = () => {
        return <div className='d-flex align-items-center session-header'>
            <CIcon icon={cilInfo} height={30} />
            Session Expired
        </div>
    }
    const Footer = () => {
        return <Fragment>
            <Button text={"Stay Connected"} color={THEME_COLOR_NAME.PRIMARY} onClick={refreshToken} />
            <Button text={"Sign in"} color={THEME_COLOR_NAME.PRIMARY} onClick={onLogin} />
        </Fragment>
    }
    return (
        <Fragment>
            {isTokenExp &&
                <Modal isVisible={isTokenExp} size={"lg"} isFooter={true} footer={<Footer />}
                    closeButton={false} isHeader={true} customClass={"session"} header={<Header />}
                    children="Your session is expired. Refresh or login with new session " />
            }
        </Fragment>
    )
}

export default ExpTokenModal;
