import { createSlice } from "@reduxjs/toolkit";
import { SellCattleReducerModel, UpdateSellCattleModel } from "../../models/sell-cattle/sell-cattle.models";
import { SellCattleThunk } from "./action";

export const initialState: SellCattleReducerModel = {
  sellCattleList: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isEditLoading: false,
  editSellCattle: { id: "", status: "", reason: "" }
};
export const SellCattleSlice = createSlice({
  name: "sell-cattle",
  initialState,
  reducers: {
    editSellCattle: (state, action) => {
      state.editSellCattle = action.payload as UpdateSellCattleModel;
    },
    setSellCattle: (state, action) => {
      state.sellCattleList = action.payload as any;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(SellCattleThunk.fetchAsyncSellCattle.pending, (state) => {
        state.sellCattleList = [];
        state.isLoading = true;
      })
      .addCase(SellCattleThunk.fetchAsyncSellCattle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sellCattleList = action.payload as any;
        state.editSellCattle = { id: "", status: "", reason: "" }
      })
      .addCase(SellCattleThunk.fetchAsyncSellCattle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.sellCattleList = [];
        state.editSellCattle = { id: "", status: "", reason: "" }
      })
  },
});

const { setSellCattle, editSellCattle } = SellCattleSlice.actions;
export const SellCattleReducerActions = {
  setSellCattle, editSellCattle
}
export default SellCattleSlice.reducer;
