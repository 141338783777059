import { createSlice } from "@reduxjs/toolkit"; 
import { AppointmentReducerModel ,UpdateAppointmentModel} from "../../models/appointment/appointment.models";
import { AppointmentThunk } from "./action"; 

export const initialState: AppointmentReducerModel = {
    appointmentList:[],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message:"",
    isEditLoading:false,
    editAppointment:{id:"",status:""}
}; 
export const AppointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: { 
    editAppointment: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.editAppointment = action.payload as UpdateAppointmentModel;
    },
    setAppointment: (state,action) => { 
      state.appointmentList = action.payload as any;
    },
    
  },
  extraReducers: ( builder ) => {
    builder
        .addCase(AppointmentThunk.fetchAsyncAppointment.pending,(state) => {
          state.appointmentList =[];
            state.isLoading = true;
        })
        .addCase(AppointmentThunk.fetchAsyncAppointment.fulfilled, (state, action ) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.appointmentList = action.payload as any;
            state.editAppointment={id:"",status:""}
        })
        .addCase(AppointmentThunk.fetchAsyncAppointment.rejected, ( state, action ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload as string;
            state.appointmentList = [];
            state.editAppointment={id:"",status:""}
        })
        .addCase(AppointmentThunk.updateAppointmentAction.pending, (state) => { 
          state.isEditLoading=true;
        })
        .addCase(AppointmentThunk.updateAppointmentAction.fulfilled, (state, action) => { 
          state.appointmentList = action.payload as any;
          state.isEditLoading=false;
        })
        .addCase(AppointmentThunk.updateAppointmentAction.rejected, (state, action) => {
            // console.log("rejected");
        }) 
  },
});

const { editAppointment,setAppointment } = AppointmentSlice.actions;
export const AppointmentReducerActions={
  editAppointment,
  setAppointment
}  
export default AppointmentSlice.reducer;
