export const VALID_MILK_NUMBER: any = new RegExp(/^[0-9]{1,2}([.][0-9]{1,3})?$/);
export const VALID_MILK_DIGIT_THREEDIGIT: any = new RegExp(/^[0-9]{1,3}([.][0-9]{1,3})?$/);
export const INVALID_NUMBER: any = new RegExp(/^(?:[1-9]|[1-4][0-9]|50)$/)//(/^([0-9])\1*$/);
export const EXCEPT_THIS_SYMBOLS = ["e", "E", "+", "-"];
export const VALID_ANIMAL_TAG = new RegExp(/^[a-zA-Z0-9-]{5,12}$/);
export const milkEntryValidator = (number: any, max = 10, isThreeDigit = false) => {
    if (isThreeDigit) {
        return max >= parseFloat(number) && VALID_MILK_DIGIT_THREEDIGIT.test(parseFloat(number));
    } else {
        return max >= parseFloat(number) && VALID_MILK_NUMBER.test(parseFloat(number));
    }
};
const validateNumber = (value: any) => {
    var regExp = new RegExp("^\\d+$");
    var isValid = regExp.test(value);
    return isValid;
}
const validateAnimalTag = (value: any) => {
    var isValid =  VALID_ANIMAL_TAG.test(value);
    return isValid;
}
export const ValidationService = {
    validateNumber,
    validateAnimalTag
}