import { CreateFeedParams, DeleteMediaParams, SignedUrlParam, UpdateFeedParams } from "../models/feeds/feeds.models";

const getFeeds = (
  feedId: string="",
  sku: string=""
) => {
  return {
    query: 
    `query{
        feeds(fetchFeedInput:{
            name:"${feedId}",
            sku:"${sku}"
        }){
            id
            sku
            name
            recommendedFor
            specification{
              protein
              fat
            }
            usage{
                description
                mediaCollection{
                    name
                    url
                    contentType
                }
            }
            benefits{
                description
                mediaCollection{
                    name
                    contentType
                    url
                }
            }
            dosage{
                description
                mediaCollection{
                    name
                    url
                    contentType
                }
            }
            composition{
                description
                mediaCollection{
                    name
                    url
                    contentType
                }
            }
        }
    }`,
    variables: {},
  };
};


const createFeedQuery = ({
  name,
  sku,
  recommendedFor,
  fat=null,
  protein=null,
  benefitsDescription,
  dosageDescription,
  usageDescription,
  compositionDescription,
}: CreateFeedParams) => {
  return {
    query: `
    mutation{
      createFeed(
        createFeedInput:{
          name: "${name}",
          sku: ${JSON.stringify(sku)},
          recommendedFor: ${JSON.stringify(recommendedFor)},
          specification:{
              protein:${protein},
              fat:${fat}
          },
          benefits: {
              description: "${benefitsDescription}"
          },
          dosage:{
              description:"${dosageDescription}"
          },
          usage:{
              description:"${usageDescription}"
          },
          composition:{
              description:"${compositionDescription}"
          }
        }
      ){
        id  
      }
    }`,
    variables: {},
  };
};



const updateFeedQuery = ({
  id,
  name,
  sku,
  recommendedFor,
  fat=null,
  protein=null,
  benefitsDescription,
  dosageDescription,
  usageDescription ,
  compositionDescription,
}: UpdateFeedParams) => {
  return {
    query: `
    mutation{
      updateFeed(
        id: "${id}",
        updateFeedInput:{
          name: "${name}",
          sku: ${JSON.stringify(sku)},
          recommendedFor: ${JSON.stringify(recommendedFor)},
          specification:{
              protein:${protein},
              fat:${fat}
          },
          benefits: {
              description: "${benefitsDescription}"
          },
          dosage:{
              description:"${dosageDescription}"
          },
          usage:{
              description:"${usageDescription}"
          },
          composition:{
              description:"${compositionDescription}"
          }
        }
      ){
        success  
      }
    }`,
    variables: {},
  };
};


const signedUrlQuery = ({
  feedId,
  mediaOf,
  mimeType,
  action = "resumable",
}: SignedUrlParam) => {
  return {
    query: `
    query{
      getFeedsSignedUrl(
        feedId: "${feedId}",
        mediaOf: "${mediaOf}",
        mimeType: "${mimeType}",
        action: "${action}"
      ){
        signedUrl
       }
    }`,
    variables: {},
  };
};

const deleteMedia = ({
  feedId,
  mediaOf,
  path
}: DeleteMediaParams) => {
  return {
    query: `
    mutation{
      deleteMedia(
        feedId: "${feedId}",
        mediaOf: "${mediaOf}",
        path:"${path}"
      ){
        success
        message
       }
    }`,
    variables: {},
  };
};

export const FeedsQuery = {
  getFeeds,
  createFeedQuery,
  updateFeedQuery,
  signedUrlQuery,
  deleteMedia,
  
};
