import { FarmModel, FarmReducerModel } from "../../models/farm/farm.models";
import { createSlice } from '@reduxjs/toolkit';
import { FarmThunk } from './actions'; 
export const initialState: FarmReducerModel = {
  farmList: [],
  isLoading: true, 
  isError: false,
  isFetching: false,
  message: "",  
  selectedFarm: { id: "" } as FarmModel
};
export const FarmSlice = createSlice({
  name: "farm",
  initialState,
  reducers: {
    setSelectFarm: (state, action) => {
      state.selectedFarm = action.payload || {} as FarmModel;
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(FarmThunk.getAllFarm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FarmThunk.getAllFarm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.farmList = action.payload as any;
      })
      .addCase(FarmThunk.getAllFarm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as any;
      }) 
  },
});
const { setSelectFarm } = FarmSlice.actions;
export const FarmReducerAction = {
  setSelectFarm
}
export default FarmSlice.reducer;


