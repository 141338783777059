/* eslint-disable array-callback-return */
const getSearchData = (data:Array<any>, search:string) => { 
    const filteredData = data.filter(item => {
      return Object.keys(item).some(key =>item[key].replace(/[-\s]/g, "").toLowerCase().indexOf(search.replace(/[-\s]/g, "").toLowerCase()) !== -1);
    }); 
    return filteredData;
};
 const getSearchColumn = (data:Array<any>, search:string,column:string) => { 
  const filteredData = data.filter(item => {
        return (item[column].replace(/[-\s]/g, "").toLowerCase().indexOf(search.replace(/[-\s]/g, "").toLowerCase()) !== -1)  
  }); 
  return filteredData;
};
export {
  getSearchData,
  getSearchColumn
}