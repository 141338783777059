import { useState } from 'react';
import { AppModel } from '../models/app-model/app-model';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { MILK_HISTORY_ROUTE } from '../../constants/app-constants';
import { useNavigate } from 'react-router-dom';
import { getYearMonthDate } from '../helper/get-day-from-date';
import { MilkingService } from '../helper/milking-service';
import { AnimalMilkThunk } from '../async-thunk/milk-history/actions';
import { MilkReducerActions } from '../async-thunk/milk-history/reducer';
import { format } from 'date-fns';
export const useMilkListFilterResult = () => {
    const { auth, milkYeildRecords, filterYeildList, perPageRow, currentpage, isFetching, filteredMilkQuality } = useAppSelector((state: AppModel) => ({
        filterYeildList: state.milkHistory.filterYeildList,
        milkYeildRecords: state.milkHistory.filterYeildList,
        filteredMilkQuality: state.milkHistory.filteredMilkQuality,
        totalRecords: state.milkHistory.totalRecords,
        isMilkLoading: state.milkHistory.isLoading,
        currentpage: state.milkHistory.currentpage,
        perPageRow: state.milkHistory.perPageRow,
        isFetching: state.milkHistory.isLoading,
        milkSearch: state.milkHistory.milkSearch,
        auth: state.auth,
    }));
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { userRoles } = auth;
    const { isDataAdmin } = userRoles;
    const [isLoadRecords, setIsLoadRecords] = useState(false);

    const milkQuality = (tab: string, isMilkPropertQuality: boolean) => {
        if (isMilkPropertQuality) {
            dispatch(MilkReducerActions.setMilkFeature(tab))
            navigate(`/milking-record/${tab}`)
        } else {
            dispatch(MilkReducerActions.setMilkFeature(MILK_HISTORY_ROUTE.YEILD))
            navigate(`/milking-record/${MILK_HISTORY_ROUTE.YEILD}`)
        }
    }
    const dispatchGetMilkRecord = async (startDate = new Date(), endDate = new Date(), tab: any, isQualityDate: any) => {
        await setIsLoadRecords(true);
        if (tab === MILK_HISTORY_ROUTE.QUALITY && isQualityDate) {
            await dispatch(AnimalMilkThunk.fetchAsyncMilkQuality({ startDate, endDate }));
        } else if (tab === MILK_HISTORY_ROUTE.YEILD) {
            const date = new Date(startDate);
            date.setDate(date.getDate() - 1);
            const pastDate = format(date, 'yyyy-MM-dd');
            await dispatch(AnimalMilkThunk.fetchAsyncMilkRecord({ startDate: pastDate, endDate }));
        }
        await setIsLoadRecords(false);
    }

    const createUpdateMilkRecord = async (data: any, tab: any) => {
        const { validate = [], create = [], update = [], date = new Date() } = data;
        if (!validate.length && create.length) {
            const createRecord = { selectedDate: date, createRecords: create, isUpdate: update.length }
            if (tab === MILK_HISTORY_ROUTE.QUALITY) {
                await dispatch(AnimalMilkThunk.createAsyncMilkQuality(createRecord));   // create milk quality
            } else {
                await dispatch(AnimalMilkThunk.createAsyncMilkRecord(createRecord));   // create milk yeild
            }
        }
        if (!validate.length && update.length && isDataAdmin) {
            const updateRecord = { selectedDate: date, updateRecords: update, isCreate: create.length };
            if (tab === MILK_HISTORY_ROUTE.QUALITY) {
                await dispatch(AnimalMilkThunk.updateAsyncMilkQuality(updateRecord));  // create milk quality
            } else {
                await dispatch(AnimalMilkThunk.updateAsyncMilkRecord(updateRecord));   // update milk yeild
            }
        }
    }
    const setMilkingHistoryTab = (tab: string | undefined, isMilkPropertQuality: boolean) => {
        switch (tab) {
            case MILK_HISTORY_ROUTE.YEILD:
                // navigate(`/milking-record/${MILK_HISTORY_ROUTE.YEILD}`)
                dispatch(MilkReducerActions.setMilkFeature(MILK_HISTORY_ROUTE.YEILD))
                break;
            case MILK_HISTORY_ROUTE.QUALITY:
                milkQuality(MILK_HISTORY_ROUTE.QUALITY, isMilkPropertQuality)
                break;
            default:
                navigate(`/milking-record/${MILK_HISTORY_ROUTE.YEILD}`);
                dispatch(MilkReducerActions.setMilkFeature(MILK_HISTORY_ROUTE.YEILD));
                break;
        }
    }
    const getMilkQualityEntry = async (dateTime: any) => {
        const { createRecords, validateField, updateRecords } = await MilkingService.getMilkQualityFillRecord(filteredMilkQuality, dateTime);
        await dispatch(MilkReducerActions.setInputValidate(validateField));
        return { createRecords, validateField, updateRecords }
    }
    const getMilkYeildEntry = async (dateTime: any) => {
        const { createRecords, validateField, updateRecords } = await MilkingService.getMilkYieldFillRecord(filterYeildList, dateTime);
        await dispatch(MilkReducerActions.setInputValidate(validateField));
        return { createRecords, validateField, updateRecords }
    }
    const validateCurrentDateMilkField = async (date = new Date(), selectDate = new Date(), tab: any, selectedData = (a: Array<any>, b: Array<any>, c: Array<any>, d: any) => { }) => {
        const dateTime = `${getYearMonthDate(selectDate)}`;
        if (tab === MILK_HISTORY_ROUTE.QUALITY) {
            const { createRecords, validateField, updateRecords } = await getMilkQualityEntry(dateTime);
            await selectedData(validateField, createRecords, updateRecords, date);
        } else if (tab === MILK_HISTORY_ROUTE.YEILD) {
            const { createRecords, validateField, updateRecords } = await getMilkYeildEntry(dateTime);
            await selectedData(validateField, createRecords, updateRecords, date);
        }
    }
    const saveMilkRecord = async (tab: string | undefined, selectDate = new Date(), isRouteChange = false) => {
        const dateTime = `${getYearMonthDate(selectDate)}`;
        if (tab === MILK_HISTORY_ROUTE.QUALITY) {
            const { createRecords, validateField, updateRecords } = await getMilkQualityEntry(dateTime);
            const data = { validate: validateField, create: createRecords, update: updateRecords, date: selectDate };
            await createUpdateMilkRecord(data, MILK_HISTORY_ROUTE.QUALITY);
            return validateField;
        } else if (tab === MILK_HISTORY_ROUTE.YEILD) {
            const { createRecords, validateField, updateRecords } = await getMilkYeildEntry(dateTime);
            const data = { validate: validateField, create: createRecords, update: updateRecords, date: selectDate };
            await createUpdateMilkRecord(data, MILK_HISTORY_ROUTE.YEILD);
            return validateField;
        }
    }
    return {
        milkYeildRecords,
        filterYeildList,
        perPageRow,
        currentpage,
        isFetching,
        isLoadRecords,
        dispatchGetMilkRecord,
        createUpdateMilkRecord,
        setMilkingHistoryTab,
        validateCurrentDateMilkField,
        saveMilkRecord,
    };
}
