import { ActionType } from './action-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { animalsService } from '../../services/api';
import { AppModel } from '../../models/app-model/app-model';
import { FarmQuerys } from '../../query/farm-query';
import { FarmModel } from '../../models/farm/farm.models';
const getAllFarm = createAsyncThunk(
  ActionType.SET_FARM_LOCATION,
  async (_, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const { data } = await animalsService.getQueryRequest(auth.token, FarmQuerys.getFarmQuery());
      const farmList = data.data.getFarms || [];
      const returnList = farmList.filter((item: FarmModel) => {
        item.label = item.name;
        item.value = item.id;
        return item;
      })
      return returnList;
    }catch(error){
      return []
    }
  });
export const FarmThunk = {
  getAllFarm,
};