import React, { Fragment, useEffect } from 'react';
import "./sass/style.scss" 
import { useAppDispatch, useAppSelector } from './app/hooks';
import AppRoutes from "./routes/routes";
import { AuthThunk } from './shared/async-thunk/auth/actions';
import { AppModel } from './shared/models/app-model/app-model';
import Loader from "./shared/components/loader"
const App: React.FC = () => {
  const { isLoading } = useAppSelector((state: AppModel) => state.auth);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(AuthThunk.loginAuthDetail())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {isLoading && <Loader type='full' />}
      <AppRoutes />
    </Fragment>
  )
}

export default App;
