import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react";
import { memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AuthReducerAction } from "../../async-thunk/auth/reducer"; 
import { getSetToastMessage } from "../../helper/toast-message";
import { AppModel } from "../../models/app-model/app-model";
import "./style.scss"
interface ToastModalProps {
    toastNo?: any;
    toasterRef?: any;
    placement?: string;
}
const ToastModal = (props: ToastModalProps) => {
    const { toastNo = 0, toasterRef = "", placement = "top-end" } = props;
    const { toast } = useAppSelector((state: AppModel) => state.auth);
    const { isToast = false, heading = "", subHeading = "", type = "", content } = toast;
    const dispatch = useAppDispatch();

    const closeButton = () => {
        const param = getSetToastMessage();
        dispatch(AuthReducerAction.setToastMessage(param))
    }
    // useEffect(() => {
    //     if (isToast) {
    //         let timerFunc = setTimeout(() => {
    //             closeButton()
    //         }, 1000);
    //         return () => clearTimeout(timerFunc);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    const getFill = () => {
        if (type === "success") { return "#20c997" }
        else if (type === "error") { return "#dc3545" }
    }
    
    return (
        <CToaster ref={toasterRef} push={toastNo} placement={placement} className={`toast-modal ${type}`} >
            <CToast autohide={isToast} visible={isToast} animation={true}  onClose={() => closeButton()}>
                <CToastHeader closeButton={true}>
                    <svg
                        className="rounded me-2"
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                        role="img"
                    >
                        <rect width="100%" height="100%" fill={getFill()}></rect>
                    </svg>
                    <strong className="me-auto">{heading}</strong>
                    <small>{subHeading}</small>
                </CToastHeader>
                <CToastBody>{content}</CToastBody>
            </CToast>
        </CToaster>
    )
}
export default memo(ToastModal);