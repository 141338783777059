import { createAsyncThunk } from "@reduxjs/toolkit";
import { animalsService } from "../../services/api";
import { ActionType } from "./action-types";
import { AnimalsQuery } from "../../query/animal-query";

import { AppModel } from "../../models/app-model/app-model";
import { FarmCattleModel, SignedUrlParam } from "../../models/animal/animal.models";
// import { corsProxyForSignedUrl } from "../../../constants/url-constants";
import axios from "axios";
import { getSetToastMessage, setToastMessageContent } from "../../helper/toast-message";
import { AuthReducerAction } from "../auth/reducer";
import { CATTLE_DIRECTORY_STATUS, TOAST_MESSAGE_PAGE } from "../../../constants/app-constants";
import { CattleDirectoryConfig } from "../../../modules/cattle-directory/config";
import _ from "lodash";

const fetchAsyncAnimalsList = createAsyncThunk(
  ActionType.SET_ANIMALS_LIST,
  async (obj: any, { getState }) => {
    const { auth, farm, animals } = getState() as AppModel;
    const { farmList } = farm;
    const { farmId = "", media = "faceFront" } = obj || {};
    try {
      let farmIds: any = [];
      if (farmId === "ALL") {
        farmList.forEach((f: any) => {
          farmIds.push(f.id);
        })
      } else {
        farmIds = [farmId]
      }
      const res = await animalsService.getQueryRequest(auth.token, AnimalsQuery.getAnimalsQuery({ ...obj, farmIds, media, }));
      const response = res?.data?.data?.getAnimals || [];
      if (response && response.length) {
        return { filter: animals.farmAnimal.filter, animals: response, filteredAnimal: response, farmId: farmId }
      } else {
        return { animals: [], filteredAnimal: [], farmId: "", filter: animals.farmAnimal.filter, };
      }
    } catch (error) {
      return { animals: [], filteredAnimal: [], farmId: "", filter: animals.farmAnimal.filter, }
    }
  }
);
const loadAsyncAnimalsList = createAsyncThunk(
  ActionType.SET_ANIMALS_LISTING,
  async (obj: any, { getState }) => {
    const { auth, farm } = getState() as AppModel;
    const { farmList, selectedFarm } = farm;
    const { media = "faceFront", } = obj || {};
    try {
      let farmIds: any = [];
      if (selectedFarm.id === "ALL") {
        farmList.forEach((f: any) => {
          farmIds.push(f.id);
        })
      } else {
        farmIds = [selectedFarm.id]
      }
      const res = await animalsService.getQueryRequest(auth.token, AnimalsQuery.getAnimalsQuery({ ...obj, farmIds, media, }));
      const response = res?.data?.data?.getAnimals || [];
      return response;
    } catch (error) {
      return { animals: [], filteredAnimal: [], farmId: "" }
    }
  }
);
const updateAsyncAnimalStatus = createAsyncThunk(
  ActionType.SET_FARM_ANIMAL_STATUS,
  async (paramObj: any, { getState, dispatch }) => {
    const { auth, animals } = getState() as AppModel;
    const { tag, payload, isChild = false, selectedStatusFilter = [] } = paramObj;
    try {
      const { data } = await animalsService.getQueryRequest(auth.token, AnimalsQuery.updateFarmAnimalStatusMutation(payload, isChild));
      const response = data?.data?.updateAnimal || null;
      if (response && response.id) {
        const toastData = getSetToastMessage(`${TOAST_MESSAGE_PAGE.ANIMAL_STATUS_UPDATE} ${tag}`, TOAST_MESSAGE_PAGE.UPDATE);
        dispatch(AuthReducerAction.setToastMessage(toastData));
        const animalClone = _.cloneDeep(animals.farmAnimal);
        let updateStateAnimal = { ...animalClone } as FarmCattleModel;
        // const { filter }: any = updateStateAnimal;
        if (isChild) {
          const healthStatus = (payload.healthStatus === CATTLE_DIRECTORY_STATUS.NOT_ALIVE) ? CATTLE_DIRECTORY_STATUS.NOT_ALIVE : response.healthStatus;
          const param: any = { animalId: payload.id, status: healthStatus, isFilterAllAnimal: true };
          const { allAnimal = [], filteredAnimal = [] } = CattleDirectoryConfig.filterByAnimalData(updateStateAnimal.animals, param, true);
          let filterStatusByChildAnimal: any = filteredAnimal;
          if (healthStatus === CATTLE_DIRECTORY_STATUS.NOT_ALIVE) {
            const filteredAnimals = filteredAnimal.filter((item: any) => {
              if (item.children && item.children.length) {
                item.children = item.children.filter((c: any) => c.id !== payload.id);
              }
              return item;
            })
            filterStatusByChildAnimal = CattleDirectoryConfig.filterByStatusData(filteredAnimals, selectedStatusFilter);
          }
          updateStateAnimal.filteredAnimal = filterStatusByChildAnimal;
          updateStateAnimal.animals = allAnimal;
        } else {
          const param: any = { animalId: response.id, status: response.animalStatus, animalHealth: response.healthStatus, isFilterAllAnimal: true };
          const { allAnimal = [], filteredAnimal = [] } = CattleDirectoryConfig.filterByAnimalData(updateStateAnimal.animals, param);
          const filterByStatusAnimal = CattleDirectoryConfig.filterByStatusData(filteredAnimal, selectedStatusFilter);
          updateStateAnimal.filteredAnimal = filterByStatusAnimal;
          updateStateAnimal.animals = allAnimal;
        }
        return updateStateAnimal;
      } else {
        if (data.errors && data.errors.length) {
          const error = data.errors.find((item: any) => item.message);
          const toastData = setToastMessageContent(`Error`, error.message, "error");
          dispatch(AuthReducerAction.setToastMessage(toastData));
          return { errorMessage: "animal status not update something goes wrong" }
        } else {
          return animals.farmAnimal
        }
      }
    } catch (error) {
      console.log(error);
      const toastData = setToastMessageContent(`Error`, "something goes wrong", "error");
      dispatch(AuthReducerAction.setToastMessage(toastData));
      return { errorMessage: "animal status not update something goes wrong" }
    }
  });

const fetchAsyncAnimalDetail = createAsyncThunk(
  ActionType.SET_ANIMAL_DETAIL,
  async (payload: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(
        auth.token,
        AnimalsQuery.animalByIdQuery(payload)
      );
      const animal = response.data.data.getSingleAnimal || [];

      return animal[0] || { id: "", media: [], tag: "" }
    } catch (error) {
      return []
    }
  }
);

const updateSignedUrlImage = createAsyncThunk(
  ActionType.SET_SIGNED_URL,
  async (signedUrlParam: SignedUrlParam, { getState }) => {
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(
      auth.token,
      AnimalsQuery.signedUrlQuery(signedUrlParam)
    );
    const signedUrl = response.data.data.getSignedUrl.signedUrl;
    // const signedUrl = corsProxyForSignedUrl + response.data.data.getSignedUrl.signedUrl;
    const headers = {
      "content-type": signedUrlParam.mimeType,
      Authorization: `Bearer ${auth.token}`,
    };
    const uploadImageResponse = await axios.put(
      signedUrl,
      signedUrlParam.newMedia,
      {
        headers: headers,
      }
    );
    console.log(signedUrl, uploadImageResponse);
    return uploadImageResponse.data;
    // return "";
  }
);
const updateAnimalTag = createAsyncThunk(
  ActionType.SET_CHANGE_ANIMAL_TAG,
  async ({ id, tag, reason }: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(
        auth.token,
        AnimalsQuery.updateAnimalTagMutation(id, tag, reason)
      );
      if (response.data && response.data.errors) {
        const error = response.data.errors.find((er: any) => er.message) || {};
        return { errorMessage: error?.message || "" }
      } else if (response && response.data && response.data.data) {
        const animal = response.data.data.changeAnimalTag || "";
        return animal || { id: "", tag: "", errorMessage: "" }
      }
    } catch (error) {
      return []
    }
  }
);

const updateMediaTag = createAsyncThunk(
  ActionType.SET_CHANGE_MEDIA_TAG,
  async ({ id, mediaId, mediaTags }: any, { getState, dispatch }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(
        auth.token,
        AnimalsQuery.updateMediaTagMutation(id, mediaId, mediaTags)
      );
      if (response.data && response.data.errors) {
        const error = response.data.errors.find((er: any) => er.message) || {};
        return { errorMessage: error?.message || "" }
      } else if (response && response.data && response.data.data) {
        const toastData = getSetToastMessage("Animal media updated");
        dispatch(AuthReducerAction.setToastMessage(toastData));
        const animal = response.data.data.updateMediaTag || "";
        return animal || { id: "", tag: "", errorMessage: "" }
      }
    } catch (error) {
      return []
    }
  }
);

const getAnimalMediaFromBazar = createAsyncThunk(
  ActionType.GET_BAZAR_ANIMAL_MEDIA,
  async (queryInput: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(auth.token, AnimalsQuery.getAnimalMediaFromBazarQuery(queryInput));
      return response.data.data.getAnimalMediaFromBazar || [];
    } catch (error) {
      return []
    }
  }
);

const updateAnimalMediaInBazar = createAsyncThunk(
  ActionType.SET_CHANGE_ANIMAL_MEDIA,
  async ({ id, media }: any, { getState, dispatch }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(
        auth.token,
        AnimalsQuery.updateAnimalMediaInBazarMutation(id, media)
      );
      if (response.data && response.data.errors) {
        const error = response.data.errors.find((er: any) => er.message) || {};
        return { errorMessage: error?.message || "" }
      } else if (response && response.data && response.data.data) {
        const toastData = getSetToastMessage("Animal media order updated");
        dispatch(AuthReducerAction.setToastMessage(toastData));
        const animalMedia = response.data.data.updateAnimalMediaInBazar || "";
        return animalMedia || { id: "", media: [], errorMessage: "" }
      }
    } catch (error) {
      return []
    }
  }
);


export const AnimalsThunk = {
  fetchAsyncAnimalDetail,
  fetchAsyncAnimalsList,
  loadAsyncAnimalsList,
  updateSignedUrlImage,
  updateAsyncAnimalStatus,
  updateAnimalTag,
  updateMediaTag,
  getAnimalMediaFromBazar,
  updateAnimalMediaInBazar
};
