import { initialState as farm } from "../shared/async-thunk/farm/reducer";
import { initialState as animals } from "../shared/async-thunk/animals/reducer";
export const migrations = {
    1: (state) => {
        return {
            ...state,
            farm: {
                ...state.farm,
                selectedFarm: farm.selectedFarm
            }
        };
    },
    2: (state) => {
        return {
            ...state,
            animals: {
                ...state.animals,
                farmAnimal: {
                    ...state.animals.farmAnimal,
                    childAnimal: animals.farmAnimal.childAnimal
                }
            }
        };
    },
    3: (state) => {
        return {
            ...state,
            animals: {
                ...state.animals,
                farmAnimal: {
                    ...state.animals.farmAnimal,
                    filter: animals.farmAnimal.filter
                }
            }
        };
    },
    5: (state) => {
        return {
            ...state,
            animals: {
                ...state.animals,
                farmAnimal: {
                    ...state.animals.farmAnimal.animals,
                    filteredAnimal: animals.farmAnimal.filteredAnimal,
                    animals: animals.farmAnimal.animals,
                    filter: animals.farmAnimal.filter
                }
            }
        };
    }
};
