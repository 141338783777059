export const ActionType = {
  GET_SET_MILK_ENTRY:"milk/get-set-milk-entry",
  GET_SET_ANIMAL_MILK_ENTRY:"milk/get-set-animal-milk-entry",
  SET_CREATE_MILK_ENTRY:"milk/set-create-milk-entry",
  SET_UPDATE_MILK_ENTRY:"milk/set-update-milk-entry",
  GET_SET_MILK_QUALITY:"milk/get-set-milk-quality",
  SET_CREATE_MILK_QUALITY:"milk/set-create-milk-quality",
  SET_UPDATE_MILK_QUALITY:"milk/set-update-milk-quality",
   
  IS_LOADING:'milk/is_loading',
  SET_ERROR:'milk/set_error',
}