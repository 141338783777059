import { routePath, staticRoute } from "../routes/main-route-list";
export const STATUS_TYPE = {
    NEW: 'New',
    VISITED: 'Visited',
    CANCELLED: 'Cancelled',
    CONFIRMED: "Confirmed"
}
export const SELECT_APPOINTMENT_STATUS = [{ value: STATUS_TYPE.VISITED, label: STATUS_TYPE.VISITED }, { value: STATUS_TYPE.CANCELLED, label: STATUS_TYPE.CANCELLED }, { value: STATUS_TYPE.CONFIRMED, label: STATUS_TYPE.CONFIRMED }];
export const NUMBER = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    TEN: 10,
    TWELVE: 12,
    TWENTY: 20,
    TWENTY_FIVE: 25,
    THIRTY: 30,
    HUNDRED: 100
}
export const BADGE_STATUS_COLOR: any = {
    Visited: 'success',
    New: 'warning',
    NEW: 'warning',
    Cancelled: 'danger',
    Confirmed: 'info',
    Pending: "warning",
    ongoing: "warning",
    upcoming: 'info',
    Success: "success",
    DISPATCHED: "info",
    AssignedDeliverAgent: "warning",
    Delivered: "success",
    Rejected: 'danger',
    INOBSERVATION: "info",
    INEXPERIMENT: "danger",
    READYFORSALE: "infolight",
    BOOKED: "success",
    SOLD: "success",
    HEALTHY: "success",
    UNWELL: "warning",
    NOT_ALIVE: "secondary",
    UNDER_TREATMENT: "warning",
    SHORTLISTED: "infolight",
    UNDER_NEGOTIATION: "warning",
    REJECTED: "danger",
    CANCELLED: "danger",
    ACCEPTED: "success",
    COMPLETED: "success",
    Closed: "success",
    CONFIRMED: "info",
    Verified: "info",
    Expired: "expired",
    PICKUP_PENDING: "pending",
    IN_TRANSIT: "warning-dark"
}
export const STEP_NAMES = {
    status: "status",
    location: "location"
}
export const TABLE_INPUT_NAME = { SELECT: "select", INPUT: "input", BADGE: "badge", PROGRESSBAR: "progress" };
export const FORM_FIELDS_TYPE = {
    INPUT_TEXT: 'text',
    SELECT: 'select'
}
export const CHECKLIST_TAB = [
    { id: 0, text: "DAILY VET TASKS", active: true, to: staticRoute.dailyVetList },
    { id: 1, text: "OUTGOING CATTLES", active: false, to: staticRoute.outgoingCheckList },
    { id: 2, text: "INCOMING CATTLES", active: true, to: staticRoute.incomingCheckList }
]
export const THEME_COLOR_NAME = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    SUCCESS: "success",
    LIGHT: "light",
    DARK: "dark"
}
export const CATTLE_LIST_TAB = [
    { id: 0, label: "IN FARM" },
    { id: 1, label: "SOLD" }
    // { id: 2, value: "All" }
];
export const CATTLE_DIRECTORY_STATUS = {
    UnderObservation: "Under Observation",
    ReadyForSale: "Ready for Sale",
    UnderExperiment: "Under Experiment",
    Booked: "Booked",
    Healthy: "Healthy",
    Unwell: "Unwell",
    NotAlive: "Not alive",
    UnderTreatment: "Under Treatment",
    PickupPending: "Awaiting Pick Up",
    InTransit: "In Transit",
    INOBSERVATION: "INOBSERVATION",
    INEXPERIMENT: "INEXPERIMENT",
    READYFORSALE: "READYFORSALE",
    BOOKED: "BOOKED",
    SOLD: "SOLD",
    HEALTHY: "HEALTHY",
    UNWELL: "UNWELL",
    NOT_ALIVE: "NOT_ALIVE",
    UNDER_TREATMENT: "UNDER_TREATMENT",
    PICKUP_PENDING: "PICKUP_PENDING",
    IN_TRANSIT: "IN_TRANSIT"
}
export const CATTLE_GENDER: any = {
    female: "Katdi",
    male: "Katda",
    na: "Not Alive",
    none: "Not Alive"
}
export const CATTLE_GENDER_HINDI: any = {
    female: "कटड़ी",
    male: "कटड़ा"
}
export const CATTLE_TYPE = {
    BHAINS: "Bhains",
    BACHDE: "Bachde"
}
export const CURRENCY = {
    INR: "₹"
}
export const CATTLE_DIRECTORY_BUFFALO_STATUS = [    //id will be start with 0
    { id: 0, value: CATTLE_DIRECTORY_STATUS.INOBSERVATION, label: CATTLE_DIRECTORY_STATUS.UnderObservation },
    { id: 1, value: CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT, label: CATTLE_DIRECTORY_STATUS.UnderTreatment },   //  user select animal status  we will send  health status 
    { id: 2, value: CATTLE_DIRECTORY_STATUS.READYFORSALE, label: CATTLE_DIRECTORY_STATUS.ReadyForSale },
    { id: 3, value: CATTLE_DIRECTORY_STATUS.INEXPERIMENT, label: CATTLE_DIRECTORY_STATUS.UnderExperiment },
    { id: 4, value: CATTLE_DIRECTORY_STATUS.BOOKED, label: CATTLE_DIRECTORY_STATUS.Booked },
];
export const CATTLE_DIRECTORY_BUFFALO_QC_STATUS = [
    { id: 5, value: CATTLE_DIRECTORY_STATUS.PICKUP_PENDING, label: CATTLE_DIRECTORY_STATUS.PickupPending },
    { id: 6, value: CATTLE_DIRECTORY_STATUS.IN_TRANSIT, label: CATTLE_DIRECTORY_STATUS.InTransit }

]
export const CATTLE_DIRECTORY_CALVES_STATUS = [//id will be start with 0 
    { id: 0, label: CATTLE_DIRECTORY_STATUS.Healthy, value: CATTLE_DIRECTORY_STATUS.HEALTHY },
    { id: 1, label: CATTLE_DIRECTORY_STATUS.UnderTreatment, value: CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT },
    { id: 2, label: CATTLE_DIRECTORY_STATUS.NotAlive, value: CATTLE_DIRECTORY_STATUS.NOT_ALIVE },
]
export const FILTER_NAME = {
    PRICE: "price",
    MILK: "milk",
    CURRENT_MILK: "currentMilk",
    EXPECTED_MILK: "expectedMilk",
    SATHMEIN: "sathMein",
    BYAT: "lactationNos",
    BYAHI_DIN: "daysFromLastParturition",
    DAYS_IN_HUB: "daysInHub",
    CALF_HEALTH: "calfHealth"
}
export const CATTLE_DIRECTORY_CARD = {
    BYAT: "Byat",
    Milk_Yield: "Milk Yield",
    MILK_CAPACITY: "Milk Capacity",
    KITNE_DIN_KI_BYAHI: "Byahi huve din",
    SATH_MEIN: "Sath Mein",
    CURRENT_PRICE: "Current Price",
    COST_PRICE: "Cost Price",
    CALFID: "Calf ID",
    AGE: "Age",
    GENDER: "Gender",
    MOTHER_ID: "Mother ID"
}
export const CATTLE_DETAIL_SECTION_ROUTE = {
  BASIC_INFORMATION: "basic-information",
  MILKING: "milking",
  MILKING_RECORD: "milking-record",
  PHOTOS_VIDEOS: "photos-and-videos",
  BODY_HEALTH: "body-health",
  DOCUMENTS: "Documents",
  APPOINTMENTS: "Appointments",
  DEALS: "deals",
  CATTLE_REPORT:"cattle-report",
};
export const CATTLE_DETAIL_SECTION: Array<any> = [
  {
    id: 0,
    label: "Basic Information",
    to: CATTLE_DETAIL_SECTION_ROUTE.BASIC_INFORMATION,
    active: [CATTLE_DETAIL_SECTION_ROUTE.BASIC_INFORMATION],
  },
  {
    id: 1,
    label: "Milking",
    to: CATTLE_DETAIL_SECTION_ROUTE.MILKING,
    active: [
      CATTLE_DETAIL_SECTION_ROUTE.MILKING,
      CATTLE_DETAIL_SECTION_ROUTE.MILKING_RECORD,
    ],
    children: [
      { id: 0, label: "Genral", to: CATTLE_DETAIL_SECTION_ROUTE.MILKING },
      {
        id: 1,
        label: "Milk Curve",
        to: CATTLE_DETAIL_SECTION_ROUTE.MILKING_RECORD,
      },
    ],
  },
  {
    id: 2,
    label: "Photos & videos",
    to: CATTLE_DETAIL_SECTION_ROUTE.PHOTOS_VIDEOS,
    active: [CATTLE_DETAIL_SECTION_ROUTE.PHOTOS_VIDEOS],
  },
  {
    id: 3,
    label: "Body & Health",
    to: CATTLE_DETAIL_SECTION_ROUTE.BODY_HEALTH,
    active: [CATTLE_DETAIL_SECTION_ROUTE.BODY_HEALTH],
  },
  // {
  //     id: 4, label: "Documents", to: CATTLE_DETAIL_SECTION_ROUTE.DOCUMENTS,
  //     active: [CATTLE_DETAIL_SECTION_ROUTE.DOCUMENTS],
  // }
  {
    id: 4,
    label: "Appointments",
    to: CATTLE_DETAIL_SECTION_ROUTE.APPOINTMENTS,
    active: [CATTLE_DETAIL_SECTION_ROUTE.APPOINTMENTS],
  },
  {
    id: 5,
    label: "Deals",
    to: CATTLE_DETAIL_SECTION_ROUTE.DEALS,
    active: [CATTLE_DETAIL_SECTION_ROUTE.DEALS],
  },
  {
    id: 6,
    label: "Cattle Report",
    to: CATTLE_DETAIL_SECTION_ROUTE.CATTLE_REPORT,
    active: [CATTLE_DETAIL_SECTION_ROUTE.CATTLE_REPORT],
  },
];
export const CATTLE_CHANGE_TAG_REASON = [
    { label: "Tag was missing when received first time from from farmer", value: "Tag was missing when received first time from from farmer" },
    { label: "Tag got damaged during transfer", value: "Tag got damaged during transfer" },
    { label: "Tag got damaged in hub", value: "Tag got damaged in hub" },
    { label: "Tag was repeat tag and hence needed to be changed", value: "Tag was repeat tag and hence needed to be changed" },
    { label: "Other", value: "Other" }
]
export const MILK_HISTORY_TAB = [{ id: 1, text: "Milk-Yeild" }, { id: 2, text: "Milk-Quality" }]
export const MILK_HISTORY_ROUTE = { YEILD: "yeild", QUALITY: "quality" }
export const EVNING_ENTRY_HEADER = ["Evening Calf Cons. From Teats", "Evening Calf Cons. From Bottle"];
export const MORNING_ENTRY_HEADER = ["Morning Calf Cons. From Teats", "Morning Calf Cons. From Bottle"];
export const MILK_ENTRY_TIME = {
    MORNING: "Morning",
    EVENING: "Evening",
    BOTH: "both"
};

export const MILK_ENTERY_INPUT = { milkYieldFromAnimal: "", calfDrinkFromTeat: "", calfDrinkFromBottle: "0" };
export const MILK_QALITY_INPUT = { densityUom: "g/cm3", densityValue: '', fatPercentage: '', id: "", snfPercentage: '', waterPercentage: '' }
export const VET_INPUT = { snf: "", fat: "", water: "", density: "" }
export const FEED_ENTERY_INPUT = {
    changedAt: "",
    createdAt: "",
    id: "",
    feedId: "",
    recordTime: "",
    sourceLocation: "",
    weightUom: "",
    weightValue: '',
    remainingValue: ""
};
export const FEED_COLUMN_NAME = { OFFERED: "weightValue", REMAINING: "remainingValue", CONCENTRATE: 'concentrate', ROUGHAGE: "roughage", SILAGE: "silage" }
export const QUALITY_CHECK_LIST_TAB = [
    { id: 'review_tab', text: "For Review", to: routePath.qualityReviews },
    { id: 'assigned_tab', text: "Assigned", to: routePath.qualityAssigned }
];
export const QUALITY_REVIEW_LIST_TAB = [
    { id: 0, text: "Quality Check 1", title: "QC1" },
    { id: 1, text: "Medical History", title: "Vaccines & Medical Tests" },
    { id: 3, text: "Photos & Videos", title: "Photos & Videos" },
    { id: 4, text: "Udder & teet", title: "Teat & Udder Health" },
    { id: 5, text: "Milk Tracker", title: "Milking 1" },
    { id: 6, text: "Notes", title: "QC1" }
];

export const FORM_INPUT_TYPE = {
    PHOTO_INPUT: "photo_input",
    BARCODE_INPUT: "barcode_input",
    TEXT_INPUT: "text_input",
    MULTI_CHOICE: "multi_choice",
    SLIDER_INPUT: "slider_input",
    DROP_DOWN: "drop_down",
    VIDEO_INPUT: "video_input"
}
export const FORM_FIELDS_RADIO = {
    OK: 'OK',
    NOTOK: 'Not-Ok'
}
export const FeedTableDataCell = ["morningOffered", "morningRemaining", "eveningOffered", "eveningRemaining"];
export const AppointmentFilter = [{ id: 0, text: "New" }, { id: 1, text: "Visited" }, { id: 2, text: "Confirmed" }, { id: 3, text: "Cancelled" }, { id: 4, text: "All" }];
export const SELECT_QC_REASON = [{ label: "successful purchase", value: "SUCCESSFUL_PURCHASE" }, { label: "failed QC", value: "FAILED_QC" }];

export const TOAST_MESSAGE_PAGE = {
    MILK_YEILD: "Milk yield",
    MILK_QUALITY: "Milk quality",
    CREATE: "created",
    UPDATE: "updated",
    CREATE_UPDATE: "created & updated",
    ANIMAL_TANSFER: "Animal Transfer To",
    ANIMAL_STATUS_UPDATE: "Animal status",
    DEAL_STATUS_UPDATE: "Deal status"
}
export const ANIMAL_STATUS = {
    Pending: "Pending", //New order created
    // Confirmed: "Confirmed",
    // InTransit: "In transit",
    AssignedDeliverAgent: "Assigned Delivery Agent",
    // OutForDelivery: "Out For Delivery",
    Cancelled: "Cancelled",
    Rejected: "Rejected",
    Delivered: "Delivered",
    Dispatched: "Dispatched"
}
export const ANIMAL_TANSFER_STATUS = {
    PENDING: "PENDING", //New order create 
    ASSIGNED_DELIVERY_AGENT: "ASSIGNED_DELIVERY_AGENT",
    DISPATCHED: "DISPATCHED",
    DELIVERED: "DELIVERED",
    CANCELLED: "CANCELLED",
    REJECTED: "REJECTED",
}
export const ANIMAL_FARM_TRANSFER_STATUS = [
    { value: ANIMAL_TANSFER_STATUS.PENDING, label: ANIMAL_STATUS.Pending },
    { value: ANIMAL_TANSFER_STATUS.ASSIGNED_DELIVERY_AGENT, label: ANIMAL_STATUS.AssignedDeliverAgent },
    { value: ANIMAL_TANSFER_STATUS.DISPATCHED, label: ANIMAL_STATUS.Dispatched },
    { value: ANIMAL_TANSFER_STATUS.DELIVERED, label: ANIMAL_STATUS.Delivered },
    { value: ANIMAL_TANSFER_STATUS.CANCELLED, label: ANIMAL_STATUS.Cancelled },
    { value: ANIMAL_TANSFER_STATUS.REJECTED, label: ANIMAL_STATUS.Rejected },
]

export const SELL_STATUS = {
    PENDING: 'PENDING',
    SHORTLISTED: 'SHORTLISTED',
    UNDER_NEGOTIATION: 'UNDER_NEGOTIATION',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    ASSIGNED: 'ASSIGNED',
}
export const SELECT_SELL_CATTLE_STATUS = [
    { value: SELL_STATUS.PENDING, label: "Pending" },
    { value: SELL_STATUS.SHORTLISTED, label: "Shortlisted" },
    { value: SELL_STATUS.UNDER_NEGOTIATION, label: "Under Negotiation" },
    { value: SELL_STATUS.REJECTED, label: "Rejected" },
    { value: SELL_STATUS.ACCEPTED, label: "Accepted" },
]

export const SellCattleFilter = [
    { id: SELL_STATUS.PENDING, text: "Pending Requests", },
    { id: SELL_STATUS.SHORTLISTED + ' ' + SELL_STATUS.UNDER_NEGOTIATION, text: "Attended Requests" },
    { id: SELL_STATUS.REJECTED, text: "Rejected Requests" },
    { id: SELL_STATUS.ACCEPTED, text: "Selected for QC" },
    { id: SELL_STATUS.ASSIGNED, text: "Assigned for QC" }];

export const REJECT_REQUEST_REASONS = [
    { label: "Seller has sold the cattle already", value: "Seller has sold the cattle already" },
    { label: "Seller was just checking the price", value: "Seller was just checking the price" },
    { label: "Seller is not interested anymore to sell the cattle", value: "Seller is not interested anymore to sell the cattle" },
    { label: "Seller is demanding more price for the cattle now", value: "Seller is demanding more price for the cattle now" },
    { label: "QC Members are unavailable", value: "QC Members are unavailable" },
    { label: "Cattle doesn't follow buying criteria", value: "Cattle doesn't follow buying criteria" },
    { label: "The request area of QC is too far", value: "The request area of QC is too far" },
    { label: "Not selling buffalo", value: "Not selling buffalo" },
    { label: "Internal user testing request", value: "Internal user testing request" },
    { label: "Unable to contact seller", value: "Unable to contact seller" },
    { label: "Others", value: "Others" }
]

export const CATTLE_TYPES = [
    { label: "BUFFALO", value: "BUFFALO" },
    { label: "COW", value: "COW" }
]

export const CATTLE_BREED_TYPES = [
    { label: "Murrah", value: "Murrah" },
    { label: "Zaffarabadi", value: "zaffarabadi" }
]

export const CALF_GENDER_TYPES = [
    { label: "Katda", value: "MALE" },
    { label: "Katdi", value: "FEMALE" },
    { label: "Not Alive", value: "NONE" }
]

export const BookingFilter = [{ id: "ALL", text: "All" }, { id: "FARM_VISIT", text: "Hub Visit" }, { id: "VIDEO_CALL", text: "Video Call" }];
export const DealFilter = [{ id: "ALL", text: "All" }, { id: "NEW", text: "New" }, { id: "Verified", text: "Approved" }, { id: "Rejected", text: "Rejected" }, { id: "Closed", text: "Closed" }, { id: "myDeals", text: "My Deals" }];
export const BOOKING_STATUS_TYPES = {
    NEW: 'NEW',
    CONFIRMED: "CONFIRMED",
    CANCELLED: 'CANCELLED',
    COMPLETED: "COMPLETED"
}
export const SELECT_BOOKING_STATUS = [
    { value: BOOKING_STATUS_TYPES.CONFIRMED, label: "Confirmed" },
    { value: BOOKING_STATUS_TYPES.COMPLETED, label: "Completed" },
    { value: BOOKING_STATUS_TYPES.CANCELLED, label: "Cancelled" },
];
export const DEAL_STATUS_TYPES = {
    NEW: 'New',
    APPROVED: "Verified",
    REJECTED: "Rejected",
    CLOSED: 'Closed',
    EXPIRED: 'Expired'
}
export const DEAL_UI_STATUS: any = {
    'New': 'New',
    'Verified': "Approved",
    'Rejected': "Rejected",
    'Closed': 'Closed',
    'Expired': 'Expired'
}
export const SELECT_DEAL_STATUS = [
    { value: DEAL_STATUS_TYPES.APPROVED, label: "Approved" },
    { value: DEAL_STATUS_TYPES.REJECTED, label: "Rejected" },
    { value: DEAL_STATUS_TYPES.CLOSED, label: "Closed" },
    { value: DEAL_STATUS_TYPES.EXPIRED, label: "Expired" },
];

export const VISIT_TYPES: any = {
    FARM_VISIT: 'Hub Visit',
    VIDEO_CALL: 'Video Call',
}

export const AppointmentPurpose = {
    GENERIC: 'GENERIC',
    MILKING: 'MILKING'
}

export const REJECT_VISIT_REASONS = [
    { label: "Customer has bought from somewhere else", value: "Customer has bought from somewhere else" },
    { label: "Plan of purchasing is canceled", value: "Plan of purchasing is canceled" },
    { label: "Customer is busy ", value: "Customer is busy " },
    { label: "Customer has stopped picking phone", value: "Customer has stopped picking phone" },
    { label: "Other", value: "Other" }
]

export const MediaFilter = [
    { id: "Pitch", text: "Pitch" },
    { id: "appActive", text: "Active on app" },
    { id: "All", text: "All" },
    { id: ["faceFront"], text: "Horns"},
    { id: ["milkVeinsLeft", "milkVeinsRight", "udderSideLeft",  "udderBack", "teatsHealth"], text: "Udder" },
    { id: ["sideFullLeft" , "sideFullRight" , "spine" , "tail" , "sacralLigament" , "tailHeadLigament" , "back" , "trochanter" , "physicalAppearance" , "walking"
    ], text: "Body" },
    { id: ["faceFront", "eyesCloseup", "noseCloseup"], text: "Face" },
    { id: ["eyesCloseup"], text: "Eyes" },
    { id: "Videos", text: "Videos" },
]

export const CattleMediaTypes = [
    { label: "Horns", value:JSON.stringify([{label: "Face Front", value:"faceFront"}])},
    { label: "Udder", value:JSON.stringify([
        {label: "milkVeinsLeft", value:"milkVeinsLeft"},
        {label: "milkVeinsRight", value:"milkVeinsRight"}, 
        {label: "udderSideLeft", value:"udderSideLeft"}, 
        {label: "udderBack", value:"udderBack"}, 
        {label: "teatsHealth", value:"teatsHealth"}])
    },
    { label: "Body", value: JSON.stringify([
        {label: "sideFullLeft", value:"sideFullLeft"},
        {label: "sideFullRight", value:"sideFullRight"}, 
        {label: "spine", value:"spine"}, 
        {label: "tail", value:"tail"}, 
        {label: "sacralLigament", value:"sacralLigament"},
        {label: "tailHeadLigament", value:"tailHeadLigament"},
        {label: "back", value:"back"},
        {label: "trochanter", value:"trochanter"},
        {label: "physicalAppearance", value:"physicalAppearance"},
        {label: "walking", value:"walking"}])
    },
    { label: "Face", value:JSON.stringify([
        {label: "faceFront", value:"faceFront"},
        {label: "eyesCloseup", value:"eyesCloseup"}, 
        {label: "noseCloseup", value:"noseCloseup"}])
    },
]
