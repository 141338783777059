import { MILK_ENTRY_TIME } from "../../constants/app-constants";
import { format, isFriday, isMonday, isValid } from 'date-fns'
import moment from "moment";
export const MORNING_MILK_TIME = "06:00:00";
export const EVENING_MILK_TIME = "18:00:00";
const DAY_LIST = [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];

const ABBR_DAY_LIST = [
    "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
];

const MONTH_LIST = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const ABBR_MONTH_LIST = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

const getWeekDay = (date = new Date(), abbr = false) => {
    const dayList = abbr ? ABBR_DAY_LIST : DAY_LIST;
    return dayList[date.getDay()];
};

const getMonth = (date = new Date(), abbr = false) => {
    const monthList = abbr ? ABBR_MONTH_LIST : MONTH_LIST;
    return monthList[date.getMonth()];
};
const getWeekDateList = (startDate = new Date(), endDate = new Date()) => {
    let arr = [];
    let startDateTime: any = new Date(startDate);
    let endDateTime: any = new Date(endDate);
    while (startDateTime <= endDateTime) {
        arr.push(new Date(startDateTime));
        startDateTime.setDate(startDateTime.getDate() + 1);
    }
    return arr;
}
const getActiveDate = (selectedDate = new Date(), date = new Date()) => {
    return (new Date(selectedDate).getTime() === new Date(date).getTime());
}
const getDayOfSuffix = (n: number) => {
    if (n >= 11 && n <= 13) {
        return "th";
    }
    switch (n % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}
const getSelectedDateFormat = (date = new Date(), isFullYear = true) => {
    const selectDate = new Date(date)
    const year = isFullYear ? selectDate.getFullYear() : selectDate.getFullYear().toString().slice(-2);
    return `${selectDate.getDate()}${getDayOfSuffix(selectDate.getDate())} ${getMonth(selectDate)}${!isFullYear ? '’' : ''} ${year}`
}
const getLastDateMonthYear = (date = new Date(), day: number, isNext = false) => {
    return isNext ? new Date(date.getFullYear(), date.getMonth(), date.getDate() + day) : new Date(date.getFullYear(), date.getMonth(), date.getDate() - day);
}
const getMilkTime = (time: string) => {
    return (time === MILK_ENTRY_TIME.MORNING.toLowerCase() ? MORNING_MILK_TIME : time === MILK_ENTRY_TIME.EVENING.toLowerCase() ? EVENING_MILK_TIME : "");
}
const getYearMonthDate = (date = new Date()) => {
    return `${formatDate(date)}`
}
const getDateListFormat = (date = new Date(), isYearfull = false) => {
    return `${getSelectedDateFormat(date, isYearfull)} : ${getWeekDay(date)}`
}
const getDateMonthYearTwoDigitFormat = (date = new Date(), isYearfull = false, isDayFirst = true,) => {
    return isDayFirst ? `${getWeekDay(date)}, ${getSelectedDateFormat(date, isYearfull)} ` : `${getSelectedDateFormat(date, true)} ${getWeekDay(date)}`
}
const formatDate = (date = new Date()) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
const getHours = (date = new Date()) => {
    var hours = date.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours;
}
const getMilkEntryMode = (date = new Date(), entryField: string) => {
    let isEntryMode: any;
    const today = new Date();
    const selectDate = new Date(date);
    const currentDate = getLastDateMonthYear(today, 0);
    const curHr = today.getHours();
    if (selectDate.getTime() < currentDate.getTime()) {
        isEntryMode = false;
    } else {
        if (curHr < 12) {
            if (entryField.toLowerCase() === MILK_ENTRY_TIME.MORNING.toLowerCase()) {
                isEntryMode = false
            } else if (entryField.toLowerCase() === MILK_ENTRY_TIME.EVENING.toLowerCase()) {
                isEntryMode = MILK_ENTRY_TIME.EVENING;
            }
        } else {
            isEntryMode = false;
        }
    }
    return isEntryMode;
}
const isMilkPropertyDate = (date = new Date()) => {
    const selectDate = new Date(date);
    return (isMonday(selectDate) || isFriday(selectDate));
}
const getonlyDate = (date = new Date()) => {
    const returnDate = new Date(date);
    returnDate.setHours(0, 0, 0, 0);
    return returnDate;
}
const getTimeList = (startTime = 0, endTime = 24, interval = 15) => {
    let times: any = [];
    var start = new Date(1, 1, 1, 0, 0);
    var end = new Date(1, 1, 2, 0, 0);
    for (var d = start; d < end; d.setMinutes(d.getMinutes() + interval)) {
        if ((d.getHours() >= startTime) && (d.getHours() <= endTime)) {
            const time = format(d, "hh:mm a");
            times.push({ label: time, value: time, hour: d.getHours(), minute: d.getMinutes() });
        }
    }
    return times;
}
const getDiffDay = (startDate: any, endDate: any, ageIndays = "days") => {
    let days: any = "";
    const dateA = moment(startDate);
    const dateB = moment().utc(endDate);
    const diff: any = dateB.diff(dateA, 'days');
    const diffDay = (Math.abs(diff) || Math.abs(parseInt(diff)));
    if (parseInt(diff) === 1) {
        days = (ageIndays && `${Math.abs(diff)}  day`) || Math.abs(parseInt(diff));
    } else {
        days = (ageIndays && `${Math.abs(diff)}  ${ageIndays}`) || Math.abs(parseInt(diff));
    }
    return { days, diffDay }
}
const getUtcDate = (date = new Date(), format = "YYYY-MM-DD") => {
    return (isValid(new Date(date)) && moment.utc(date).format(format)) || ""
}
const getCurrentWeekdays = () => {
    const d = new Date();
    let index = d.getDay();
    var daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const current = moment();
    let resultDates = [];
    let n = 7;
    while (n > 0) {
        resultDates.push(daysInWeek[index++] + "-" + current.format("DD") + "-" + current.format("MM") + "-" + current.format("YYYY"));
        if (index == 7) index = 0;
        current.add(1, "day");
        n--;
    }
    return resultDates;
}
export {
    getWeekDay,
    getMonth,
    getWeekDateList,
    getActiveDate,
    getSelectedDateFormat,
    getLastDateMonthYear,
    getMilkTime,
    getYearMonthDate,
    getMilkEntryMode,
    getHours,
    formatDate,
    getDateListFormat,
    getDateMonthYearTwoDigitFormat,
    isMilkPropertyDate,
    getonlyDate,
    getTimeList,
    getDiffDay,
    getDayOfSuffix,
    getUtcDate,
    getCurrentWeekdays,
}