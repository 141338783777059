import React, { Fragment, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./layout.scss";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";
import MainRoutes from "../routes/main-routes";
import { useAppSelector } from '../app/hooks';
import { AppModel } from '../shared/models/app-model/app-model';
import { useMilkListFilterResult } from '../shared/hooks/use-milk-list-filter-result';
import ExpTokenModal from '../shared/components/exp-token-modal';
import ToastModal from "../shared/components/toast-modal";
const Layout: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { toast, authDetail, sidebarUnfoldable, milkFeature, selectedDate } = useAppSelector((state: AppModel) => ({
    authDetail: state.auth.authDetail,
    toast: state.auth.toast,
    selectedDate: state.milkHistory.selectedDate,
    milkFeature: state.milkHistory.milkFeature,
    sidebarUnfoldable: state.navbar.sidebarUnfoldable,
  }))
  const toasterRef = useRef();
  const { customAttributes } = authDetail || {};
  const { roles = [] } = customAttributes || {}
  const { saveMilkRecord } = useMilkListFilterResult();
  const isDashboard = ['/'].includes(pathname)

  const onClickNav = async (e: any, b = null) => {
    e.preventDefault();
    await saveMilkRecord(milkFeature, selectedDate);
    if (b) {
      navigate(b);
    }
  }
  return (
    <Fragment>
      <Header onClickNav={onClickNav} />
      {(!!roles.length) && <Fragment>
        <Sidebar onClickNav={onClickNav} />
        <main className={`defaultLayout-main px-4 ${sidebarUnfoldable ? "dashboard-unfoldable " : ""} ${isDashboard ? "" : "pt-4"}`}>
          <MainRoutes />
        </main>
        <ExpTokenModal />
        {toast.isToast && <ToastModal toasterRef={toasterRef} />}
      </Fragment>}
    </Fragment>
  )
}

export default Layout 