import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppModel } from '../../models/app-model/app-model';
import { ActionType } from './action-types';
import { animalsService } from '../../services/api';
import { SellCattleQuery } from '../../query/sell-cattle-query';


const fetchAsyncSellCattle = createAsyncThunk(
  ActionType.SELL_CATTLE_LIST,
  async (queryInput: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const response = await animalsService.getQueryRequest(auth.token, SellCattleQuery.getSellCattleByStatusQuery(queryInput));
      return response.data.data.getSycRequests || [];
    } catch (error) {
      return []
    }
  }
);

const updateSellCattleStatusAction = createAsyncThunk(
  ActionType.UPDATE_SELL_CATTLE_STATUS,
  async (obj: any, { getState }) => {
    const { queryInput, editSellCattle } = obj;
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(auth.token, SellCattleQuery.updateSellCattleStatusQuery(editSellCattle));
    // const response = await animalsService.getQueryRequest(auth.token, SellCattleQuery.getSellCattleByStatusQuery(queryInput));
    return response.data.data.updateSycRequestStatus;
  }
);

const updateSellCattleAction = createAsyncThunk(
  ActionType.UPDATE_SELL_CATTLE_LIST,
  async (obj: any, { getState }) => {
    const { id, editSyncRequest } = obj;
    const { auth } = getState() as AppModel;
    const response = await animalsService.getQueryRequest(auth.token, SellCattleQuery.updateSellCattleQuery(id, editSyncRequest));
    // const response = await animalsService.getQueryRequest(auth.token, SellCattleQuery.getSellCattleByStatusQuery(queryInput));
    return response.data.data.updateSycRequestData;
  }
);

export const SellCattleThunk = {
  fetchAsyncSellCattle,
  updateSellCattleStatusAction,
  updateSellCattleAction
}