export const ActionType = {
  GET_FEEDS: "feeds/get-animal-feeds",
  GET_FEED: "feeds/get-animal-feed",
  CREATE_FEED: "feeds/create_feed",
  UPDATE_FEED: "feeds/update_feed",
  SET_SIGNED_URL: "feeds/set-signed-url",
  DELETE_FEED_MEDIA: "feeds/delete_media",
  UPDATE_FEED_MEDIA: "feeds/update_media",
  IS_LOADING: "feeds/is_loading",
  SET_ERROR: "feeds/set_error",
};