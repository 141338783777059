const getFarmQuery = () => {
    return {
        "query": `query{
            getFarms{ 
            id
            code
            name
            operational
                address{
                id
                pincode
                state
                city
                addressLine1
                addressLine2
                district
            }
        }
    }`
    }
}
export const FarmQuerys = {
    getFarmQuery,
}