import { createSlice } from "@reduxjs/toolkit";
import { SellerReducerModel } from "../../models/seller/seller.models";
import { SellerThunk } from "./actions";


export const initialState: SellerReducerModel = {
  sellerList: [],
  isLoading: true,
  isError: false,
  isFetching: false,
  message: "",
};
export const SellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SellerThunk.getSeller.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SellerThunk.getSeller.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sellerList = action.payload as any;
      })
      .addCase(SellerThunk.getSeller.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as any;
      })
  },
});
export default SellerSlice.reducer;


