import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppModel } from "../../models/app-model/app-model";
import { SellerQuery } from "../../query/seller-query";
import { animalsService } from "../../services/api";
import { ActionType } from "./action-types";

const getSeller = createAsyncThunk(
  ActionType.GET_SELLER,
  async (queryInput: any, { getState }) => {
    const { auth } = getState() as AppModel;
    try {
      const { data } = await animalsService.getQueryRequest(auth.token, SellerQuery.getSellerQuery(queryInput));
      return data.data.findSeller || [];
    } catch (error) {
      return []
    }
  });
export const SellerThunk = {
  getSeller,
};