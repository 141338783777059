export const getSuccessToastMessage = (heading = "", type = "") => {
    const text = `${heading} ${type} successfully`;
    return {
        heading: heading,
        content: text
    }
}
const getSetToastMessage = (toastHeading = "", toastType = "", type = "success") => {
    const { heading = "", subHeading = "", content = "" }: any = getSuccessToastMessage(toastHeading, toastType)
    return {
        ...(toastHeading && { isToast: true }),
        ...(toastHeading && { type: type }),
        ...(!toastHeading && { type: "" }),
        heading: heading,
        subHeading: subHeading,
        content: content,
    }
}
export const getSuccessMessage = (heading = "", content = "") => {
    return {
        heading: heading,
        content: `${content} successfully`
    }
}
const setToastMessageContent = (toastHeading = "", toastContent = "", type = "success") => {
    const { heading = "", subHeading = "", content = "" }: any = getSuccessMessage(toastHeading, toastContent)
    return {
        ...(toastHeading && { isToast: true }),
        ...(toastHeading && { type: type }),
        ...(!toastHeading && { type: "" }),
        heading: heading,
        subHeading: subHeading,
        content: content,
    }
}
export {
    setToastMessageContent,
    getSetToastMessage
}