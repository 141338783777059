import { FeedsReducerModel } from "../../models/feeds/feeds.models";
import { createSlice } from "@reduxjs/toolkit";
import { AnimalFeedsThunk } from "./actions";
export const initialState: FeedsReducerModel = {
  feedsList: [],
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  message: "",
  isError: false,

  feed: {
    id: "",
    sku: [],
    name: "",
    specification: {
      protein: "",
      fat: "",
    },
    recommendedFor: [],
    usage: {},
    dosage: {},
    composition: {},
    benefits: {},
  },

  signedUrlImage: "",
  isSignedUrlImageError: false,
  isSignedUrlImageLoading: false,
  isSignedUrlImageSuccess: false,
  signedUrlMessage: "",

  deleteMediaSuccess: false,
  isdeleteMediaLoading: false,
  deleteMediaMessage: "",

  updateMediaSuccess: false,
  isupdateMediaLoading: false,
  updateMediaMessage: "",

  createUpdateFeedSuccess: false,
  createUpdateFeedLoading: false,
  createUpdateFeedMessage: "",
};

export const FeedsReducerSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AnimalFeedsThunk.fetchAsyncFeeds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AnimalFeedsThunk.fetchAsyncFeeds.fulfilled, (state, action) => {
        state.isError=false;
        state.isLoading = false;
        state.feedsList = action.payload;
      })
      .addCase(AnimalFeedsThunk.fetchAsyncFeeds.rejected, (state) => {
        state.isLoading = false;
        state.feedsList = [];
      })

      .addCase(AnimalFeedsThunk.fetchAsyncFeed.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(AnimalFeedsThunk.fetchAsyncFeed.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.feed = action.payload;
        // state.
      })
      .addCase(AnimalFeedsThunk.fetchAsyncFeed.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
      })

      .addCase(AnimalFeedsThunk.createFeed.pending, (state) => {
        state.createUpdateFeedSuccess = false;
        state.createUpdateFeedLoading = true;
      })
      .addCase(AnimalFeedsThunk.createFeed.fulfilled, (state, action) => {
        state.createUpdateFeedLoading = false;
        state.createUpdateFeedSuccess = action.payload.success as boolean;
        state.feedsList.push(action.payload.createdFeed);
      })
      .addCase(AnimalFeedsThunk.createFeed.rejected, (state, action: any) => {
        state.createUpdateFeedLoading = false;
        state.createUpdateFeedSuccess = action.payload.success as boolean;
        state.createUpdateFeedMessage = action.payload as string;
      })

      .addCase(AnimalFeedsThunk.updateFeed.pending, (state) => {
        state.createUpdateFeedSuccess = false;
        state.createUpdateFeedLoading = true;
      })
      .addCase(AnimalFeedsThunk.updateFeed.fulfilled, (state, action) => {
        state.createUpdateFeedLoading = false;
        state.createUpdateFeedSuccess = action.payload.success as boolean;
        state.feedsList.map((feed, index) => {
          if (feed.id === action.payload.updatedFeed.id) {
            state.feedsList[index] = action.payload.updatedFeed;
          }
        });
      })
      .addCase(AnimalFeedsThunk.updateFeed.rejected, (state, action: any) => {
        state.createUpdateFeedLoading = false;
        state.createUpdateFeedSuccess = action.payload.success as boolean;
        state.createUpdateFeedMessage = action.payload as string;
      })

      .addCase(AnimalFeedsThunk.createFeedMedia.pending, (state) => {
        state.isSignedUrlImageLoading = true;
      })
      .addCase(
        AnimalFeedsThunk.createFeedMedia.fulfilled,
        (state, action) => {
          state.isSignedUrlImageLoading = false;
          state.isSignedUrlImageSuccess = true;
          state.signedUrlImage = action.payload as string;
        }
      )
      .addCase(
        AnimalFeedsThunk.createFeedMedia.rejected,
        (state, action) => {
          state.isSignedUrlImageLoading = false;
          state.isSignedUrlImageError = true;
          state.signedUrlMessage = action.payload as string;
          state.signedUrlImage = "";
        }
      )

      .addCase(AnimalFeedsThunk.deleteFeedMedia.pending, (state) => {
        state.isdeleteMediaLoading = true;
      })
      .addCase(AnimalFeedsThunk.deleteFeedMedia.fulfilled, (state, action) => {
        state.isdeleteMediaLoading = false;
        state.deleteMediaSuccess = action.payload.success as boolean;
        state.deleteMediaMessage = action.payload.message as string;
      })
      .addCase(
        AnimalFeedsThunk.deleteFeedMedia.rejected,
        (state, action: any) => {
          state.isdeleteMediaLoading = false;
          state.deleteMediaSuccess = action.payload.success as boolean;
          state.deleteMediaMessage = action.payload as string;
        }
      )

      .addCase(AnimalFeedsThunk.updateFeedMedia.pending, (state) => {
        state.isupdateMediaLoading = true;
      })
      .addCase(AnimalFeedsThunk.updateFeedMedia.fulfilled, (state, action) => {
        state.isupdateMediaLoading = false;
        state.updateMediaSuccess = action.payload.success as boolean;
        state.updateMediaMessage = action.payload.message as string;
      })
      .addCase(
        AnimalFeedsThunk.updateFeedMedia.rejected,
        (state, action: any) => {
          state.isupdateMediaLoading = false;
          state.updateMediaSuccess = action.payload.success as boolean;
          state.updateMediaMessage = action.payload as string;
        }
      );
  },
});
// const {setMilkFeedTracker,onFeedSearch}=FeedReducerSlice.actions;
// export const FeedReducerActions={
//   setMilkFeedTracker,
//   onFeedSearch
// }
export default FeedsReducerSlice.reducer;
